<template>
  <v-container>
        <v-row>
        <v-col cols="12" >
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            sort-by="dte__date_emited"
            :sort-desc="true"
            items-per-page="5"
            :loading="loading"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right'
              }"
            >
            <template v-slot:top>

              
            <v-row justify="end" class="mb-2">
              <export-product-invoice-detail 
              :product="product"
              :filter="filter" 
              :options="options" 
              :disabled="loading"/>
            </v-row>
            </template>
            <template v-slot:item.dte__document_id="{ item }">
            <v-chip
            v-if="item.tipo==60" 
            x-small
            color="error"
            class="ml-2"
            >
            NC
            </v-chip>
              {{item.dte__document_id}}
            </template>
            <template v-slot:item.total="{ item }">
              {{item.total | currency('$', 0)}}
            </template>
            <template v-slot:item.avg_price="{ item }">
              {{item.avg_price | currency('$', 0)}}
            </template>
            <template v-slot:item.dte__date_emited="{ item }">
            <div class="d-block">
              {{item.dte__date_emited| moment('DD/MM/YYYY')}}
              </div>
            </template>
          </v-data-table>
        </v-col>
    </v-row>
      <v-row class="mb-2 justify-space-around">
        
	</v-row>
    
    
  </v-container>
</template>

<script>

import {fetchInvoicedDetailByProducts} from '@/api/reports'
import ExportProductInvoiceDetail from '@/components/reports/ExportProductInvoiceDetail'
import notifications from '@/utils/notifications'
export default {
    name:'ProductInvoiceDetail',
    props:['product','filter'],
    components:{ExportProductInvoiceDetail},
    data(){
        return{
            options:{},
            items:[],
            totalItems:0,
            search:'',
            headers:[
            {
            text: 'Documento',
            align: 'start',
            value: 'dte__document_id',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: true,
            },
            
            {
            text: 'Emitido',
            align: 'start',
            value: 'dte__date_emited',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: true
            },
            {
            text: 'Razon Social',
            align: 'start',
            value: 'dte__rznSocRecep',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: true
            },
            {
            text: 'OT',
            align: 'start',
            value: 'dte__work_order__id',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Cot.',
            align: 'start',
            value: 'dte__work_order__sales_note__id',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Proyecto',
            align: 'start',
            value: 'dte__work_order__sales_note__name',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Precio Prom.',
            align: 'start',
            value: 'avg_price',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            }, 
            {
            text: 'Cantidad',
            align: 'start',
            value: 'qty',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            },
            {
            text: 'Total',
            align: 'start',
            value: 'total',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            }, 
            
            ],
            loading:false,
            iva:this.$store.getters.getCompany.tax, 
        }
    },
    methods:{
       async fetch(){
          this.loading=true
          //Arreglando variables de fecha en caso de ser null
          if(!this.filter.created_after) this.filter.created_after=''
          if(!this.filter.created_before) this.filter.created_before=''
          if(!this.filter.seller) this.filter.seller=''
          const {sortBy, sortDesc,page, itemsPerPage }=this.options
          let  sort = (sortDesc[0] ? '-'+sortBy[0] : sortBy[0])
          if (!sort||!sortBy[0]) sort = 'dte__date_emited'
          const query=`page=${page}&page_size=${itemsPerPage}&search=${this.search}&ordering=${sort}&product=${this.product.product}&emited_after=${this.filter.created_after}&emited_before=${this.filter.created_before}&dte__customer__seller__id=${this.filter.seller}`
          await fetchInvoicedDetailByProducts(query)
          .then((res)=>{
            this.loading=false
            this.totalItems=res.count
            this.items=res.results
          })
        },  
    },
    watch:{
      filter:{
        handler(){
          this.fetch()
        },
        deep:true
      },
      options:{
        handler:'fetch',
        deep:true
      },
      search:{
        handler(){
          this.fetch()
        }
      },
      product:{
        handler(){
          this.fetch()
        },
        deep:true
      },
    },
   
}
</script>

<style>

</style>