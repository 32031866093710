<template>
<div>
    <v-row justify="end">
        <!-- <v-col cols="12" md="3" class="d-flex justify-end">
            <v-select
            v-model="optionSelected"
            :items="dataRange"
            label="Periodo"
            outlined
            hide-details
            item-text="text"
            width="300"
            return-object
            style="max-width: 200px"
            dense
            />
        </v-col> -->
        <v-col cols="12" md="3">
            <v-menu
                v-model="menuDate"
                :close-on-content-click="false"
                max-width="290"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    :value="computedDateFormattedMomentjs"
                    label="Periodo"
                    dense
                    readonly
                    v-bind="attrs"
                    outlined
                    v-on="on"
                    @click:clear="date = null"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dateRange"
                    @change="()=>{menuDate = false;fetch()}"
                    range
                ></v-date-picker>
                </v-menu>
        </v-col>
    </v-row>
    <v-row v-if="loading">

        <v-col>
            <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            height="170"
            type="card"
            ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            height="170"
            type="card"
            ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            height="170"
            type="card"
            ></v-skeleton-loader>
        </v-col>
        <v-col>
            <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            height="170"
            type="card"
            ></v-skeleton-loader>
        </v-col>
    </v-row> 
     
    <v-row v-else>
        <Can I="read" a="SalesNote">
            <v-col>
                <v-card
                    class="mt-4 mx-auto"
                    max-width="300"
                    height="170" 
                >
                    <v-sheet
                        class="v-sheet--offset mx-auto pa-2"
                        color="medium_blue"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                        :height="80"
                        min-height="80"
                    >
                        <v-sparkline
                        :value="quoted.map(e=>e.total)"
                        color="white"
                        :smooth="6"
                        :line-width="2"
                        auto-draw
                        :height="80"
                        min-height="80"
                        
                        >
                            
                        </v-sparkline>
                    </v-sheet>
                    <v-card-text class="pt-0 mt-4">
                        <div class="text-h4 font-weight-light mb-2 text-center">
                            {{total_quoted | currency('$', 0)}}
                        </div>
                        <div class="subheading font-weight-light grey--text text-center">
                            Cotizado
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </Can>
        <Can I="read" a="SalesNote">
            <v-col>
                <v-card
                    class="mt-4 mx-auto"
                    max-width="300"
                    height="170" 
                >
                    <v-sheet
                        class="v-sheet--offset mx-auto pa-2"
                        color="custom_yellow"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                        :height="80"
                        min-height="80"
                    >
                        <v-sparkline
                        :value="confirmed.map(e=>e.total)"
                        color="white"
                        :smooth="6"
                        :line-width="2"
                        :height="80"
                        auto-draw
                        min-height="80"
                        >
                            
                        </v-sparkline>
                    </v-sheet>
                    <v-card-text class="pt-0 mt-4">
                        <div class="text-h4 font-weight-light mb-2 text-center">
                            {{total_confirmed | currency('$', 0)}}
                        </div>
                        <div class="subheading font-weight-light grey--text text-center">
                            Confirmado
                        </div>
                    
                        </v-card-text>
                </v-card>
            </v-col>
        </Can>
        <Can I="read" a="SalesNote">
            <v-col>
                <v-card
                    class="mt-4 mx-auto"
                    max-width="300"
                    height="170" 
                >
                    <v-sheet
                        class="v-sheet--offset mx-auto pa-2"
                        color="green"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                        height="80"
                        min-height="80"
                    >
                        <v-sparkline
                        :value="invoices.map(e=>e.total)"
                        color="white"
                        :smooth="6"
                        :line-width="2"
                        auto-draw
                        height="80"
                        min-height="80"
                        >
                            
                        </v-sparkline>
                    </v-sheet>
                    <v-card-text class="pt-0 mt-4">
                        <div class="text-h4 font-weight-light mb-2 text-center">
                            {{total_invoiced | currency('$', 0)}}
                        </div>
                        <div class="subheading font-weight-light grey--text text-center">
                            Facturado
                        </div>
                    
                        </v-card-text>
                </v-card>
            </v-col>
        </Can>
        <Can I="read" a="Invoice">
            <v-col>
                <v-card
                    class="mt-4 mx-auto"
                    max-width="300"
                    height="170" 
                >
                    <v-sheet
                        class="v-sheet--offset mx-auto pa-2"
                        color="custom_orange"
                        elevation="12"
                        max-width="calc(100% - 32px)"
                        height="80"
                        min-height="80"
                    >
                        <v-sparkline
                        :value="payments.map(e=>e.total)"
                        color="white"
                        :smooth="6"
                        :line-width="2"
                        auto-draw
                        height="80"
                        min-height="80"
                        >
                            
                        </v-sparkline>
                    </v-sheet>
                    <v-card-text class="pt-0 mt-4">
                        <div class="text-h4 font-weight-light mb-2 text-center">
                            {{total_payments | currency('$', 0)}}
                        </div>
                        <div class="subheading font-weight-light grey--text text-center">
                            Pagado
                        </div>
                    
                        </v-card-text>
                </v-card>
            </v-col>
        </Can>
    </v-row>
    <day-graph-complete :dateRange='dateRange' :customers='customers' :sellers='sellers' :loading='loading'/>
    
</div>
</template>

<script>
import {fetchDayGraph} from '@/api/salesNotes'
import DayGraphComplete from '@/components/sales/DayGraphComplete'
const moment = require('moment')
export default {
    name: 'CommercialGraph',
    components: {
        DayGraphComplete
    },
    data(){
        return {
            optionSelected:{},
            dateRange:[moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'), moment().format('YYYY-MM-DD')],
            menuDate:false,
            dataRange:[
                {
                text:  moment(new Date().toString()).format('MMMM-YYYY'),
                after: moment(new Date().toString()).format('YYYY')+'-'+moment(new Date().toString()).format('MM')+'-01',
                before:moment(new Date().toString()).format('YYYY-MM-DD'),
                format:'DD-MM-YYYY',
                monthly:''
                },
                {
                    text:'30 días',
                    after: moment(new Date().toString()).add(-1,'month').format('YYYY-MM-DD'),
                    before:moment(new Date().toString()).format('YYYY-MM-DD'),
                    format:'DD-MM-YYYY',
                    monthly:''
                },
                {
                    text:'12 Meses',
                    after:moment(new Date().toString()).add(-1,'year').format('YYYY-MM-DD'),
                    before:moment(new Date().toString()).format('YYYY-MM-DD'),
                    format:'MMM',
                    monthly:true
                }
            ],
            quoted:[],
            confirmed:[],
            invoices:[],
            payments:[],
            customers:[],
            sellers:[],
            total_quoted:0,
            total_confirmed:0,
            total_invoiced:0,
            total_payments:0,
            loading:false,  
        }
    },
    computed: {
      computedDateFormattedMomentjs () {
        return `${this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[0]?this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[0]:''} - ${this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[1]?this.dateRange.map(date => moment(date).format('DD-MMM-YYYY'))[1]:''}`
      },
    },
    methods:{
        async fetch(){
            this.loading=true
            //Trayendo el las Sales Notes para saber lo cotizado
            //sort = '', search = '', created_after = '', created_before = '', customer = '', seller = '', has_work_order = '', page = '', page_size = '' 
            var filterSeller=''
            if (!this.$can('realAll', 'SalesNote')){
                const user = this.$store.getters.getUser
                filterSeller=user.id
            }
            const query=`created_after=${this.dateRange[0]}&created_before=${this.dateRange[1]}&customer__seller=${filterSeller}&monthly=${this.optionSelected.monthly}`

            await fetchDayGraph(query)
            .then((data)=>{
                this.loading=false
                const {quoted,confirmed,invoices, payments, total_quoted,total_confirmed,total_invoiced, total_payments,customers,sellers}=data
                this.quoted=quoted
                this.confirmed=confirmed
                this.invoices=invoices
                this.payments=payments
                this.total_quoted=total_quoted
                this.total_confirmed=total_confirmed
                this.total_invoiced=total_invoiced
                this.total_payments=total_payments
                this.customers=customers
                this.sellers=sellers
            })
        }
    },
    async mounted(){
    this.optionSelected = this.dataRange[0]
    this.fetch()
    },
     watch:{
    //Revisa cambios en options para solicitar data nuevamente
    optionSelected:{
      deep: true,
      handler: 'fetch'
    }
  }
}
</script>

<style>

</style>