<template>
  <v-container class="pa-10">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Agregar Registro de Procesos</v-toolbar-title>
    </v-toolbar>
    <ValidationObserver v-slot="{handleSubmit}">
      <v-form class="mt-2" @submit.prevent="handleSubmit(save)">
        <v-simple-table>
          <thead>
            <tr>
              <th class="table-header">
                OT
              </th>
              <th class="table-header">
                Producto
              </th>
              <th v-if='machine.production_unit_m2' class="table-header">
                Total Mts<sup>2</sup>
              </th>
              <th v-if='machine.production_unit_m2' class="table-header">
                Producido Mts<sup>2</sup>
              </th>
              <th v-if='!machine.production_unit_m2' class="table-header">
                Total Unidades
              </th>
              <th v-if='!machine.production_unit_m2' class="table-header">
                Producido Unidades
              </th>
              <th class="d-flex align-center justify-center table-header">
                Completado?
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="process in processes" :key="process.id">
              <td>
                {{process.work_order}}
              </td>
              <td>
                <div v-if='process.product.library_product' class="text text-caption">
                 [{{process.product.library_product.sku}}]
                </div>
                <div>
                  {{process.product.name}}
                </div>
              </td>
              <td v-if='machine.production_unit_m2'>
               <strong>{{process.completed_mts2_qty.mts2}}</strong> / {{process.product.mts2}}
               <v-progress-linear
                  color="light-blue"
                  height="5"
                  :value="process.completed_mts2_qty.mts2/process.product.mts2*100"
                  striped
                >
                </v-progress-linear>
              </td>
              <td v-if='machine.production_unit_m2'>
                <ValidationProvider name="Producción" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model='process.real_mts2'
                    class="quantity"
                    solo
                    dense
                    :min="0"
                    @input="calculateQty(process)"
                    type='number'
                    :error-messages="errors"
                    append-icon="square_foot"
                  />
                </ValidationProvider>
              </td>
              <td v-if='!machine.production_unit_m2'>
                <strong>{{process.completed_mts2_qty.qty}}</strong> / {{process.product.qty}}
               <v-progress-linear
                  color="light-blue"
                  height="5"
                  :value="process.completed_mts2_qty.qty/process.product.qty*100"
                  striped
                >
                </v-progress-linear>
              </td>
              <td v-if='!machine.production_unit_m2'>
                <ValidationProvider name="Cantidad" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model='process.real_qty'
                    class="quantity"
                    solo
                    dense
                    :min="0"
                    @input="calculateMts2(process)"
                    type='number'
                    :error-messages="errors"
                    append-icon="view_comfy"
                  />
                </ValidationProvider>
              </td>
              <td class="d-flex align-center justify-center">
                <v-checkbox v-model='process.ask_completed'/>
              </td>
            </tr>
            <tr>
              
              <td>

              </td>
              <td style="text-align: end" v-if='machine.production_unit_m2'>
                Total Mts<sup>2</sup> Reales: 
              </td>
              <td v-if='machine.production_unit_m2'>
                <h4><strong>{{mts2 | number('0,0[.]00')}}</strong></h4>
              </td>
              <td style="text-align: end" v-if='!machine.production_unit_m2'>
                Total Cantidad Real: 
              </td>
              <td v-if='!machine.production_unit_m2'>
                <strong>{{qty | number('0,0[.]00')}}</strong>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <ValidationProvider name="Máquina" rules="required" v-slot="{ errors }">
          <v-select
            label="Equipo"
            placeholder="Seleccione el Equipo"
            v-model='equipment'
            :items="machine.equipments"
            item-text="name"
            item-value="id"
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>
          <v-textarea
            v-model='notes'
            outlined
            label="Notas"
            placeholder="Notas al registro"
            :rows="1"
          />
          <div class="text-center mb-6">
            <v-btn type="submit" :loading="loading" x-large><v-icon class="mr-2">fas fa-paper-plane</v-icon> Enviar</v-btn>
          </div>
      </v-form>
    </ValidationObserver>

  </v-container>
</template>

<script>
import {postProcessOrderComplete} from '@/api/production'
import notifications from '@/utils/notifications'
export default {
  name:'ProcessComplete',
  props:['processes','machine'],
  data() {
    return {
      equipment:'',
      mts2:0,
      qty:0,
      loading:false,
      notes:'',
    }
  },
  methods:{
    async save(){
      this.loading=true
      // Se usa processesToComplete para evitar el circular
      //reference cuando aplico desde la ventada de OT
      const proccessesToComplete = this.processes.map(p=>{
        return {
          id: parseInt(p.id),
          real_mts2: parseFloat(p.real_mts2)||0,
          real_qty:parseFloat(p.real_qty)||0,
          ask_completed: Boolean(p.ask_completed)
        }
      })
      await postProcessOrderComplete({
        processes:proccessesToComplete,
        equipment:this.equipment,
        notes:this.notes
      })
      .then((res)=>{
        notifications.showSuccess(res)
        this.loading=false
        this.$emit('refresh')
        this.$emit('close')
      })
      .catch((e)=>{
          notifications.showError('Error Realizando el Registro '+e)
          this.loading=false
      })
    },
    calculateQty(item){
      //Calculo de Cantidad cuando se ingresan valores en Mts2 - Para postcosteo
      const productArea=parseFloat(item.product.width)*parseFloat(item.product.height)*0.0001
      item.real_qty=parseFloat(item.real_mts2)/productArea
      this.suma()
    },
    calculateMts2(item){
      const productArea=parseFloat(item.product.width)*parseFloat(item.product.height)*0.0001
      item.real_mts2=parseFloat(item.real_qty)*productArea
      this.suma()
    },
    suma(){
      this.qty=0
      this.mts2=0
      this.processes.map(proc=>{
        this.qty+=parseFloat(proc.real_qty)
        this.mts2+=parseFloat(proc.real_mts2)
      })
    },
  },
  watch:{ 
    processes(){
      this.mt2=0
      this.qty=0
    }
    }
  
}
</script>

<style lang="scss">
.quantity {
    width: 7rem;
    .v-input__control{
      .v-text-field__details{
      min-height: 0px !important;
      padding: 0 !important;
      margin-bottom: 0px !important;
      .v-messages{
      min-height: 0px !important;
      }
    }
    }
  }
</style>
