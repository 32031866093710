<template>
  <v-container>
    <v-row class="mb-4 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center">fas fa-city</v-icon>Clientes</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn to="customers/import" class="mr-4" color="primary" text v-if="$can('create', 'Customer')" >
            <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
        </v-btn>
        <v-btn v-if="$can('create', 'Customer')"  color="success" @click.stop="CreateUpdate">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="pa-4">
      <v-row class="mb-10 justify-space-between">
        <v-col>
        <v-data-table
          class=""
          :headers="headers"
          :items="items"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalCustomers"
          item-class="table-body"
          :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }"
        >
          <template v-slot:top>
            <v-row justify="end">
              <v-col cols="6">
                <v-text-field
                    v-model="search"
                    @keyup="searchTimeOut"
                    label="Buscar"
                    append-icon="fas fa-search"
                    :disabled="loading"
                  ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="item.status ? 'success':'error'"
              label
              small
              dark
              class="chip justify-center"
            >
              {{ item.status ? 'Activo':'Inactivo' }}
            </v-chip>
          </template>
          <template v-slot:item.name="{ item }">
            {{item.name }} {{item.branch_office>0?`[${item.branch_office}]`:''}}
          </template>
          <template v-slot:item.seller="{item}">
            {{item.seller.first_name + ' ' + item.seller.last_name}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="$can('update', subject('Customer',item))"  @click.stop="CreateUpdate(item)">fas fa-edit</v-icon>
          </template>
        </v-data-table>

        </v-col>
      </v-row>
    </v-card>
        <v-dialog 
          v-model="showDetail"
          max-width="500px">
          <v-card>
            <customer-card :selected="selected"/>
          </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import {fetchCustomers} from '@/api/customers'
import CustomerCard from '@/components/customers/CustomerCard'
import notifications from '@/utils/notifications'
import {subject}  from '@casl/ability';
export default {
  name: 'Customers',
  components: {
    CustomerCard,
  },
  async mounted(){
    await this.fetch()
  },
  data: () =>({
    items:[],
    headers: [
      { text: 'RUT', value: 'rut' ,class: 'table-header'},
          {
            text: 'Razón Social',
            align: 'start',
            value: 'name',
            class: 'table-header'
          },
          { text: 'Fantasía', value: 'fantasy' ,class: 'table-header'},
          { text: 'Vendedor',value:'seller',class: 'table-header'},
          { text: 'Estatus',value:'status',class: 'table-header',sortable: false},
          { text: 'Acciones',value:'actions',class: 'table-header',align:'center',sortable: false}
        ],
    search:'',
    timer:'',
    options:{},
    totalCustomers:0,
    showDetail:false,
    loading:false,
    selected:{
      name:'',
      fantasy:'',
      rut:''
    },
  }),
  methods:{
    subject: subject,
    showCustomer(item){
      this.showDetail=true
      this.selected=item
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    CreateUpdate(item){
      this.$router.push({name:'CustomersCreateUpdate',params:{customerId:item.id}})
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='seller'){
        sort = 'seller__first_name'
      }
      else {
        sort = sortBy[0]
      }
      await fetchCustomers(sortDesc[0]?`${sort}`:`-${sort}`,page,itemsPerPage,this.search)
      .then((data)=>{
        this.totalCustomers=data.count
        this.items=data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando clientes')
        this.loading=false
      })
    }
  },
  computed: {
    numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    }
  }
}
</script>

<style lang="scss">

tbody {
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight:normal !important;
  font-size:medium !important;
  color:#707070 !important;
}
.chip{
  width: 4rem;
  justify-items: center;
}
</style>
sty
