var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savemachine)}}},[_c('v-card-title',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v(_vm._s(_vm.machine.id>0 ? 'Actualizar Area':'Crear Area'))])]),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"type":"submit","color":"success"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v(" Guardar")],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","prepend-icon":"fas fa-file-invoice","outlined":"","error-messages":errors},model:{value:(_vm.machine.name),callback:function ($$v) {_vm.$set(_vm.machine, "name", $$v)},expression:"machine.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Capacidad Diária","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Capacidad por Hora","type":"number","prepend-icon":"fas fa-hard-hat","append-icon":_vm.machine.production_unit_m2?'square_foot':'view_comfy',"hint":_vm.machine.production_unit_m2?'Metros Cuadrádos':'Unidades',"persistent-hint":"","min":1,"outlined":"","error-messages":errors},on:{"click:append":function($event){_vm.machine.production_unit_m2 = !_vm.machine.production_unit_m2}},model:{value:(_vm.machine.capacity),callback:function ($$v) {_vm.$set(_vm.machine, "capacity", $$v)},expression:"machine.capacity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Horas por dia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Horas por día","type":"number","prepend-icon":"fas fa-stopwatch","min":1,"max":24,"outlined":"","error-messages":errors},model:{value:(_vm.machine.hours_per_day),callback:function ($$v) {_vm.$set(_vm.machine, "hours_per_day", $$v)},expression:"machine.hours_per_day"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Orden","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Orden","type":"number","prepend-icon":"fas fa-sort","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.machine.sort),callback:function ($$v) {_vm.$set(_vm.machine, "sort", $$v)},expression:"machine.sort"}})]}}],null,true)}),_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{staticClass:"mr-4",attrs:{"label":"Sabado","dense":""},model:{value:(_vm.machine.saturday),callback:function ($$v) {_vm.$set(_vm.machine, "saturday", $$v)},expression:"machine.saturday"}}),_c('v-checkbox',{attrs:{"label":"Domingo","dense":""},model:{value:(_vm.machine.sunday),callback:function ($$v) {_vm.$set(_vm.machine, "sunday", $$v)},expression:"machine.sunday"}})],1)],1)],1)],1)]}}])}),(_vm.machine.equipments)?_c('div',[_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto mb-2",on:{"click":_vm.addEquipment}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Equipo")],1)],1),(_vm.machine.equipments.length>0)?_c('v-simple-table',[_c('tbody',_vm._l((_vm.machine.equipments),function(equipment){return _c('tr',{key:equipment.id},[_c('td',[_c('div',[_vm._v(" "+_vm._s(equipment.name)+" ")])]),_c('td',[_c('v-switch',{on:{"change":function (){_vm.newEquipment=equipment;_vm.saveEquipment()}},model:{value:(equipment.enabled),callback:function ($$v) {_vm.$set(equipment, "enabled", $$v)},expression:"equipment.enabled"}})],1),_c('td',{attrs:{"align":"center"}},[_c('div',[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editEquipment(equipment)}}},[_vm._v("fas fa-edit")])],1)])])}),0)]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.isEditingEquipment),callback:function ($$v) {_vm.isEditingEquipment=$$v},expression:"isEditingEquipment"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_vm._v("Editar Equipo")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveEquipment)}}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"fas fa-file-invoice","outlined":"","error-messages":errors},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"type":"submit"}},[_c('v-icon',[_vm._v("fas fa-save")])],1)]},proxy:true}],null,true),model:{value:(_vm.newEquipment.name),callback:function ($$v) {_vm.$set(_vm.newEquipment, "name", $$v)},expression:"newEquipment.name"}})]}}],null,true)})],1)]}}],null,false,226650455)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }