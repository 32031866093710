<template>
  <v-div>
    <v-app-bar color="success" dense dark>
      <v-toolbar-title>
        <v-icon class="mr-2">fas fa-rss</v-icon>SOFTNET
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="fetchSoftnetData" text  small fab><v-icon small>fas fa-arrows-rotate</v-icon></v-btn>
      <v-btn text @click="$emit('close')" small fab ><v-icon small>fas fa-close</v-icon></v-btn>
    </v-app-bar>
    <div class="d-flex justify-center align-items-center mt-6" v-if="loading">
      <v-progress-circular
        indeterminate
        color="success"
        class="mr-2"
      />
    </div>
    <!-- <div v-if="!loading" class="d-flex align-center mt-4 mb-4">
      <v-icon>fas fa-rss</v-icon>
      <v-img class="mr-4" max-height="50" max-width="150"  src="../../assets/integrations/logo-softnet.png" alt="logo-softnet"/>
      <h1 class="text text-h4 titulo">Nota de Venta</h1>
    </div> -->
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form v-if="!loading" class="mt-8 pa-4" @submit.prevent="handleSubmit(confirm)">
      <v-row>
        <v-col cols="12" md="2" >
          <v-text-field
            :value="customer_data?customer_data.rut:' '"
            label="RUT"
            dense
            :loading="loading"
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
        </v-col>
        <v-col cols="12" md="5">
          <v-text-field
            dense
            label="Razón Social"
            :value="customer_data?customer_data.nombre_rz:' '"
            :loading="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="5" >
          <v-text-field
            dense
            label="Giro"
            :value="customer_data?customer_data.giro:''"
            :loading=loading
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="6" >
          <v-text-field
            dense
            label="Vendedor"
            :value="seller_name"
            :loading=loading
            :disabled="loading"
            outlined
            readonly
            hide-details
          />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              dense
              class="text-end mr-2"
              :items="formas_pago"
              :loading="loading"
              :disabled="loading"
              item-text="nombre"
              item-value="id"
              label="Forma de Pago"
              placeholder="Selecciona Forma de Pago"
              hide-details
              outlined
              v-model="forma_pago"
              >
          </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              dense
              class="text-end mr-2"
              :items="customer_data?customer_data.direccion:[]"
              :loading="loading"
              :disabled="loading"
              item-text="direccion"
              item-value="id"
              label="Direccion"
              placeholder="Selecciona Direccion"
           
              outlined
              v-model="direccion"
              >
          </v-select>
          </v-col>
            <v-col cols="12" md="6">
              <v-select
                dense
                class="text-end mr-2"
                :items="areas_negocio"
                :loading="loading"
                :disabled="loading"
                item-text="nombre"
                item-value="id"
                label="Area de Negocio"
                placeholder="Selecciona Area de Negocio"
                outlined
                v-model="area_negocio"
                >
            </v-select>
            </v-col>
      </v-row>
      <v-row>
            <v-col>
              <v-data-table
              :headers="headers"
              :items="productos"
              item-key="key"
              class=""
              :loading="loading"
              hide-default-footer
              >
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    No hay productos para mostrar
                  </v-alert>
                </template>
                <template v-slot:item.qty="{ item }">
                  <ValidationProvider name="qty" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.qty'
                      :min="1"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                      solo
                      dense
                      style="width:100px"
                      ></v-text-field>
                    </ValidationProvider>
                </template>
                <template v-slot:item.price="{ item }">
                  <ValidationProvider name="price" rules="required" v-slot="{ errors }">
                    <v-text-field
                      type="number"
                      v-model='item.price'
                      :min="0"
                      solo
                      dense
                      style="width:150px"
                      :error-messages="errors"
                      :hide-details="errors.length<1"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <template v-slot:item.total="{ item }">
                  {{item.price*item.qty | currency('$', 0)}}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon class="error--text" x-small @click.stop="removeItem(item)">fas fa-trash</v-icon>
                </template>
                <template v-slot:item.total="{ item }">
                  {{item.price*item.qty | currency('$', 0)}}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-4">
            <v-col>
                <v-card class="py-6">
                  <v-simple-table dense class='strip-table'>
                    <thead>
                      <tr>
                        <th class="text-center table-header2">Neto</th>
                        <th class="text-center table-header2">IVA</th>
                        <th class="text-center table-header2">Total</th> 
                       
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center strip-body"><strong>{{neto| currency('$',0)}}</strong></td>
                        <td class="text-center strip-body d-flex align-center justify-center" style="min-height:80px;">
                          (<ValidationProvider name="IVA" rules="required" v-slot="{ errors }">
                            <v-text-field 
                            type="number" 
                            v-model="iva" 
                            dense solo 
                            style="max-width:70px;" 
                            hide-details
                            max="100"
                            min="0"/>
                          </ValidationProvider>)%
                          <strong class="ml-4">{{(neto)*iva*0.01| currency('$',0) }}</strong>
                        </td>
                        <td class="text-center strip-body"><strong class="">{{neto*(1+iva/100)| currency('$',0)   }}</strong></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card>
              </v-col>
      </v-row>
      <v-row justify="center">
          <v-col class="text-center mt-4">
            <v-btn color="success" type="submit" :disabled="false" :loading="sending"><v-icon class="mr-2">fas fa-file-arrow-up</v-icon>Guardar</v-btn>
          </v-col>
        </v-row>
    </v-form>
    </ValidationObserver>
    <confirm 
    v-model="showConfirm" 
    :message="sales_note?`Se creara un nuevo documento en Softnet vinculado a la OT ${sales_note.work_order} `:'-'" 
    @ok="sendData"
    @cancel="showConfirm=false"/>
  </v-div>
</template>
<script>
import { getFullData,createDocument } from "@/api/softnet";
import notifications from "../../utils/notifications";

export default{
  name:'CreateDocument',
  props:['sales_note_id'],
  data(){
    return{
      loading:false,
      showConfirm:false,
      sending:false,
      customer_data:{},
      sales_note:{},
      formas_pago:[],
      forma_pago:'',
      direccion:'',
      areas_negocio:[],
      area_negocio:'',
      seller_name:'',
      productos:[],
      exento:0,
      iva:parseInt(this.$store.getters.getCompany.tax),
      headers:[
        {
          text: 'Código',
          align: 'start',
          sortable: false,
          value: 'sku',
          show:false,
          class:'table-header2'
        },
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'name',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value: 'qty',
          width:'10%',
          show:true,
          class:'table-header2'
        },
        {
          text: 'Precio Unit.',
          align: 'end',
          sortable: false,
          value: 'price',
          show:true,
          width:'12%',
          class:'table-header2'
        },
        {
          text: 'Total',
          align: 'end',
          sortable: false,
          value: 'total',
          show:true,
          class:'table-header2'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions',
          show:true,
          class:'table-header2'
        },
        ],

    }
  },
  methods:{
    removeItem(item){ 
      this.productos.splice(this.productos.indexOf(item),1)  
    },
    confirm(){
      this.showConfirm=true
    },
    async fetchSoftnetData(){
      this.loading = true;
      await getFullData(this.sales_note_id).then(response => {
        console.log(response)
        const {customer_data, sales_note,forma_pago,area_negocio} = response;
        this.customer_data=customer_data;
        this.direccion=customer_data.direccion[0].id;
        this.sales_note=sales_note;
        this.formas_pago=forma_pago;
        this.forma_pago=forma_pago[0].id;
        this.areas_negocio=area_negocio;
        this.area_negocio=area_negocio[0].id;
        this.seller_name=sales_note.sales_note_customer.seller.first_name+' '+sales_note.sales_note_customer.seller.last_name;
        const discountFactor=1-parseFloat(sales_note.totals.discount)/100;
        this.productos=sales_note.details.products.map(producto => {
          producto.price=parseFloat(producto.qty)!=0?parseFloat(producto.totalPrice/producto.qty)*discountFactor:0;
          producto.qty=parseFloat(producto.qty);
          return producto;
          });
        this.loading = false;
      })
      .catch(error => {
        notifications.showError(error);
        this.loading = false;
      })
    },
    async sendData(){
      this.sending=true
      const data={
        workorder_id:this.sales_note.work_order,
        area_negocio:this.area_negocio,
        condicion_pago:this.forma_pago,
        direccion:this.direccion,
        neto:this.neto,
        exento:this.exento,
        descuento:0,
        total:this.neto+this.exento,
        observacion:`COT ${this.sales_note.id} - OT ${this.sales_note.work_order}`,
        detalle:this.productos.map(p=>{
          return {
            Codigo:p.sku,
            Cantidad:p.qty,
            Precio:p.price,
            Descuento:0,
            Afecto:true
          }
        }),
      }

      await createDocument(data).then(response => {
        const {mensaje,folio} = response[0];
        notifications.showSuccess(`${mensaje} folio: ${folio}`);
        this.sending=false
        this.showConfirm=false
        this.$emit('close')
      })
      .catch(e=>{
        notifications.showError(e);
        this.sending=false
        this.showConfirm=false
      })
    }
  },
  computed:{
    neto(){
      return this.productos.reduce((acc,val)=>{return acc + parseFloat(val.qty)*parseFloat(val.price);},0)
    },
    company(){
      return this.$store.getters.getCompany
    },
  },
  watch:{
    sales_note_id(newValue){
      console.log(newValue)
      this.fetchSoftnetData();
    }
  },
  async created(){
    this.fetchSoftnetData();
  }
}

</script>