<template>
  <v-container>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(submitContact)">
      <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        prepend-icon="fas fa-id-badge"
        placeholder="Nombre"
        v-model="first_name"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Apellido" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        prepend-icon="fas fa-id-badge"
        placeholder="Apellido"
        v-model="last_name"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
        <v-text-field
        outlined
        prepend-icon="fas fa-envelope"
        placeholder="Email"
        v-model="email"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
        <v-text-field
        outlined
        prepend-icon="fas fa-phone"
        placeholder="Teléfono"
        v-model="phone"
        clearable
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
        <div class="d-flex justify-end">
          <v-btn type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
        </div>
    </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import regions_comunes from '@/utils/regions_comunes.json'
export default {
  name:'ContactCreateUpdate',
  data:()=>({
    first_name:'',
    last_name:'',
    email:'',
    phone:''
  }),
  methods:{
    submitContact(){
      this.$emit('add',{
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone:this.phone,
      })
      this.$emit('close')
    }
  },
}
</script>