<template>
  <v-container>
      <v-row class="mb-2 justify-space-around">
        <v-col cols="12" md="4" v-if="!inSet">
            <h3 class="titulo text-h7">Informacion del Documento</h3>
           <table>
          <tbody>
            <tr>
              <td class="text-right">
                Documento:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.document_id}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Fecha de Emisión:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.date_emited|moment('DD/MM/YYYY')}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                RUT:
              </td>
              <td class="text-left px-3">
                  <div></div>
                <div class="font-weight-black ">{{invoice.rutRecep}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Razón Social:
              </td>
              <td class="text-left px-3">
                  <div></div>
                <div class="font-weight-black ">{{invoice.rznSocRecep}}</div>
              </td>
            </tr>
        <h3 class="titulo text-h7 mt-4">Montos y Pagos</h3>
            <tr>
              <td class="text-right">
                Neto:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.get_subtotal| currency('$', 0)}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                IVA ({{invoice.iva}}%):
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.get_iva| currency('$', 0)}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Total:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.get_total| currency('$', 0)}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Pagado:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.get_payed| currency('$', 0)}}</div>
              </td>
            </tr>
            <tr>
              <td class="text-right">
                Saldo:
              </td>
              <td class="text-left px-3">
                <div class="font-weight-black ">{{invoice.get_total-invoice.get_payed| currency('$', 0)}}</div>
              </td>
            </tr>
          </tbody>
        </table> 
        </v-col>
        <v-col cols="12" :md="inSet?12:8">
            <v-data-table
                class=""
                :headers="headers"
                :items="invoice.details"
                :items-per-page="-1"
                :loading="loading"
                :item-class="()=>{return 'row-style-normal'}"
                sort-by='date'
                :sort-desc="false"
                hide-default-footer
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                }">
                <template v-slot:top>
                  <h3 class="titulo text-h7">Productos</h3>
                </template>
                <template v-slot:no-data>
                  <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
                    No existen abonos registrados
                  </v-alert>
                </template>
                <template v-slot:item.tipo="{ item }">
                    {{tipos[item.tipo-1]}}
                </template>
                <template v-slot:item.date="{ item }">
                    {{item.date | moment('DD/MM/YYYY')}}
                </template>
                <template v-slot:item.price="{ item }">
                    {{item.price| currency('$', 0)}}
                </template>
                <template v-slot:item.total="{ item }">
                    {{item.price*item.qty| currency('$', 0)}}
                </template>
            </v-data-table>
        </v-col>
	</v-row>
    <v-dialog v-model="toPayment" max-width="500">
        <v-card class="pa-6">
            <v-card-title class="titulo text-h5">Registrar Abono</v-card-title>
            <ValidationObserver v-slot="{handleSubmit}">
                <v-form @submit.prevent="handleSubmit(addPayment)">
                    <ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
                        <v-text-field
                            label="Fecha Emision"
                            v-model="newPayment.date"
                            type="date"
                            outlined
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Tipo" rules="required" v-slot="{ errors }">
                        <v-select
                            class="text-end mr-2"
                            v-model="newPayment.tipo"
                            :items="tipos"
                            :disabled="loading"
                            label="Tipo de Documento"
                            single-line
                            outlined
                            :error-messages="errors"
                        />
                    </ValidationProvider>
                    <ValidationProvider name="Detalle" rules="required" v-slot="{ errors }">
                        <v-text-field
                            label="Detalle"
                            v-model="newPayment.glosa"
                            outlined
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="Monto" rules="required" v-slot="{ errors }">
                        <v-text-field
                            label="Monto"
                            v-model="newPayment.amount"
                            type="number"
                            :min="0"
                            outlined
                            :error-messages="errors"
                        ></v-text-field>
                    </ValidationProvider>
                    <div class="text-center">
                        <v-btn type="submit" color="success" :loading="loadingAddPayment" class="text-end">Guardar</v-btn>
                    </div>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
    <confirm
    v-model="showConfirmDelete" 
    message="¿Está seguro de eliminar el abono seleccionado?"
    @ok="removePayment"
    @cancel="showConfirmDelete=false"/>
  </v-container>
</template>

<script>
import {createPayment,deletePayment} from '@/api/salesNotes'
import notifications from '@/utils/notifications'
export default {
    name:'InvoiceDetail',
    props:['invoice','inSet'],
    data(){
        return{
            tipos:[
                'EFECTIVO',
                'CHEQUE',
                'TRANSFERENCIA',
                'DEPOSITO'],
            headers:[
            {
            text: 'Item',
            align: 'start',
            value: 'nro_linea',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false,
            },
            {
            },
            {
            text: 'Código',
            align: 'start',
            value: 'sku',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: false
            },
            {
            text: 'Descripción',
            align: 'start',
            value: 'name',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: false
            },
            {
            text: 'Cant.',
            align: 'start',
            value: 'qty',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            },
            {
            text: 'Prec. Unit.',
            align: 'start',
            value: 'price',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            },
            {
            text: 'Total',
            align: 'start',
            value: 'total',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: false
            }, 
            
            ],
            newPayment:{
                date:'',
                tipo:'',
                glosa:'',
                amount:''
            },
            toPayment:false,
            loading:false,
            loadingAddPayment:false,
            paymentToRemove:'',
            showConfirmDelete:false,
        }
    },
    methods:{
        async addPayment(){
            this.loadingAddPayment=true;
            await createPayment({
                date: this.newPayment.date,
                amount: this.newPayment.amount,
                tipo:this.tipos.indexOf(this.newPayment.tipo)+1,
                glosa:this.newPayment.glosa,
                dte:this.invoice.id,   
            })
            .then((data)=>{
                this.toPayment=false;
                this.loadingAddPayment=false;
                this.invoice.payments.push(data);
                this.newPayment={
                    date:'',
                    tipo:'',
                    glosa:'',
                    amount:''
                }
                notifications.showSuccess('Pago registrado con exito');
                this.$emit('refresh');
            })
            .catch((err)=>{
                this.loadingAddPayment=false;
                notifications.showError(err.response.data.message);
            })
        },
        async removePayment(){
            this.loading=true;
            await deletePayment(this.paymentToRemove.id)
            .then(()=>{
                this.loading=false; 
                this.paymentToRemove='';
                this.showConfirmDelete=false;
                notifications.showSuccess('Pago eliminado con exito');
                this.$emit('refresh');
            })
            .catch((err)=>{
                this.loading=false;
                this.paymentToRemove='';
                this.showConfirmDelete=false;
                notifications.showError(err.response.data.message);
            })
        }
    }
    

}
</script>

<style>

</style>