<template>
    <v-container>
      <!-- <v-skeleton-loader
            v-if="loading"
            type="actions,text,text,text,text,card,card"
       ></v-skeleton-loader> -->
  
       
         <v-row class="mb-10 justify-space-between">
          <h1 class="text text-h4 titulo">Ordenes de Trabajo Valorizadas</h1>
          <export-work-orders-money :disabled="loading" :filter="filter" :options="options" :search="search"/>
         </v-row>
         <v-card class="pa-6">
        <v-row>
          <v-col>
            <v-text-field :disabled="loading" dense v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Creada Desde:"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :disabled="loading" dense v-model='filter.created_before' clearable outlined type="date" label="Creada Hasta:" ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field :disabled="loading" dense v-model='filter.delivery_after' class="mr-4" outlined clearable type="date" label="Entrega Desde:"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field :disabled="loading" dense v-model='filter.delivery_before' clearable outlined type="date" label="Entrega Hasta:" ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="filter.status"
              :items="status"
              item-text="text"
              item-value="value"
              label="Estatus"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
              :disabled="loading"
            />
          </v-col>
          <v-col>
            <v-select
              dense
              v-model="filter.seller"
              :items="sellers"
              item-text="email"
              item-value="id"
              label="Vendedor"
              placeholder="Todos los vendedores"
              prepend-inner-icon="filter_alt"
              :loading="sellersLoading"
              outlined
              clearable
              :disabled="loading"
            />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="px-0">
            <customer-complete2 v-model="filter.customer"/>
          </v-col>
        </v-row> -->
        
        <v-data-table
          :headers="headers"
          :items="workOrders"
          :options.sync="options"
          :server-items-length="totalWorkOrders"
          :search="search"
          sort-by="created_at"
          :sort-desc="false"
       
          
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [10, 20, 30, 40, 50,100],
          }"
        >
          <template v-slot:top>
          <v-row justify="end">
            <v-col cols="12" md="6">
              <v-text-field
                  v-model="search"
                  label="Buscar"
                  append-icon="fas fa-search"
                  @keyup="searchTimeOut"
                  :disabled="loading"
                ></v-text-field>
            </v-col>
          </v-row>
          </template>
          <template v-slot:item.id="{ item }">
            <div class="d-flex inline-flex">
              <show-work-order :work_order_id="item.id">{{item.id}}</show-work-order>
            </div>
          </template>
          <template v-slot:item.created_at="{ item }">
            <div>{{item.created_at | moment("DD/MM/YYYY")}}</div>
          </template>
          <template v-slot:item.sales_note__name="{item}">
            {{item.sales_note__name}}
          </template>
          <template v-slot:item.sales_note__customer__name="{item}">
            {{item.sales_note__customer__name}}
          </template>
          <template v-slot:item.delivery_date="{ item }">
            {{item.delivery_date | moment("DD/MM/YYYY HH:mm")}}
          </template>
          <template v-slot:item.flags="{ item }">
            <div class="d-flex">
                <v-icon small title="Precio debajo de costo" class="mr-2 red--text" v-if="item.flags.red">fas fa-exclamation-triangle</v-icon>
                <v-icon small title="Precio " class="mr-2 yellow--text" v-if="item.flags.yellow">fas fa-exclamation-triangle</v-icon>
            </div>
          </template>
          <template v-slot:item.total="{ item }">
            {{item.totals.total | currency('$',0)}}
          </template>
    </v-data-table>
    </v-card>
    </v-container>
  </template>
  
  <script>
  import {fetchWorkOrders,getWorkOrder,deleteWorkOrder} from '@/api/production'
  import {fetchWorkOrdersMoney} from '@/api/reports'
  import {getSalesNote} from '@/api/salesNotes'
  import CustomerComplete2 from '@/components/customers/CustomerComplete2.vue'
  import WorkOrderCard from '@/components/production/WorkOrderCard'
  import ExportWorkOrders from '@/components/production/ExportWorkOrders'
  import ExportWorkOrdersMoney from '@/components/reports/ExportWorkOrdersMoney'
  import CreateDocument from '@/components/softnet/CreateDocument'
  import {pdfWorkOrder} from '@/doc_generators/workOrder'
  import notifications from '@/utils/notifications'
  import {pdfCostReport} from '@/doc_generators/costReport'
  import SoftnetButton from '@/components/softnet/SoftnetButton.vue'
  import PrintWorkOrder from '@/components/production/PrintWorkOrder'
  import moment from 'moment'
  
  
  export default {
    name:'WorkOrdersWithMoney',
    components:{CustomerComplete2,WorkOrderCard,ExportWorkOrdersMoney,CreateDocument,SoftnetButton,PrintWorkOrder},
    data() {
      return {
        loading:false,
        status:[
            {text:'Vigentes',value:false},
            {text:'Completadas',value:true},
            {text:'Todas',value:''}
          ],
        filter:{
          created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
          created_before:'',
          delivery_after:'',
          delivery_before:'',
          status:'',
          seller:''
          },
        workOrderOnDisplay:{},
        workOrderOnDisplayLoading:[],
        options:{},
        totalWorkOrders:0,
        display:false,
        displaySoftnet:false,
        search:'',
        workOrders:[],
        sellers:[],
        sellersLoading:false,
        showDeleteOrder:false,
        workOrdertoDelete:{},
        headers:[
            {text: 'OT',align: 'start',value: 'id',class: 'table-header2'},
            {text: 'Creada',align: 'start',value: 'created_at',class: 'table-header2'},
            {text: 'Proyecto',align: 'start',value: 'sales_note__name',class: 'table-header2'},
            {text: 'Ct',value:'sales_note__id',class: 'table-header2'},
            {text: 'Cliente',align: 'start',value: 'sales_note__customer__name',class: 'table-header2'},
            {text:'Vendedor',value:'sales_note__customer__seller__first_name',class:'table-header2'},
            {text: 'Entrega',align: 'start',value: 'delivery_date',class: 'table-header2'},
            {text:'',align:'center',value:'flags',class:'table-header2',width:'1%'},
            {text: 'Total',align: 'end',value: 'total',class: 'table-header2'},
          ],
          loading_print_work_order:false,
          loading_print_cost_report:false,
      }
    },
    methods:{
      async fetch(){
        this.loading=true
        //Arreglando variables de fecha en caso de ser null
        if(!this.filter.created_after) this.filter.created_after=''
        if(!this.filter.created_before) this.filter.created_before=''
        if(!this.filter.delivery_after) this.filter.delivery_after=''
        if(!this.filter.delivery_before) this.filter.delivery_before=''
        if(!this.filter.seller) this.filter.seller=''
        const {sortBy, sortDesc,page, itemsPerPage }=this.options
        const query = `page=${page}&page_size=${itemsPerPage}&ordering=${sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`}&search=${this.search}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&delivery_after=${this.filter.delivery_after}&delivery_before=${this.filter.delivery_before}&completed=${this.filter.status}&sales_note__customer__seller=${this.filter.seller}&search=${this.search}`
        fetchWorkOrdersMoney(query)
        .then(data=>{
          this.workOrders=data.results
          this.totalWorkOrders=data.count
          this.loading=false
        })
        .catch(err=>{
          console.log(err)
        })
        .finally(()=>{
          this.loading=false
        })
      },
      searchTimeOut() {  
        //Función para hacer búsqueda lazy
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            this.fetch()
        }, 800);
      },
      itemRowStyle(item){
        return 'row-style-normal'
        /* //return item.estimated==0 ? 'row-style-completed':'row-style-normal'
        if(item.estimated>0){
          return new Date(item.estimated).toISOString()>item.delivery_date?'error--text row-style-normal':'row-style-normal'
        }
        else{
          return 'row-style-completed success--text'
        } */
      },
    },
    async mounted(){
      await this.fetch()
      this.sellersLoading=true
      this.sellers=this.$store.getters.getSellers
      this.sellersLoading=false
    },
    watch:{
      options:{
        handler:'fetch',
        deep:true
      },
      filter:{
        deep: true,
        handler: 'fetch'
      },
    }
  }
  </script>
  
  <style lang="scss">
    .row-style-completed{
      cursor: pointer;
    }
    .row-style-normal{
      cursor: pointer;
    }
  </style>
  
  
  
  