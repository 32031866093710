<template>
  <div>
    <v-select
          dense
          :items="items"
          :prepend-icon="icon"
          :placeholder="value.email ? value.email:'Seleccione un Vendedor'"
          item-value="id"
          v-model="value"
          @input="updateValue"
          outlined
          :error-messages="errors"
          return-object
    >
      <template v-slot:selection="{ item }">
        <div class="text">{{item.first_name + ' '+item.last_name + ' - ' +item.email}}</div>
      </template>
      <template v-slot:item="{ item }">
        <div class="text">{{item.first_name + ' '+item.last_name + ' - ' +item.email}}</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import {fetchSellers} from '@/api/auth'

export default {
  name:'SellerSelection',
  data:()=>({
    items: []
  }),
  methods:{
    updateValue(){
      this.$emit('input',this.value)
    }
  },
  props:{
    value:'',
    errors:'',
    icon:''
  },
  async created(){
    this.items = await fetchSellers()
  }
}
</script>