<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="`export_sales_detail-by-${product}_report`"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchSalesDetailByProducts} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportProductSalesDetail",
  props:['product','filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        date:{title:'Fecha',},
        work_order:{title:'OT'},
        sales_note:{title:'Cot.'},
        work_order_name: { title: 'Proyecto' },
        customer: { title: 'Cliente' },
        seller: { title: 'Vendedor' },
        qty:{title:'Cant.'},
        mts2:{title:'Mts2'},
        total_sales_price:{title:'Venta'},
       },
       csvTitle:`export_sales_detail_by_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading = true
      this.items=this.product.map(item=>{
        return{
          ...item,
          date:moment(item.date).format('DD/MM/YYYY'),
          qty:this.$options.filters.number(item.qty,'0'),
          mts2:this.$options.filters.number(item.mts2,'0'),
          total_sales_price:this.$options.filters.number(item.total_sales_price,'0'),
        }
      })
      this.dataReady=true
      this.loading = false
    },
    handleSuccess(val){
      notifications.showSuccess('Documentos Exportados')
      this.dataReady=false
    },
    handleError(val){
      notifications.showError('Error Exportando Documentos')
      this.dataReady=false
    }
  }

}
</script>