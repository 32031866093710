<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import notifications from '@/utils/notifications'
import {fetchArticles} from '@/api/stocks'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
export default {
  name:"ExportStock",
  props:['family','store','disabled',],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      items:[],
      dataReady:false,
      labels:{ 
        sku:{title:'Código'},
        name:{title:'Descripción'},
        family_name: { title: 'Familia' },
        unit_name:{title:'Unidad'},
        stock:{title:'Existencias'},
        available_stock:{title:'Disponibles'},
        avg:{title:'Valor Promedio'},
        total:{title:'Valor Total'},
        last_buy_price:{title:'Precio Ult. Compra'},
        last_buy_date:{title:'Fecha Ult. Compra'},
        last_buy_qty:{title:'Cant. Ult. Compra'},
        notes:{title:'Observaciones'},
       },
       csvTitle:`export_inventario_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    totalStock(item){
      if(this.store){
        const filteredStocks = item.stocks.filter(i=>i.store==this.store)
        return filteredStocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
      }
      else return item.stocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
    },
    availableStock(item){
      if(this.store){
        const filteredStocks = item.stocks.filter(i=>i.store==this.store&&i.add_stock)
        return filteredStocks.reduce((acc, val)=>{ return acc + parseInt(val.qty); }, 0)
      }
      else if(this.store&&!this.store.add_stock){
        return 0
      }
      else return item.stocks.filter(i=>i.add_stock).reduce((acc, val)=>{ return acc + parseInt(val.qty) }, 0)
    },
    async fetch(){
      this.loading=true
      if (!this.family) this.family=''
      if (!this.store) this.store=''
      const query=`page=1&page_size=-1&family=${this.family}&stocks__store=${this.store}`
      await fetchArticles(query)
      .then(res=>{
        this.total=res.count
        console.log(res.results)
        const results=res.results.map(item=>{
          return {
            sku: item.sku,
            name: item.name.replaceAll("\"", ""),
            family_name: item.family_name,
            unit_name: item.unit_name,
            stock: numeral(this.totalStock(item)).format('0[.]0'),
            available_stock: numeral(this.availableStock(item)).format('0[.]0'),
            avg: numeral(item.avg).format('0'),
            notes: item.notes,
            total: numeral(parseFloat(item.avg)*parseFloat(this.totalStock(item))).format('0'),
            last_buy_price: numeral(item.last_buy_price).format('0'),
            last_buy_date: moment(item.last_buy_date).format('DD-MM-YYYY'),
            last_buy_qty: numeral(item.last_buy_qty).format('0')
          }
        })
        this.items=results
        this.dataReady=true
      })
      .catch((err)=>{
        notifications.showError('Error cargando artículos '+err)
      })
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Inventario Exportado')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Inventario')
      this.dataReady=false
    }
  }

}
</script>