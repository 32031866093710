<template>
<div>
  <v-btn :disabled="disabled" color="medium_blue" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="workOrders"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchPostCostWorkOrders,} from '@/api/reports'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
import numeral from 'numeral'
export default {
  name:"ExportInvoiceWorkOrders",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      workOrders:[],
      dataReady:false,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        name: { title: 'Proyecto' },
        sales_note:{title:'Nota de Venta'},
        customer_name:{title:'Cliente'},
        seller_full_name:{title:'Vendedor'},
        total_sale:{title:'Venta'},
        total_invoiced:{title:'Neto Fact.'},
       },
       csvTitle:`export_work-order_invoice_report_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.seller) this.filter.seller=''
      if(!this.filter.has_invoiced) this.filter.has_invoiced=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=1&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&sales_note__customer__seller=${this.filter.seller}&has_invoices=${this.filter.has_invoiced}`
      await fetchPostCostWorkOrders(query)
      .then((data)=>{
        console.log(data)
        this.workOrders = data.results
        this.workOrders.forEach(w=>{
          w.created_at=moment(w.created_at).format("DD/MM/yyyy HH:mm")
          w.total_sale=numeral(w.real_price.sales_total).format('0')
          w.total_invoiced=numeral(w.total_invoiced_payed.invoiced).format('0')
          
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>