import moment from 'moment'
import { fetchCompany } from '@/api/parameters'
var numeral = require('numeral');

moment.locale('es');

export async function pdfQuotation(content) {
    var pdfMake = require("pdfmake/build/pdfmake.js");
    var pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Trayendo datos de la compañía
    const company = await fetchCompany()
    console.log(company)
    //Estableciendo la fecha de emisión
    const quotationDate = moment(new Date()).format('DD-MMM-YYYY');

    //Formato a la dirección del cliente
    const customerFullAddress = content.sales_note_customer.addresses[0].street + ' ' + content.sales_note_customer.addresses[0].number + ' ' + content.sales_note_customer.addresses[0].suite
    const customerComuneAddress = content.sales_note_customer.addresses[0].comune + ' - ' + content.sales_note_customer.addresses[0].region
    const contact = content.sales_note_contact.first_name + ' ' + content.sales_note_contact.last_name
    //configuración de formado de números 
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'
    const IVA = company.tax

    //Cálculo de IVA y Total con el IVA
    const totalIVA = content.totals.total * IVA / 100
    const totalconIVA = content.totals.total + totalIVA

    

    pdfMake.fonts = {
        Poppins: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-SemiBold.ttf"
        },
        RobotoCondensed: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Bold.ttf"
        }
    };

    var dd = {
        pageSize: "LETTER",
        content: [

            { //Logo 
                image: 'logo',
                width: 120,
                margin: [0, 0, 0, 10]
            },
            //Encabezado esquina superior izquierda
            { text: company.name },
            { text: company.giro },
            { text: company.address },
            { text: 'Fono: ' + company.phone + ' - ' + company.email + ' - ' + company.webpage },
            { text: 'Fecha: ' + quotationDate, alignment: 'right', fontSize: 12, margin: [0, 20, 0, 0] },
            //Encabezado esquina superior derecha
            {
                absolutePosition: { x: 390, y: 45 },
                margin: [0, 10, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: [170, ],
                    heights: [25, 10, 40, 10, 10],
                    body: [
                        [{ text: 'RUT.: ' + company.rut, alignment: 'center', fontSize: 12, color: 'red' }, ],
                        [{ text: 'COTIZACIÓN', alignment: 'center', fontSize: 20, bold: true, }, ],
                        [{ text: 'Nro. ' + content.id, alignment: 'center', fontSize: 20, bold: true, }, ],
                    ]
                }
            },
            //Dibujos en Canvas
            { //Cuadro Rojo esquina superior derecha
                absolutePosition: { x: 385, y: 45 },
                canvas: [{
                    type: 'rect',
                    x: 0,
                    y: 0,
                    w: 180,
                    h: 100,
                    r: 1,
                    lineWidth: 3,
                    lineColor: 'red',
                    color: '',
                }, ]
            },

            // Encabezado centro
            {
                layout: 'noBorders',
                table: {
                    widths: ['11%', '50%', '20%', '19%'],
                    body: [
                        [
                            { text: 'Señor(es):', alignment: 'left' },
                            { text: content.sales_note_customer.name, alignment: 'left', bold: true, },
                            { text: 'Vendedor:', alignment: 'left' },
                            { text: content.sales_note_customer.seller.first_name + ' ' + content.sales_note_customer.seller.last_name, alignment: 'left', bold: true, },
                        ],
                        [
                            { text: 'RUT:', alignment: 'left' },
                            { text: content.sales_note_customer.rut, alignment: 'left', bold: true, },
                            { text: 'Condición de Venta:', alignment: 'left' },
                            /* { text: parseInt(content.sales_note_customer.payment) < 1 ? 'Contado' : `Pago a ${content.sales_note_customer.payment} días`, alignment: 'left', bold: true, }, */
                            { text: content.payment_condition, alignment: 'left', bold: true, },
                        ],
                        [
                            { text: 'Giro:', alignment: 'left' },
                            { text: content.sales_note_customer.giro, alignment: 'left', bold: true, },
                            {},
                            {},
                        ],
                        [
                            { text: 'Dirección:', alignment: 'left' },
                            { text: customerFullAddress, alignment: 'left', bold: true, },
                            {},
                            {},
                        ],
                        [
                            { text: 'Referencia:', alignment: 'left' },
                            { colSpan: 3, text: content.name, alignment: 'left', bold: true, },
                            '',
                            '',
                        ],
                        [
                            { text: 'Contacto:', alignment: 'left' },
                            { colSpan: 3, text: contact, alignment: 'left', bold: true, },
                            '',
                            '',
                        ]
                    ]
                }
            },
            //Tabla con Items a Cotizar Función
            {
                layout: 'lightHorizontalLines',
                style: 'table',
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['6%', '50%', '10%', '17%', '17%'],
                    body: buildItemsTableBody(content.details)
                }
            },
            //Sección Observaciones
            {
                margin: [0, 10, 0, 0],
                layout: 'noBorders',
                table: {
                    widths: ['15%', '85%'],
                    body: [
                        [
                            { text: 'Observaciones:', alignment: 'left' },
                            { text: content.details.notes, alignment: 'left' },
                        ],
                    ]
                }
            },
            //Sección de Totales
            {
                margin: [250, 10, 0, 0],
                table: {
                    widths: ['15%', '15%', '30%', '40%'],
                    body: totalsTable(content,totalIVA,totalconIVA) /* [
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Subtotal', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.price).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }
                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: `Descuento (${content.totals.discount}%)`, alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.totalDiscount).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }

                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Neto', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.price - content.totals.totalDiscount).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }

                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '19% IVA', alignment: 'left', border: [false, false, false, true] },
                            { text: numeral(totalIVA).format('$0,0'), alignment: 'right', bold: true, border: [false, false, false, true] },
                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Total', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(totalconIVA).format('$0,0'), alignment: 'right', bold: true, border: [false, false, false, false] },
                        ],
                    ] */
                },
                unbreakable: true 
            },
        ],

        footer: {
            columns: [{
                text: company.fantasy + ' - ' + company.webpage,
                alignment: "center",
                style: "footer"
            }]
        },
        images: {
            logo: company.logo_url
        },
        defaultStyle: {
            font: "RobotoCondensed",
            fontSize: 10,
            color: "#5B5A59"
        },
        styles: {
            h1: {
                font: "RobotoCondensed",
                fontSize: 20,
                bold: true,
                alignment: "center",
                color: "#7A3D8A"
            },
            h2: {
                font: "RobotoCondensed",
                fontSize: 12,
                bold: true,
                alignment: "left",
                color: "#5B5A59"
            },
            p: {
                font: "RobotoCondensed",
                fontSize: 10,
                lineHeight: 1.2,
                color: "#5B5A59"
            },
            reportHeader: {
                font: "RobotoCondensed",
                fontSize: 10,
                lineHeight: 1.5,
                color: "#5B5A59"
            },
            footer: {
                font: "RobotoCondensed",
                fontSize: 8,
                color: "#5B5A59"
            },
            table: {
                font: "RobotoCondensed",
                fontSize: 9,
                color: "#5B5A59"
            }
        }
    };
    const quotation_title = content.name.length > 150? content.name.replace(' ','_').substring(0, 150) : content.name.replace(' ','_');
    pdfMake.createPdf(dd).download(`Cotizacion_${content.id}_${quotation_title}.pdf`);
}

const totalsTable = (content,totalIVA,totalconIVA) => {
    const tableBody = [[
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Subtotal', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.price).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }
                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: `Descuento (${content.totals.discount}%)`, alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.totalDiscount).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }

                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Neto', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(content.totals.price - content.totals.totalDiscount).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, false] }

                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '19% IVA', alignment: 'left', border: [false, false, false, true] },
                            { text: numeral(totalIVA).format('$0,0'), alignment: 'right', bold: false, border: [false, false, false, true] },
                        ],
                        [
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: '', alignment: 'left', border: [false, false, false, false] },
                            { text: 'Total', alignment: 'left', border: [false, false, false, false] },
                            { text: numeral(totalconIVA).format('$0,0'), alignment: 'right', bold: true, border: [false, false, false, false] },
                        ]]
    if(content.totals.totalDiscount === 0){
        tableBody.splice(0,2)
    }
    return tableBody
}

function buildItemsTableBody(data) {
    var body = [];
    var tableData = [];
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'

    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'qty', 'price', 'total']

    //Creando el Header de la Table
    body.push(['Item', 'Descripción', 'Cantidad', { text: 'Precio Unit', alignment: 'right' }, { text: 'Valor', alignment: 'right' }]);

    //preparando la data para crear la tabla
    //Incluimos los productos
    data.products.map(item => {
            tableData.push({
                item: { text: data.products.indexOf(item)+1, alignment: 'center' },
                name: item.name + ' Tam: ' + new Intl.NumberFormat(numberFormat).format(item.width) + 'x' + new Intl.NumberFormat(numberFormat).format(item.height) + ' cms' + (item.notes ? `\n${item.notes}` : ''),
                qty: { text:  item.qty, alignment: 'center' },
                price: { text: item.sell_unit_m2 ? numeral(item.price*item.width*item.height*0.0001).format('$0,0'):numeral(item.price).format('$0,0'), alignment: 'right' },
                total: { text: numeral(item.totalPrice).format('$0,0'), alignment: 'right' },
            })
        })
        //Incluimos costos adicionales 
    data.costs.map(item => {
        tableData.push({
            item: { text: '-', alignment: 'center' },
            name: item.name,
            qty: { text: new Intl.NumberFormat(numberFormat).format(item.qty), alignment: 'center' },
            price: { text: numeral(item.sell_price).format('$0,0'), alignment: 'right' },
            total: { text: numeral(item.totalPrice).format('$0,0'), alignment: 'right' },
        })
    })

    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}