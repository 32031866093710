var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4",attrs:{"justify":"space-between","align-content":"center"}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Material Comprometido al "+_vm._s(_vm._f("moment")(new Date(),"DD/MM/YYYY HH:mm")))]),_c('export-supply-pending',{attrs:{"data":_vm.supplies}})],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"text","item-value":"value","label":"Grupo","placeholder":"Todos","prepend-inner-icon":"filter_alt","outlined":"","clearable":"","hide-details":"","dense":"","disabled":_vm.loading},model:{value:(_vm.filter_group),callback:function ($$v) {_vm.filter_group=$$v},expression:"filter_group"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.supplies,"search":_vm.search,"sort-by":"pending","sort-desc":true,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
      }},scopedSlots:_vm._u([{key:"item.qty",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.qty,'0,0.00'))+" ")]}},{key:"item.consumed",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.consumed,'0,0.00'))+" ")]}},{key:"item.pending",fn:function(ref){
      var item = ref.item;
return [_c('strong',{class:(item.pending)<0?'error--text':''},[_vm._v(_vm._s(_vm._f("number")(item.pending,'0,0.00')))])]}},{key:"item.article_sku",fn:function(ref){
      var item = ref.item;
return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.push({name:'DetailedArticle',params:{id:item.article_id}})}}},[_vm._v(_vm._s(item.article_sku))])]}},{key:"item.stock",fn:function(ref){
      var item = ref.item;
return [_c('strong',{class:(item.stock)<0?'error--text':''},[_vm._v(_vm._s(item.stock))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }