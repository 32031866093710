<template>
	<v-container>
		<v-row v-if="approbing" justify="center" align-content="center">
			<div class="text text-h4 success--text"><v-icon class="mr-2 success--text">fas fa-handshake</v-icon><strong>Confirmación de Negocio</strong></div>
		</v-row>
		<v-row class="mb-2 justify-space-between shrink">
			<v-col>
				<h1 class="text text-h4 titulo">Cotización #<strong>{{salesNote.id}}</strong></h1>
				<div class="text text-h7 mt-2">Creada por: <strong>{{salesNote.created_by_full_name}} - {{salesNote.created_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
				<div class="text" v-if="salesNote.updated_by" >Actualizada: <strong>{{salesNote.updated_by_full_name}} - {{salesNote.updated_at| moment('DD/MMM/YYYY h:mm a')}}</strong></div>
			</v-col>
			<v-col class="text-end" v-if="!approbing" >
				<div class="flex justify-space-around">
				<v-chip label outlined large v-if="salesNote.work_order"><v-icon class="mr-2 success--text">fas fa-handshake</v-icon>Aprobada</v-chip>
				<v-chip label outlined large v-else class="info--text">Ingresada</v-chip>
						<v-menu
							offset-y
							bottom left 
							v-if="!approbing"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									text
								>
									<v-icon class="ml-2">more_vert</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item>
									<v-btn text @click.stop="edit()" class="mr-2" ><v-icon class="mr-2">fas fa-pen</v-icon>Modificar</v-btn>
								</v-list-item>
								<v-list-item v-if="$can('readCost', 'SalesNote')">
									<v-btn text @click.stop="generateCostReport" class="mr-2" ><v-icon class="mr-2">fas fa-calculator</v-icon>Informe Costos</v-btn>
								</v-list-item>
								<v-list-item>
									<v-btn text @click.stop="generateQuotation" class="mr-2" ><v-icon class="mr-2">print</v-icon>Presupuesto Cliente</v-btn>
								</v-list-item>
								<v-list-item v-if="$can('duplicate', 'SalesNote')">
									<v-btn text @click.stop="duplicate" class="mr-2" ><v-icon class="mr-2">content_copy</v-icon>Duplicar</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					<v-btn @click="$emit('close')" class="ml-2" text ><v-icon class="mr-2">fas fa-chevron-left</v-icon></v-btn>
					</div>
			</v-col>
		</v-row>
	
	<v-row>
		<v-col cols="12" md="6">
				<table>
					<tbody>
						<tr>
							<td class="text-right">
								Cliente:
							</td>
							<td class="text-left px-3">
								<div class="font-weight-black ">{{salesNote.sales_note_customer.name}}</div>
							</td>
						</tr>
						<tr>
							<td class="text-right">
								Contacto:
							</td>
							<td class="text-left px-3">
								<div class="font-weight-black ">{{`${salesNote.sales_note_contact.first_name} ${salesNote.sales_note_contact.last_name}`}}</div>
							</td>
						</tr>
						<tr>
							<td class="text-right">
								Proyecto:
							</td>
							<td class="text-left px-3">
								<div class="font-weight-black ">{{salesNote.name}}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</v-col>
			<v-col cols="12" md="6">
				<table>
					<tbody>
						<tr>
							<td class="text-right" v-if="salesNote.work_order">
								Orden de Trabajo:
							</td>
							<td class="text-left px-3" v-if="salesNote.work_order">
								<div class="font-weight-black ">#{{salesNote.work_order}}</div>
							</td>
						</tr>
						<tr>
							<td class="text-right">
								Vendedor:
							</td>
							<td class="text-left px-3">
								<div class="font-weight-black ">{{`${salesNote.sales_note_customer.seller.first_name} ${salesNote.sales_note_customer.seller.last_name}`}}</div>
							</td>
						</tr>
						<tr>
							<td class="text-right">
								Observaciones:
							</td>
							<td class="text-left px-3">
								<div class="font-weight-black ">{{salesNote.notes}}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</v-col>
	</v-row>
	
	<v-row class="justify-end" v-if="$store.getters.getCompany.distribution_list_enabled">
		<v-col cols="4" class="text-end">
			<distribution-list :sales_note="salesNote"/>
		</v-col>
	</v-row>
	<v-row>
		<v-col>
			<v-card>
				<v-simple-table dense class='strip-table'>
					<thead>
						<tr>
							<th class="text-center strip-header">Tot. Mts<sup>2</sup></th>
							<th class="text-center strip-header" v-if="$can('readCost', 'SalesNote')">Costo</th>
							<th class="text-center strip-header">Valor de Venta</th>
							<th class="text-center strip-header">Descuento</th>
							<th class="text-center strip-header">Total Venta</th>
							<th class="text-center strip-header" v-if="$can('readCost', 'SalesNote')">Margen</th>  
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="text-center strip-header"><strong>{{salesNote.totals.mts2| number('0,0.00')}}</strong></td>
							<td class="text-center strip-header" v-if="$can('readCost', 'SalesNote')"><strong class="purple--text ">{{salesNote.totals.cost | currency('$',0) }}</strong></td>
							<td class="text-center strip-header"><strong :class="salesNote.totals.price>salesNote.totals.cost?'purple--text':'red white--text pa-2'" >{{salesNote.totals.price | currency('$',0) }}</strong></td>
							<td class="text-center strip-header"><strong>{{salesNote.totals.discount}}% ({{salesNote.totals.totalDiscount | currency('$',0)}})</strong></td>
							<td class="text-center strip-header"><strong :class="salesNote.totals.total>salesNote.totals.cost?'purple--text':'red white--text pa-2'">{{salesNote.totals.total | currency('$',0) }}</strong></td>
							<td class="text-center strip-header" v-if="$can('readCost', 'SalesNote')"><strong :class="salesNote.totals.margin>0?'purple--text':'red white--text pa-2'">{{salesNote.totals.margin | percent(1)  }}</strong></td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card>
		</v-col>
	</v-row>
	<v-divider class="my-4"></v-divider>
	<v-row justify="center">
		<v-col>
			<v-data-table
		:headers="productsTableHeaders"
		:items="salesNote.details.products"
		:single-expand="true"
		expanded.sync="expanded"
		expand-icon="fas fa-chevron-down"
		item-key="key"
		show-expand
		class="flex d-block"
		
		>
			<template v-slot:top>
				<div class="text text-h5 titulo-2">Productos</div>
			</template>
			<template v-slot:item.name="{item}">
				{{item.name}}
			</template>
			<template v-slot:item.width="{item}">
				{{item.width | number('0,0.00')}}
			</template>
			<template v-slot:item.height="{item}">
				{{item.height | number('0,0.00')}}
			</template>
			<template v-slot:item.qty="{item}">
				{{item.qty}}
			</template>
			<template v-slot:item.totalMts2="{item}">
				{{item.totalMts2 | number('0,0.00')}}
			</template>
			<template  v-slot:item.unitCost="{item}">
              <strong v-if="item.sell_unit_m2"  class="cost" color-text="primary">{{(item.totalCost/item.totalMts2) | currency('$',0)}}</strong>
              <strong v-else class="cost" color-text="primary">{{(item.totalCost/item.qty) | currency('$',0)}}</strong>
              <!-- <strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong> -->
              <v-icon small class="ml-2">{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon> 
            </template>
			<template v-slot:item.totalCost="{item}">
				<strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
			</template>
			<template v-slot:item.price="{item}">
				<v-icon small class="mr-2 yellow--text" v-if="item.price_bellow_original">fas fa-exclamation-triangle</v-icon>
				{{item.price  | currency('$',0)}}
				<v-icon>{{item.sell_unit_m2 ? 'square_foot':'view_comfy'}}</v-icon>
			</template>
			<template v-slot:item.totalPrice="{item}">
				<div :class="item.totalPrice>item.totalCost ? 'green--text':'red white--text'">
					<strong>{{item.totalPrice | currency('$',0)}}</strong>
				</div>
			</template> 
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="px-10">
					<v-row>
							<recipe 
								:mts2="item.totalMts2" 
								:total="item.totalCost" 
								:qty="item.qty" 
								:items="item.product_receta"
								:readonly="true"
								/>
					</v-row>
						<v-row>
							<supplies 
								:mts2="item.totalMts2" 
								:total="item.totalCost" 
								:qty="item.qty" 
								:items="item.product_supplies"
								:readonly="true"
								:product_width="item.width"
								:product_height="item.height"
							/>
						</v-row>
					<v-row class="mt-2 ml-4">
						<p class="font-italic">{{item.notes}}</p>
					</v-row>
				</td>
			</template>
		</v-data-table>
		</v-col>
		
	</v-row>
	<v-divider></v-divider>
	<v-row v-if="salesNote.details.costs.length>0" justify="center" class="mt-4">
		<v-data-table
		:headers="proyectCostsHeaders"
		:items="salesNote.details.costs"
		item-key="key2"
		class="flex d-block"
		dense
		hide-default-footer
		>
			<template v-slot:top>
				<div class="text text-h5 titulo-2 mb-2 mt-4">Costos Adicionales</div>
			</template>
			<template v-slot:item.name="{item}">
				{{item.name}}
			</template>
			<template v-slot:item.qty="{item}">
				{{item.qty}}
			</template>
			<template v-slot:item.unit="{item}">
				{{item.cost_unit.name}}
			</template>
			<template  v-slot:item.unitCost="{item}">
				<strong class="cost" color-text="primary">{{item.unitCost | currency('$',0)}}</strong>
			</template>
			<template v-slot:item.totalCost="{item}">
				<strong class="cost">{{item.totalCost | currency('$',0)}}</strong>
			</template>
			<template v-slot:item.price="{item}">
				{{item.sell_price| currency('$',0)}}
				<v-icon>{{item.unit.id==1?'square_foot':''}}</v-icon>
			</template>
			<template v-slot:item.totalPrice="{item}">
				<div :class="item.totalPrice>=item.totalCost ? 'green--text':'red white--text'">
					<strong>{{item.totalPrice | currency('$',0)}}</strong>
				</div>
			</template> 
		</v-data-table>
	</v-row>
	<v-row v-if="salesNote.files.length>0">
		<v-card class="grow mt-4" flat>
			<v-card-title class="text titulo-2 text-h5">Documentos</v-card-title>
			<v-card-text>
				<v-simple-table>
					<tbody>
						<tr v-for="file in salesNote.files" :key="file.url">
							<td>
								<a :href="file.url" target="_blank">{{file.name}}</a>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
		</v-card>
	</v-row>  
	</v-container>
</template>
<script>
import Recipe from '@/components/products/products/Recipe'
import DeliveryEstimation from '@/components/sales/DeliveryEstimation.vue'
import Supplies from '@/components/products/products/Supplies'
import DistributionList from '@/components/sales/DistributionList'
import {pdfQuotation} from '@/doc_generators/quotation'
import {pdfCostReport} from '@/doc_generators/costReport'
export default {
	name:'SalesDetail',
	components:{Recipe,Supplies,DeliveryEstimation,DistributionList},
	props:{
		salesNote:{
			type:Object,
		},
		approbing:{
			type: Boolean
		}
	},
	data() {
		return {
			//salesNote:'',
		//   productsTableHeaders:[
			
		//   { text: 'Código', value: 'sku' ,class:"table-header-dense"},
		//   { text: 'Descripción', value: 'name' ,class:"table-header-dense"},
		//   { text: 'Cant.', value: 'qty',class:"table-header-dense", align:'end' },
		//   {text:'An.',value:'width',class:"table-header-dense", align:'end'},
		//   {text: 'Alt.',value:'height',class:"table-header-dense", align:'end'},
		//   { text: 'Mts2', value: 'totalMts2' ,class:"table-header-dense", align:'end'},
		//   { text: 'Costo Unit.', value: 'unitCost' ,class:"table-header-dense", align:'end'},
		//   { text: 'Costo Tot.', value: 'totalCost' ,class:"table-header-dense", align:'end'},
		//   { text: 'Prec. Unit.', value:'price',class:"table-header-dense",align:'end'},
		//   { text: 'Prec. Total',value:'totalPrice',class:"table-header-dense",align:'end'},
		//   { text: '', value: 'data-table-expand' ,class:"table-header-dense"},
		// ],
		proyectCostsHeaders:[
			{ text: 'Descripción', value: 'name',class:"table-header-dense" },
			{ text: 'Cantidad', value: 'qty',class:"table-header-dense" , align:'end'},
			{ text: 'Unidad', value: 'cost_unit',class:"table-header-dense" },
			{ text: 'Costo Unit.', value: 'unitCost' ,class:"table-header-dense",align:'end'},
			{ text: 'Costo Total', value: 'totalCost' ,class:"table-header-dense",align:'end'},
			{ text: 'Prec. Unit.', value:'price',class:"table-header-dense",align:'end'},
			{ text: 'Prec. Total',value:'totalPrice',class:"table-header-dense",align:'end'},
		]
		}
	},
	methods:{
		edit(){
			this.$router.push({name:'SalesNoteCreateUpdate',params:{id:this.salesNote.id}})
		},
		duplicate(){
			this.$router.push({name:'SalesNoteCreateUpdate',params:{id:this.salesNote.id, duplicate:true}})
		},
		generateQuotation(){
			pdfQuotation(this.salesNote)
		},
		generateCostReport(){
			pdfCostReport(this.salesNote)
		},
	},
	computed:{
		productsTableHeaders(){
			const header = [
			{text:'Código',value:'sku',class:'table-header-dense',available:true},
			{ text: 'Desc.', value: 'name',class:'table-header-dense' ,available:true},
			{ text: 'Cant.', value: 'qty' ,class:'table-header-dense',available:true},
			{ text: 'Mts2', value: 'totalMts2' ,class:'table-header-dense',available:true},
			{ text: 'Cost. Unit.', value: 'unitCost' ,class:'table-header-dense',available:this.$can('readCost', 'SalesNote')},
			{ text: 'Cost. Total', value: 'totalCost' ,class:'table-header-dense',available:this.$can('readCost', 'SalesNote')},
			{ text: 'Prec. Unit.', value:'price',class:'table-header-dense',available:true},
			{ text: 'Prec. Total',value:'totalPrice',class:'table-header-dense',available:true},
			{ text: '', value: 'actions' ,class:'table-header-dense',available:true},
			{ text: '', value: 'data-table-expand' ,class:'table-header-dense',available:true},
		]
		 return header.filter(item=>item.available)
		}
	},

}
</script>