<template>
<div>
  <v-btn :disabled="disabled" color="primary" :loading="loading" @click="fetch" text><v-icon class="mr-2">fas fa-file-export</v-icon> Exportar</v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="workOrders"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Lista</b>
          </v-btn>
        </vue-json-to-csv>
        <vue-json-to-csv
          class="ml-2"
          :json-data="details"
          :labels="labelsDetails"
          :csv-title="csvTitleDetail"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Detalle</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import {fetchWorkOrders} from '@/api/production'
import VueJsonToCsv from 'vue-json-to-csv'
import moment from 'moment'
export default {
  name:"ExportWorkOrders",
  props:['filter','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      workOrders:[],
      dataReady:false,
      labels:{ 
        id:{title:'Nro'},
        created_at:{title:'Creada'},
        name: { title: 'Proyecto' },
        sales_note:{title:'Nota de Venta'},
        customer_name:{title:'Cliente'},
        seller_full_name:{title:'Vendedor'},
        delivery_date_main:{title:'Fecha Comprometida'},
        // install_date:{title:'Instalación'},
        // uninstall_date:{title:'Deinstalación'},
       },
       labelsDetails:{
        delivery_date:{title:'Fecha Comprometida'},
        line_name:{title:'Línea'},
        work_order:{title:'OT'},
        prestage_name:{title:'Etapa'},
        delivery_type:{title:'Tipo de Entrega'},
        delivery_time:{title:'Hora de Entrega'},
        customer_name:{title:'Cliente'},
        id:{title:'Item'},
        sku:{title:'Producto'},
        name:{title:'Descripción'},
        qty:{title:'Cantidad'},
        width:{title:'Ancho'},
        height:{title:'Alto'},
        mts2:{title:'Mts2'},
       },
       details:[],
       deliveryTypeList:[
      {
        name:'Despacho',
        value: 0
      },
      {
        name:'Retira el Cliente',
        value:1
      },
      {
        name:'Retira Instalador',
        value:2
      },
      ],
       csvTitleDetail:`export_detail_orden_trabajo_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`,
       csvTitle:`export_orden_trabajo_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.delivery_after) this.filter.delivery_after=''
      if(!this.filter.delivery_before) this.filter.delivery_before=''
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      await fetchWorkOrders(
        1,
        -1,
        this.search,
        this.filter.created_after,
        this.filter.created_before,
        this.filter.delivery_after,
        this.filter.delivery_before,
        this.filter.status,
        this.filter.seller,
        sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`
        )
      .then((data)=>{
        this.workOrders = data.results
        this.workOrders.forEach(w=>{
          w.created_at=moment(w.created_at).format("DD/MM/yyyy HH:mm")
          w.delivery_date_main=moment(w.delivery_date).format("DD/MM/yyyy HH:mm")
          w.detail_gaston_delivery=moment(w.delivery_date).format("DD/MM/yyyy")
          w.detail_gaston_delivery_time=moment(w.delivery_date).format("HH:mm")
          w.install_date?moment(w.install_date).format("DD/MM/yyyy HH:mm"):''
          w.uninstall_date?moment(w.uninstall_date).format("DD/MM/yyyy HH:mm"):''
          w.products.forEach(p=>{
            this.details.push({
              work_order:w.id,
              customer_name:w.customer_name,
              delivery_date:w.detail_gaston_delivery,
              delivery_time:w.detail_gaston_delivery_time,
              delivery_type:this.deliveryTypeList.find(d=>d.value==w.delivery_type).name,
              id:p.id,
              sku:p.sku,
              name:p.name,
              qty:p.qty,
              width:p.width,
              height:p.height,
              mts2:p.mts2.toFixed(2),
              prestage_name:p.prestage_name,
              line_name:p.line_name,
            })
          })
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>