var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Pre produccion")]),_c('export-prestage-products',{attrs:{"disabled":_vm.loading,"filter":_vm.filter,"options":_vm.options}})],1),_c('v-card',{staticClass:"pa-6"},[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"mr-4",attrs:{"disabled":_vm.loading,"dense":"","outlined":"","clearable":"","type":"date","label":"Creada Desde:"},on:{"change":function (){this$1.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_after),callback:function ($$v) {_vm.$set(_vm.filter, "created_after", $$v)},expression:"filter.created_after"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.loading,"dense":"","clearable":"","outlined":"","type":"date","label":"Creada Hasta:"},on:{"change":function (){this$1.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_before),callback:function ($$v) {_vm.$set(_vm.filter, "created_before", $$v)},expression:"filter.created_before"}})],1)],1),_c('v-row',{staticClass:"mb-6",attrs:{"justify":"center"}},[_c('v-col',[_c('v-btn-toggle',{attrs:{"mandatory":""},on:{"change":_vm.fetch},model:{value:(_vm.filter.stage),callback:function ($$v) {_vm.$set(_vm.filter, "stage", $$v)},expression:"filter.stage"}},[_c('v-btn',{attrs:{"value":null}},[_vm._v(" Todos ")]),_vm._l((_vm.stages),function(stage,index){return _c('v-btn',{key:index,style:(("color:" + (stage.color))),attrs:{"value":stage.id}},[_vm._v(" "+_vm._s(stage.name)+" ")])})],2)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"show-select":"","item-key":"id","items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"search":_vm.search,"selectable-key":"in_prestage","sort-by":"created_at","sort-desc":false,"single-expand":true,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.showProduct},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.selected.length==0},on:{"click":function($event){$event.stopPropagation();return (function (){
                  _vm.productToChangePrestage=_vm.item;
                  _vm.showChangePrestage=true;
                  })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-bars-staggered")]),_vm._v(" Cambio Etapa ")],1)],1)],1)]},proxy:true},{key:"item.work_order__id",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticStyle:{"text-decoration":"underline","cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showWorkOrder({id:item.work_order__id})}}},[_vm._v(_vm._s(item.work_order__id))])]}},{key:"item.work_order__created_at",fn:function(ref){
                  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.work_order__created_at,"DD/MM/YYYY")))])]}},{key:"item.prestage",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.prestage && item.in_prestage)?_c('v-chip',_vm._g({staticClass:"ma-2",staticStyle:{"width":"100px"},attrs:{"color":item.prestage.color,"label":"","outlined":""}},on),[_vm._v(" "+_vm._s(item.prestage.name)+" ")]):_c('v-chip',_vm._g({staticClass:"ma-2",staticStyle:{"max-width":"100px"},attrs:{"label":"","dark":"","color":"green","small":""}},on),[_c('strong',[_vm._v(" Produccion")])])]}}],null,true)},[(item.pre_stage_logs.length>0)?_c('v-list-item',{attrs:{"two-line":"","dark":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.pre_stage_logs[0].email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("moment")(item.pre_stage_logs[0].date,"DD/MM/YYYY HH:mm")))])],1)],1):_vm._e()],1)]}},{key:"item.work_order__delivery_date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.work_order__delivery_date,"DD/MM/YYYY HH:mm"))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":""}},'v-icon',attrs,false),on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(item.in_prestage)?_c('v-list-item',[_c('v-btn',{attrs:{"dense":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return (function (){
                _vm.productToChangePrestage=item;
                _vm.showChangePrestage=true;
                })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-bars-staggered")]),_vm._v("Cambio Etapa")],1)],1):_vm._e(),(item.in_prestage)?_c('v-list-item',[_c('v-btn',{attrs:{"dense":"","small":"","color":"green","dark":""},on:{"click":function($event){$event.stopPropagation();return (function (){
                _vm.productToProduction=item;
                _vm.showProductToProduction=true;
                })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-square-caret-right")]),_vm._v("Pasar a Produccion")],1)],1):_vm._e(),_c('v-list-item',[_c('v-btn',{attrs:{"dense":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.printWorkOrder(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-print")]),_vm._v("Imprimir OT")],1)],1)],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{staticClass:"pa-4"},[_c('work-order-card',{attrs:{"work_order":_vm.workOrderOnDisplay,"loading":_vm.workOrderOnDisplayLoading},on:{"close":function($event){_vm.display=false},"refresh":_vm.fetch}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700","transition":"dialog-bottom-transition"},model:{value:(_vm.showChangePrestage),callback:function ($$v) {_vm.showChangePrestage=$$v},expression:"showChangePrestage"}},[_c('v-card',{staticClass:"pa-4"},[_c('change-prestage',{attrs:{"products":_vm.selected},on:{"close":function($event){_vm.showChangePrestage=false},"updated":function (){_vm.selected=[];_vm.fetch()}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.showProductModal),callback:function ($$v) {_vm.showProductModal=$$v},expression:"showProductModal"}},[_c('detail-prestages',{attrs:{"product":_vm.productOnDisplay}})],1),(_vm.productToProduction)?_c('confirm',{attrs:{"message":("¿Confirma pasar a Produccion [" + (_vm.productToProduction.library_product__sku) + "] " + (_vm.productToProduction.name) + " de la orden # " + (_vm.productToProduction.work_order__id) + "?")},on:{"ok":function($event){return _vm.changeToProduction(_vm.productToProduction)},"cancel":function (){
    _vm.showProductToProduction=false
    _vm.productToProduction='';
    }},model:{value:(_vm.showProductToProduction),callback:function ($$v) {_vm.showProductToProduction=$$v},expression:"showProductToProduction"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }