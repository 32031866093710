<template>
  <v-container>
    <v-row class="mb-4" justify="space-between" align-content="center">
      <h1 class="text text-h4 titulo">Material Comprometido al {{ new Date()|moment("DD/MM/YYYY HH:mm")}}</h1>
      <export-supply-pending :data="supplies"/>
    </v-row>
    <v-card class="pa-4">
      <v-row justify="end" >
        <v-col  align-self="center">
            <v-select
              v-model="filter_group"
              :items="groups"
              item-text="text"
              item-value="value"
              label="Grupo"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              hide-details
              dense
              :disabled="loading"
            />
          </v-col>
        <v-col align-self="center">
          <v-text-field
            v-model="search"
            label="Buscar"
            append-icon="fas fa-search"
            :disabled="loading"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <v-data-table
        :headers="headers"
        :items="supplies"
        :search="search"
        sort-by="pending"
        :sort-desc="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }"
      >
        <template v-slot:item.qty="{item}">
            {{item.qty | number('0,0.00')}}
        </template>
        <template v-slot:item.consumed="{item}">
            {{item.consumed| number('0,0.00')}}
        </template>
        <template v-slot:item.pending="{item}">
          <strong :class="(item.pending)<0?'error--text':''">{{item.pending| number('0,0.00')}}</strong>
        </template>
        <template v-slot:item.article_sku="{item}">
          <a @click.prevent="$router.push({name:'DetailedArticle',params:{id:item.article_id}})">{{item.article_sku}}</a>
        </template>
        <template v-slot:item.stock="{item}">
          <strong :class="(item.stock)<0?'error--text':''">{{item.stock}}</strong>
        </template>
        </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {fetchSupplyOrders} from '@/api/reports'
import SalesProductGraph from '@/components/reports/SalesProductGraph'
import ExportSupplyPending from '@/components/reports/ExportSupplyPending'
import moment from 'moment'
export default {
  name:"SupplyOrdersPendingReport",
  components:{SalesProductGraph,ExportSupplyPending},
  created(){
    this.fetch()
  },
  data() {
    return {
      loading: false,
      filter:{
        created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
        created_before:'',
      },
      search:'',
      supplies:[],
      headers:[
          //{text: 'Código',align: 'start',value: 'sku',class: 'table-header'},
          {text: 'Descripción',align: 'start',value: 'name',class: 'table-header-2'},
          //{text: 'Grupo',value:'group',class: 'table-header-2'},
          {text: 'Unidades',align: 'end',value: 'unit',class: 'table-header-2'},
          {text: 'Cantidad',align: 'end',value: 'qty',class: 'table-header-2'},
          {text: 'Consumido',align: 'end',value: 'consumed',class: 'table-header-2'},
          {text: 'Pendiente',align: 'end',value: 'pending',class: 'table-header-2'},
          {text: 'Arti.',align: 'start',value: 'article_sku',class: 'table-header-2'},
          {text:'Exist.',align:'end',value:'stock',class:'table-header-2'},
        ],
    groups:[],
    filter_group:''
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      //const {sortBy, sortDesc,page, itemsPerPage }=this.options
      await fetchSupplyOrders(false)
      .then((res)=>{
        this.loading=false
        this.supplies=this.processData(res)
      })
    },
    processData(rawSupplies){
      console.log(rawSupplies)
      //Agrupo las ordenes por nombre de material
      let unique = [...new Set(rawSupplies.map(item =>
        item.name
      ))];

      //Agrupo las ordenes por grupo
      this.groups =  [...new Set(rawSupplies.map(item =>
        item.group_name
      ))];

      //Saco resultados del filtro por nombre
      const results=unique.map(name=>{
        const supplies=rawSupplies.filter(p=> p.name==name)
        return{
          name:supplies[supplies.length-1].name,
          sku:supplies[supplies.length-1].sku,
          group:supplies[supplies.length-1].group_name,
          unit: supplies[supplies.length-1].unit_name,
          article_sku:supplies[supplies.length-1].article?supplies[supplies.length-1].article.sku:null,
          article_id:supplies[supplies.length-1].article?supplies[supplies.length-1].article.id:null,
          stock:supplies[supplies.length-1].article?supplies[supplies.length-1].article.stocks.filter(i=>i.add_stock).reduce((acc, val)=>{ return acc + parseFloat(val.qty); }, 0):null,
          qty:supplies.reduce((acc, val)=>{ return acc + parseFloat(val.total_pp); }, 0),
          consumed:supplies.reduce((acc, val)=>{ return acc + parseFloat(val.consumed); }, 0),
          pending: supplies.reduce((acc, val)=>{ return acc + parseFloat(val.total_pp); }, 0) - supplies.reduce((acc, val)=>{ return acc + parseFloat(val.consumed); }, 0)
        }
      })
      if(this.filter_group){
        return results.filter(s=>s.group==this.filter_group)
      }
      else return results
    }
  },
  watch:{
    filter:{
      deep: true,
      handler: 'fetch'
    },
  },
}
</script>