<template>
  <v-container>
        
        <v-row>
        <v-col cols="12" >
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            sort-by="date"
            :sort-desc="false"
            :items-per-page="5"
            :loading="loading"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right',
              itemsPerPageOptions: [5,10, 20, 50],
              }"
            >
            <template v-slot:top>
              <v-row justify="end" class="mb-2">
                <export-product-sales-detail 
                :product="items"
                :filter="filter" 
                :options="options" 
                :disabled="loading"/>
              </v-row>
              <v-row justify="end" class="mb-2">
                <v-col cols="6">
                  <v-text-field
                    v-model="search"
                    label="Buscar"
                    append-icon="fas fa-search"
                    :disabled="loading"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.id="{item}">
              <show-work-order :work_order_id="item.id">
                {{item.work_order}}
              </show-work-order>
            </template>
            <template v-slot:item.date="{ item }">
              {{item.date| moment("DD/MM/YYYY")}}
            </template>
            <template v-slot:item.mts2="{ item }">
              {{item.mts2| number('0,0')}}
            </template>
            <template v-slot:item.total_sales_price="{ item }">
              {{item.total_sales_price | currency('$', 0)}}
            </template>
            
          </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {fetchSalesDetailByProducts} from '@/api/reports'
import ExportProductSalesDetail from '@/components/reports/ExportProductSalesDetail'
import notifications from '@/utils/notifications'
export default {
    name:'ProductSalesReportDetail',
    props:['sku','filter','product'],
    components:{ExportProductSalesDetail},
    data(){
        return{
            options:{},
            items:[],
            totalItems:0,
            search:'',
            headers:[
            {
            text: 'Fecha',
            align: 'start',
            value: 'date',
            class: 'table-header-2',
            show:true,
            align:'start',
            sortable: true
            },
            {
            text: 'OT',
            align: 'start',
            value: 'work_order',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Cot.',
            value: 'sales_note',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Proyecto',
            align: 'start',
            value: 'work_order_name',
            class: 'table-header-2',
            show:true,
            sortable: true
            },
            {
            text: 'Cliente',
            align: 'start',
            value: 'customer',
            class: 'table-header-2',
            sortable: true
            }, 
            {
            text: 'Vendedor',
            align: 'start',
            value: 'seller',
            class: 'table-header-2',
            show:true,
            sortable: true
            },
            {
            text: 'Cant.',
            value: 'qty',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
             {
            text: 'Mts2',
            value: 'mts2',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            },
            {
            text: 'Total',
            value: 'total_sales_price',
            class: 'table-header-2',
            show:true,
            align:'end',
            sortable: true
            }, 
            
            ],
            loading:false,
            timer:null,
            iva:this.$store.getters.getCompany.tax, 
        }
    },
    methods:{
      processDetails(){
        const {production_products}=this.product
        let unique = [...new Set(production_products.map(item =>
        item.work_order
        ))];
        this.items=unique.map(work_order=>{
          const productList=production_products.filter(item=>item.work_order===work_order)

          return{
            work_order:work_order,
            date:productList[0].date,
            sales_note:productList[0].sales_note,//productList[0].sales_note,
            work_order_name:productList[0].work_order_name,
            customer:productList[0].customer,
            seller:productList[0].seller,
            qty:productList.reduce((acc,cur)=>acc+cur.qty,0),
            mts2:productList.reduce((acc,cur)=>acc+cur.mts2,0),
            total_sales_price:productList.reduce((acc,cur)=>acc+cur.total_sales_price,0),
          }
        })
        
      },
    },
    watch:{
      sku:{
        handler(){
          this.processDetails()
        },
      },
    },
    mounted(){

      this.processDetails()
    },
   
}
</script>

