import moment from 'moment'
import { fetchCompany } from '@/api/parameters'
import { getSalesNote } from '@/api/salesNotes'
var numeral = require('numeral');

moment.locale('es');

//Declaro variables globales para no tener que enviar a todas las funciones
var wodocumentprintsuppliestable=false;
var wodocumentprintsupppliesresume=false;

export async function pdfWorkOrder(
    content, user,parameters={wodocumentprintsuppliestable:false,
        wodocumentprintsupppliesresume:false}
        ) {
    
    wodocumentprintsuppliestable=parameters.wodocumentprintsuppliestable;
    wodocumentprintsupppliesresume=parameters.wodocumentprintsupppliesresume;


    //Construimos la tabla resumen
    console.log(buildSuppliesResumen(content.products))



    var pdfMake = require("pdfmake/build/pdfmake.js");
    var pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    //Trayendo datos de la compañía
    const company = await fetchCompany()
    const sales_note = await getSalesNote(content.sales_note)

    //Datos y formatos
    const today = moment(new Date()).format('DD-MM-YYYY HH:mm');
    const deliveryDate = moment(content.delivery_date).format('DD-MMMM-YYYY HH:mm');
    const created_by = `${content.created_by.first_name} ${content.created_by.last_name} [${content.created_by.email}]`
    const updated_by = content.updated_by ? `${content.updated_by.first_name} ${content.updated_by.last_name} [${content.updated_by.email}]` : ''
    const created_at = moment(content.created_at).format('DD-MM-YYYY HH:mm')
    const updated_at = content.updated_at ? moment(content.updated_at).format('DD-MM-YYYY HH:mm') : ''
    const printed_by = `${user.first_name} ${user.last_name} [${user.email}]`

    const delivery_type=(delivery_type)=>{
        switch(delivery_type){
            case 0:
                return 'Despacho'
            case 1:
                return 'Retira el cliente'
            case 2:
                return 'Retira instalador'
            default:
    }}

    //configuración de formado de números 
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'
    const IVA = 19
    //console.log(content)

    pdfMake.fonts = {
        Poppins: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/Poppins-SemiBold.ttf"
        },
        RobotoCondensed: {
            normal: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Regular.ttf",
            bold: "https://s3.us-east-2.wasabisys.com/evo/media/public/config/fonts/RobotoCondensed-Bold.ttf"
        }
    };

    var dd = {
        pageSize: "LETTER",
        pageBreakBefore(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
            return currentNode.pageNumbers.length > 1 && currentNode.unbreakable;
        },
        content: [

            
            {
                columns: [
                    { text: `Orden de Trabajo #${content.id}`, fontSize: 20, bold: true, },
                    { text: 'Fecha Comprometida: ' + deliveryDate, fontSize: 12, bold: true, alignment: 'right' },
                ]
            },
            { text: 'Creada: ' + created_by + ' - ' + created_at },
            { text: content.updated_by ? ('Actualizada: ' + updated_by + ' - ' + updated_at) : '' },

            // Encabezado centro
            {
                layout: 'noBorders',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['16%', '16%', '16%', '16%', '16%', '16%'],
                    body: [
                        [
                            { text: 'RUT Cliente:', alignment: 'right' },
                            { text: sales_note.sales_note_customer.rut, alignment: 'left', bold: true, },
                            { text: 'Contacto:', alignment: 'right' },
                            { text: sales_note.sales_note_contact.first_name + ' ' + sales_note.sales_note_contact.last_name, alignment: 'left', bold: true },
                            { text: 'Tipo de Entrega:', alignment: 'right' },
                            { text: delivery_type(content.delivery_type), alignment: 'left', bold: true },
                            /* { text: content.delivery_type > 0 ? 'Retiro de Cliente' : 'Despacho', alignment: 'left', bold: true }, */
                        ],
                        [
                            { text: 'Razón Social:', alignment: 'right' },
                            { text: sales_note.sales_note_customer.name, alignment: 'left', bold: true, },
                            { text: 'O. Compra:', alignment: 'right' },
                            { text: content.purchase_order, alignment: 'left', bold: true, },
                            { text: 'Instalación:', alignment: 'right' },
                            { text: content.install_date ? moment(content.install_date).format('DD-MM-YYYY HH:mm') : '', alignment: 'left', bold: true },
                        ],
                        [
                            { text: 'Nota de Venta:', alignment: 'right' },
                            { text: sales_note.id, alignment: 'left', bold: true, },
                            { text: 'Condición de Pago:', alignment: 'right' },
                            { text: sales_note.sales_note_customer.payment > 0 ? `${sales_note.sales_note_customer.payment} días` : 'Contado', alignment: 'left', bold: true },
                            { text: 'Desinstalación:', alignment: 'right', },
                            { text: content.uninstall_date ? moment(content.uninstall_date).format('DD-MM-YYYY HH:mm') : '', alignment: 'left', bold: true },
                        ],
                        [
                            { text: 'Vendedor:', alignment: 'right' },
                            { text: sales_note.sales_note_customer.seller.first_name + ' ' + sales_note.sales_note_customer.seller.last_name, alignment: 'left', bold: true, },
                            { text: 'Proyecto:', alignment: 'right' },
                            { text: content.name, alignment: 'left', bold: true, colSpan: 3 },
                            { text: [{ text: 'Observaciones: ' }, { text: content.notes, bold: true }] },
                            {},
                        ],
                        [
                            { text: 'Observaciones: ', alignment: 'right' },
                            { text: content.notes, bold: true, aligment: 'left', colSpan: 5 },
                            {},
                            {},
                            {},
                            {}
                        ],
                        [
                            { text: content.customer_approval ? 'Visto Bueno: ' : '', alignment: 'right' },
                            { text: content.customer_approval ? content.approval_notes : '', bold: true, aligment: 'left', colSpan: 5 },
                            {},
                            {},
                            {},
                            {}
                        ]
                    ]
                }
            },
            //Tabla con Productos
            { text: 'Productos:', fontSize: 16, margin: [0, 15, 0, 0], },
            buildProductsTable(content.products),
            { text: content.additional_costs.length > 0 ? 'Costos Adicionales:' : '', fontSize: 16, margin: [0, 15, 0, 0], },
            content.additional_costs.length > 0 ? buildAdditionalCostTable(content.additional_costs) : { text: '' },
            //Resumen de Materiales
            wodocumentprintsupppliesresume?{stack:[

                { text: 'Resumen de Materiales:', fontSize: 16, margin: [0, 15, 0, 0], },
                buildSuppliesResumen(content.products),
            ],
            unbreakable: true 
            }: {},
        ],

        footer: {
            columns: [{
                    text: "impreso por: " + printed_by + ' - ' + today,
                    alignment: "left",
                    style: "footer",
                    margin: [15, 0, 0, 0],
                },
                {
                    text: "generado por RunFlowv v5",
                    alignment: "right",
                    style: "footer",
                    margin: [0, 0, 15, 0],
                }
            ]
        },
        defaultStyle: {
            font: "RobotoCondensed",
            fontSize: 10,
            color: "#5B5A59"
        },
        completed: {
            color: "#7A3D8A"
        },
        styles: {
            h1: {
                font: "Poppins",
                fontSize: 20,
                bold: true,
                alignment: "center",
                color: "#7A3D8A"
            },
            h2: {
                font: "Poppins",
                fontSize: 12,
                bold: true,
                alignment: "left",
                color: "#5B5A59"
            },
            p: {
                font: "Poppins",
                fontSize: 10,
                lineHeight: 1.2,
                color: "#5B5A59"
            },
            reportHeader: {
                font: "Poppins",
                fontSize: 10,
                lineHeight: 1.5,
                color: "#5B5A59"
            },
            footer: {
                font: "RobotoCondensed",
                fontSize: 8,
                color: "#33425B"
            },
            table: {
                font: "RobotoCondensed",
                fontSize: 8,
                color: "#5B5A59"
            }
        }
    };
    pdfMake.createPdf(dd).open();
}

function buildProductsTable(products) {
    const productsTable = []
    products.forEach(product => {
        productsTable.push(productTableDetail(products.indexOf(product), product))
    })
    return productsTable
}

function productTableDetail(index, product) {
    const header = {
        unbreakable: true,
        layout: {
            hLineWidth: function(i, node) {
                return (i === 0 || i === node.table.body.length) ? 0 : 0;
            },
            vLineWidth: function(i, node) {
                return (i === 0 || i === node.table.widths.length) ? 0 : 0;
            },
            hLineColor: function(i, node) {
                return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
            },
            vLineColor: function(i, node) {
                return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
            },
            // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function(i, node) { return (i === 0) ? 10 : 0; },
            paddingRight: function(i, node) { return (i === node.table.widths.length) ? 10 : 0; },
            paddingTop: function(i, node) { return (i == 0) ? 10 : 0; },
            paddingBottom: function(i, node) { return (i === node.table.body.length) ? 10 : 0; },
            // fillColor: function (rowIndex, node, columnIndex) { return null; }
        },
        margin: [0, 0, 0, 10],
        table: {
            widths: ['12%', '12%', '12%', '12%', '12%', '*', '12%', '12%'],
            body: [
                [
                    { text: index+1, fontSize: 12, bold: true, alignment: 'center' },
                    { text: `[${product.library_product.sku}] ${product.name}`, fontSize: 14, bold: true, colSpan: 7, alignment: 'left', margin: [0, 0, 0, 5], },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                ],
                [
                    { text: 'Cantidad: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: product.qty, bold: true, alignment: 'left' },
                    { text: 'Ancho: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: `${numeral(product.width).format('0,0[.]0')} cms`, bold: true, alignment: 'left' },
                    { text: 'Alto: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: `${numeral(product.height).format('0,0[.]0')} cms`, bold: true, alignment: 'left' },
                    { text: 'Mts2: ', alignment: 'right', margin: [0, 0, 3, 0] },
                    { text: numeral(product.mts2).format('0,0[.]000'), bold: true, alignment: 'left' },
                ],
                [buildProcesses(product.process_orders), {}, {}, {}, {}, {}, {}, {}, ],
                [product.supplies.length>0?{ text: 'Materiales:', colSpan: 8, margin: [0, 5, 0, 0]}:{}, {}, {}, {}, {}, {}, {}, {}, ],
                [product.supplies.length>0?buildSupplies(product):{}, {}, {}, {}, {}, {}, {}, {}, ],
                product.notes ? [{ text: 'Observaciones: ' }, { text: product.notes, colSpan: 7 }, {}, {}, {}, {}, {}, {}, ] : [{}, {}, {}, {}, {}, {}, {}, {}, ]

                // [{ text: 'Materiales:', colSpan: 8, fontSize: 10, }, {}, {}, {}, {}, {}, {}, {}, ],
                // [buildSuppliesTable(product.product_supplies), {}, {}, {}, {}, {}, {}, {}, ],
            ]
        }
    }
    return header
}

function buildProcesses(processes) {
    var string = ['Procesos: ']
   
    processes.map(process => {
        string.push({ text: `${process.name}`, bold: !process.completed })
        string.push(' / ')
    })
    return { text: string, colSpan: 8, margin: [0, 5, 0, 0], }
}

/* function buildSupplies(product) {
    const {supplies,qty} = product
    var string = ['Materiales: ']
    supplies.map(supply => {

        string.push({ text: supply.sku ? `[${supply.sku}] ` : '' + `${supply.name} (${Math.ceil(supply.qty*qty)}) / ` })
    })
    return { text: string, colSpan: 8, margin: [0, 5, 0, 0], }
} */

function buildSupplies(product){
    const {supplies,qty} = product

    //Condicional para construir la tabla de materiales
    if(wodocumentprintsuppliestable){
        const itemsTableBody=(data,qty)=>{
            var body=[]
            var tableData=[]
            const columns=['item','name','qty','qty_total']
            body.push([{text:'',alignment:'center'},{text:'Descripción',alignment:'center'},{text:'Cant.PP',alignment:'center'},{text:'Cant.Tot',alignment:'center'}])
            data.map(item=>{
                tableData.push({
                    item: { text: data.indexOf(item)+1, alignment: 'center' },
                    name: { text: item.name, alignment: 'left' },
                    qty:  { text: item.qty, alignment: 'right' },
                    qty_total:{ text: Math.ceil(item.qty*qty), alignment: 'right' },
                })
            })
            tableData.forEach(function(row) {
                var dataRow = [];
                columns.forEach(function(column) {
                    dataRow.push(row[column]);
                });
                body.push(dataRow);
            });
            return body
        }
        return {
            colSpan: 8, 
            margin: [0, 5, 0, 0],
            unbreakable: true,
            table:{
                widths:['8%','*','10%','10%'],
                headerRows:1,
                
                body:itemsTableBody(supplies,qty)
            }
        }
    }
    else{
        var string = []
    supplies.map(supply => {

        string.push({ text: supply.sku ? `[${supply.sku}] ` : '' + `${supply.name} (${Math.ceil(supply.qty*qty)}) / ` })
        })
        return { text: string, colSpan: 8, margin: [0, 5, 0, 0], }
    }
}

function buildAdditionalCostTable(costs) {
    return {
        colSpan: 8,
        margin: [0, 0, 0, 10],
        unbreakable: true,
        layout: {
            fillColor: function(rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0 && rowIndex >= 0) ? null : '#bbe1fa';
            }
        },
        table: {
            widths: ['12%', '*', '12%'],
            headerRows: 1,
            body: buildItemsTableBody(costs)
        }
    }
}

function buildItemsTableBody(data) {
    var body = [];
    var tableData = [];
    const numberFormat = 'de-DE'
    const currencyFormat = 'CLP'

    //columnas que va a tomar del objeto
    const columns = ['item', 'name', 'price']

    //Creando el Header de la Table
    body.push(['Item', 'Descripción', 'Valor']);

    //preparando la data para crear la tabla
    //Incluimos los productos
    data.map(item => {
        tableData.push({
            item: { text: data.indexOf(item), alignment: 'center' },
            name: { text: item.name, alignment: 'left' },
            price: { text: numeral(item.price).format('$0,0'), alignment: 'right' },
        })
    })

    tableData.forEach(function(row) {
        var dataRow = [];
        columns.forEach(function(column) {
            dataRow.push(row[column]);
        });
        body.push(dataRow);
    });
    return body;
}


function buildSuppliesResumen(products){
    //Recibimos todos los productos
    var supplies=[]
    products.forEach(product=>{
        product.supplies.map(supply=>{
            switch (supply.unit){
                case 1:
                    supply.total=supply.qty*product.mts2
                    break
                case 2:
                    supply.total=supply.qty*product.qty
                    break
                default:
                    supply.total=supply.qty
            }
        })
        supplies=[...supplies,...product.supplies]
    })

    //console.log('Supplies :',supplies)

    const unique = [...new Set(supplies.map(item =>item.name))];
    //console.log('Unique :',unique)

    const results=unique.map(name=>{
        const byName=supplies.filter(item=>item.name===name)
        return{
            name:byName[0]?byName[0].name:'',
            unit_name:byName[0]?byName[0].unit_name:'',
            has_dimension:byName[0]?byName[0].has_dimension:false,
            height:byName[0]?byName[0].height:0,
            width:byName[0]?byName[0].width:0,
            qty:byName.reduce((total,item)=>total+parseFloat(item.total),0),
        }
    })

    console.log('Results :',results)


    const itemsTableBody=(data)=>{
        var body=[]
        var tableData=[]
        const columns=['item','name','qty','unit_name']
        body.push([{text:'',alignment:'center'},{text:'Descripción',alignment:'center'},{text:'Cant.',alignment:'center'},{text:'Unid.',alignment:'center'}])
        data.map(item=>{
            const dimension = item.has_dimension?`Alt. ${item.height}x An. ${item.width}`:''
            tableData.push({
                item: { text: data.indexOf(item)+1, alignment: 'center' },
                name: { text: item.name + dimension, alignment: 'left' },
                qty:  { text: item.qty, alignment: 'right' },
                unit_name:{ text: item.unit_name, alignment: 'left' },
            })
        })
        tableData.forEach(function(row) {
            var dataRow = [];
            columns.forEach(function(column) {
                dataRow.push(row[column]);
            });
            body.push(dataRow);
        });
        return body
    }
    return {
        colSpan: 8, 
        margin: [0, 5, 0, 0],
        table:{
            widths:['8%','*','10%','10%'],
            headerRows:1,
            
            body:itemsTableBody(results)
        }
    }

}