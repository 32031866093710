<template>
<v-container >
  <v-row  justify="space-between">
    <div class="text text-h6">Materiales</div>
    <div v-if="!readonly">
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small @click="addingSupply=true" class="primary--text" v-bind="attrs" v-on="on" :disabled='disabled'>
            <v-icon class="mr-2" small>fas fa-calculator</v-icon>
            Materiales
          </v-btn>
        </template>
        <span>Agregar Materiales</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small class="ml-4 primary--text" @click="addingCustom=true" v-bind="attrs" v-on="on" :disabled='disabled'>
          <v-icon class="mr-2" small>fas fa-brush</v-icon>
          <v-icon class="mr-2" small>fas fa-calculator</v-icon>
        </v-btn>
        </template>
        <span>Agregar Material Personalizado</span>
      </v-tooltip>
    </div>
  </v-row>
  <v-simple-table v-if="items.length>0">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Item
          </th>
          <th class="text-left">
            Descripción
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo
          </th>
          <th class="text-center">
            Cant. P<sub>p</sub>
          </th>
          
          <th class="text-left">
            Unidades
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo Total
          </th>
          <th v-if="!readonly" class="text-center">
            
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="items.indexOf(item)"
        >
          <td>{{items.indexOf(item)+1}}</td>
          <td>
            <div class="d-block">
              {{item.name}}
              <v-icon
                v-if="item.has_dimension" 
                x-small class="info--text ml-4">
                fas fa-ruler-combined
              </v-icon>
            </div>
            <div v-if="item.has_dimension" class="d-block info--text text-caption">
              An:{{item.width}} x Al: {{item.height}}
            </div>
            <div v-if="item.has_dimension">
              <v-chip class="mb-2" color="info" small>
                Cant. Tot.: <strong class="ml-2">{{Math.ceil(item.qty*qty) }}</strong> 
              </v-chip>
            </div>
          </td>
          <td class="text-right" v-if="$can('readCost', 'SalesNote')">{{item.price | currency('$',0)}}</td>
          <td class="">
            <v-text-field
              class="mx-auto"
              style="max-width:90px;"
              dense
              type="number"
              hide-details
              solo
              v-model=item.qty
              v-if="!readonly"
              :disabled='disabled'
            ></v-text-field>
            <div v-else>{{item.qty >= 1 ? item.qty:item.qty.toFixed(3)}}</div>
          </td>
          <td>
            <v-chip label outlined class="ml-1" style="width:70px;">
              {{item.unit_name}}
            </v-chip>
          </td>
          <td v-if="item.unit==1&&$can('readCost', 'SalesNote')" class="text-right">
            {{item.min_price>(item.price*item.qty*mts2) ? item.min_price :(item.price*mts2*item.qty) |currency('$',0)}}
            <span v-if="item.min_price>(item.price*item.qty*mts2)">
              <v-chip x-small dark color="blue darken-1">min.</v-chip>
            </span>
            <v-progress-linear 
              :value=" item.min_price>(item.price*mts2)?(item.min_price/total*100):(item.price*mts2*item.qty/total*100) ">
            </v-progress-linear>
          </td>
          <td v-else-if="item.unit==2&&$can('readCost', 'SalesNote')" class="text-right">
            {{item.min_price>(item.price*item.qty*qty) ? item.min_price :(item.price*item.qty*qty) |currency('$',0)}}
            <span v-if="item.min_price>(item.price*item.qty*qty)">
              <v-chip x-small dark color="blue darken-1">min.</v-chip>
            </span>
            <v-progress-linear 
              :value="item.min_price>(item.price*item.qty*qty)?(item.min_price/total*100):(item.price*item.qty*qty/total*100)">
            </v-progress-linear>
          </td>
              <td v-else-if="$can('readCost', 'SalesNote')" class="text-right">
                {{item.price*item.qty|currency('$',0)}}
                <v-progress-linear :value="item.price*item.qty/total*100"></v-progress-linear>
              </td>
            <td v-if="!readonly" class="text-center">
              <v-icon @click="deleteItem(item)" x-small class="error--text" :disabled='disabled'>fas fa-trash</v-icon>
            </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-dialog v-model="addingProcess">
    <add-cost @add="add" :is_supply="false"/>
  </v-dialog>
  <v-dialog v-model="addingSupply" max-width="800px">
    <v-card class="pa-8 px-10">
      <v-card-title class="titulo text-h4 mb-2">
        <v-icon class="mr-2">fas fa-calculator</v-icon>
        Agregar Material</v-card-title>
      <add-cost @add="add" :is_supply="true"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addingCustom" max-width="600px">
    <cost-create-update
      :cost=newCost 
      @save="add"
      :custom='true'
    /> 
  </v-dialog>
</v-container>

</template>
<script>
import AddCost from '@/components/products/costs/AddCost'
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import {calcSheets} from '@/utils/productCost'
export default {
  name:'Supplies',
  components: { AddCost,CostCreateUpdate},
  data:()=>({
    addingProcess:false,
    addingSupply:false,
    addingCustom: false,
    newCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:true,
      qty:1,
      group:-1,
      has_dimension:false,
      width:0,
      height:0,
      has_article:false,
      price_update_option:0
    },
  }),
  props:['items','qty','total','readonly','product_width','product_height','disabled'],
  methods:{
    add(item){
      if(this.items.includes(item)){
        console.log(item.qty)
        const newItemQty=parseInt(item.qty) + 1
        this.$set(item,'qty',newItemQty)
      }
      else{
        this.$set(item,'qty',1)
        this.items.push(item)
        this.updateSupplySheetQty()
      }
      this.addingCustom=false,
      this.newCost={
            id:-1,
            unit:'',
            name:'',
            price:0,
            min_price:0,
            is_supply:true,
            qty:1,
            group:-1,
            has_dimension:false,
            width:0,
            height:0,
            has_article:false,
            price_update_option:0
          },
      this.addingProcess=false,
      this.addingSupply=false
    },
    deleteItem(item){
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
    updateSupplySheetQty(){
      this.items.filter(s=>s.has_dimension).forEach(s=>{
        s.qty=calcSheets({width:this.product_width, height:this.product_height, qty:this.qty},s)/this.qty
      })
    },
  },
  computed:{
    mts2(){
      return this.product_width*this.product_height*this.qty*0.0001
    },
  },
  watch:{
    product_width(){
      this.updateSupplySheetQty()
    },
    product_height(){
      this.updateSupplySheetQty()
    },
    qty(){
      this.updateSupplySheetQty()
    },
  }
  
}
</script>
<style scoped>
.product-quantity {
    width: 5rem;
  }
</style>