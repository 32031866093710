<template>
<div>
    <v-menu
      open-on-hover
     offset-y
     v-for="(menu,index) in filteredMenu" :key='index'
     
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          small
          depressed
          dark
          v-bind="attrs"
          v-on="on"
          class="ml-6"
          v-if="menu.icon"
        >
          <v-icon>{{menu.icon}}</v-icon>
        </v-btn>
        <v-btn
          color="white"
          text
          dark
          v-bind="attrs"
          v-on="on"
          class="mx-2"
          v-else
        >
          <div class="text font-weight-black">{{menu.title}}</div>
        </v-btn>
      </template>

      <v-list>
        
        <v-list-item v-for="(item,index) in menu.items" :key="index" :to="`/${item.link}`" active-class="light_blue-text" :disabled="!$can('read',item.gate)">
          <v-list-item-action :disabled="!$can('read',item.gate)">
            <v-icon class="dark_blue--text">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content :disabled="!$can('read',item.gate)">
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    </div>
</template>
<script>
import menu from '@/utils/menu'
export default {
  data() {
    return {
      /* menus:[
        {
          title: 'Comercial',
          items:[
            { icon: 'fas fa-shopping-cart', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
            { icon: 'fas fa-city', text: 'Clientes', link:'customers',gate:'Customer'},
            { icon: 'fas fa-book', text: 'Productos', link:'products',gate:'Product'},
            { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
          ]
        }, 
        {
          title: 'Producción',
          items:[
            { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
            { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner',gate:'Planner'},
            { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
            { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
          ]
        }, 
        {
          title: 'Inventarios',
          items:[
            { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stocks'},
            { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'Stocks'},
            { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'Stocks'},
            { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'Stocks'},
            { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'Stocks'},
          ]
        }, 
        {
          title: 'Finanzas',
          items:[
            { icon: 'fas fa-file-invoice-dollar', text: 'Facturación', link:'sales/invoices',gate:'SalesNote'}
          ]
        },
        {
          title: 'Gestión',
          items:[
            { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
            { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'PostCost'},
            { icon: 'fas fa-chart-area', text: 'Facturación por Producto', link:'reports/product-invoice',gate:'PostCost'},
            { icon: 'fas fa-chart-line', text: 'Facturación de OT', link:'reports/work-order-invoice',gate:'PostCost'},
            { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'PostCost'},
            { icon: 'fas fa-truck', text: 'Material Comprometido', link:'reports/supply-orders',gate:'PostCost'},
          ]
        },
        {
          title: 'Configuración',
          icon:'fas fa-cog',
          items:[
            { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
          { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
          { icon: 'tune', text: 'Grupos', link:'products/costs/groups-units',gate:'Cost' },
          { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
          { icon: 'people', text: 'Usuarios', link:'users',gate:'User',gate:'User' },
          { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter',gate:'Parameter' },
          ]
        } 
      ], */
      menus:menu,
      items: [
        { icon: 'fas fa-home', text: 'Inicio', link:'',gate:'SalesNote'},
        { heading: 'Comercial' },
        { icon: 'fas fa-shopping-cart', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
        { icon: 'fas fa-city', text: 'Clientes', link:'customers',gate:'Customer'},
        { icon: 'fas fa-book', text: 'Productos', link:'products',gate:'Product'},
        { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
        { divider: true },
        { heading: 'Producción' },
        { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
        { icon: 'fas fa-calendar-alt', text: 'Planificador', link:'production/main-planner',gate:'Planner'},
        { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
        { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
        { divider: true },
        { heading: 'Inventarios' },
        { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stocks'},
        { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'Stocks'},
        { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'Stocks'},
        { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'Stocks'},
        { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'Stocks'},
        { divider: true },
        { heading: 'Informes de Gestión' },
        { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
        { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'PostCost'},
        { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'PostCost'},
        { icon: 'fas fa-truck', text: 'Material Comprometido', link:'reports/supply-orders',gate:'PostCost'},
        { divider: true },
        { heading: 'Configuración' },
        { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
        { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
        { icon: 'tune', text: 'Grupos y Unidades', link:'products/costs/groups-units',gate:'Cost' },
        { icon: 'fas fa-atom', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
        { divider: true },
        { icon: 'people', text: 'Usuarios', link:'users',gate:'User',gate:'User' },
        { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter',gate:'Parameter' },
      ],
    }
  },
  computed:{
    filteredMenu(){
      return this.menus.map(menu=>{
        menu.items=menu.items.filter(item=>this.$can('read',item.gate))
        return menu
      })
    }
  }
}
</script>