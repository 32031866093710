<template>
<div>
  <v-btn :disabled="disabled" text icon color="primary"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import {fetchConsumtionBitacoraByOT} from '@/api/production'

export default {
  name:"ExportConsumeOTBitacora",
  props:['options','disabled','filter'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      items:[],
      options:{},
      labels:{ 
        created_at:{title:'Fecha'},
        transaction:{title:'Transacción'},
        workorder_id:{title:'OT'},
        customer_name:{title:'Cliente'},
        article_sku: { title: 'Art. SKU' },
        article_name:{title:'Articulo'},
        unit:{title:'Unid. Compra'},
        articlePrice:{title:'Precio Prom.'},
        unitConsume:{title:'Unid. Consumo'},
        convertedPrice:{title:'Precio Conv.'},
        qty:{title:'Cantidad'},
        total:{title:'Total'},
        created_by_full_name:{title:'Usuario'},
       },
       csvTitle:`export_consume_bitacora_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.family) this.filter.family=''
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&article__family=${this.filter.family}`
      await fetchConsumtionBitacoraByOT(query)
      .then((data)=>{
        this.items = data.results.map(i=>{
          i.created_at=moment(i.created_at).format('DD-MM-YYYY')
          i.workorder_id=i.workorder.id
          i.customer_name=i.workorder.customer_name
          i.article_sku=i.article.sku
          i.article_name=i.article.name
          return i
        })
        this.dataReady=true
        })
      .catch((err)=>notifications.showError(err))
      .finally(()=>this.loading=false)
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>