<template>
<v-container>
   <v-row justify="space-between" class="">
    <div class="text text-h6">Procesos</div>
    <div v-if="!readonly">
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small @click="addingProcess=true" class="primary--text" v-bind="attrs" v-on="on" :disabled='disabled'>
            <v-icon class="mr-2" small>fas fa-cogs</v-icon>
            Procesos
          </v-btn>
        </template>
        <span>Agregar Procesos</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on,attrs }">
          <v-btn small class="ml-4 primary--text" @click="addingCustom=true" v-bind="attrs" v-on="on" :disabled='disabled'>
            <v-icon class="mr-2" small>fas fa-brush</v-icon>
            <v-icon class="mr-2" small>fas fa-cogs</v-icon>
          </v-btn>
        </template>
        <span>Agregar Proceso Personalizado</span>
      </v-tooltip>
    </div>
  </v-row>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Item
          </th>
          <th class="text-left">
            Descripción
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo
          </th>
          <th class="text-left">
            Unidades
          </th>
          <th class="text-right" v-if="$can('readCost', 'SalesNote')">
            Costo Total
          </th>
          <th v-if="!readonly" class="text-center">
            
          </th>
        </tr>
      </thead>

      <draggable 
        :list="items"  
        draggable=".recipe-row-move" 
        tag="tbody"
        :disabled="readonly"
      >
        <tr
          v-for="item in items"
          :key="items.indexOf(item)"
          :class="readonly?'':'recipe-row-move'"
        >
          <td>{{items.indexOf(item)+1}}</td>
          <td>{{item.name}}</td>
          <td class="text-right" v-if="$can('readCost', 'SalesNote')">{{item.price | currency('$',0)}}</td>
          <td>
            <v-chip label outlined  class="ml-1">
              {{item.unit_name}}
            </v-chip>
          </td>
          <td v-if="item.unit==1&&$can('readCost', 'SalesNote')" class="text-right">
            {{item.min_price>(item.price*mts2) ? item.min_price :(item.price*mts2) |currency('$',0)}}
            <span v-if="item.min_price>(item.price*mts2)">
              <v-chip x-small dark color="blue darken-1">min.</v-chip>
            </span>
            <v-progress-linear 
              :value=" item.min_price>(item.price*mts2)?(item.min_price/total*100):(item.price*mts2/total*100) ">
            </v-progress-linear>
          </td>
          <td v-else-if="item.unit==2&&$can('readCost', 'SalesNote')" class="text-right">
            {{item.min_price>(item.price*qty) ? item.min_price :(item.price*qty) |currency('$',0)}}
            <span v-if="item.min_price>(item.price*qty)">
              <v-chip x-small dark color="blue darken-1">min.</v-chip>
            </span>
            <v-progress-linear 
              :value="item.min_price>(item.price*qty)?(item.min_price/total*100):(item.price*qty/total*100)">
            </v-progress-linear>
          </td>
          <td v-else-if="$can('readCost', 'SalesNote')" class="text-right">
            {{item.price|currency('$',0)}}
            <v-progress-linear :value="item.price/total*100"></v-progress-linear>
          </td>
        <td v-if="!readonly" class="text-center">
          <v-icon @click="deleteItem(item)" x-small class="error--text" :disabled='disabled'>fas fa-trash</v-icon>
        </td>
        </tr>
      
      </draggable>
    </template>
  </v-simple-table>
  <v-dialog v-model="addingProcess" max-width="800px">
    <v-card class="pa-8 px-10">
      <v-card-title class="titulo text-h4 mb-2">
        <v-icon class="mr-2">fas fa-cogs</v-icon>
        Agregar Proceso</v-card-title>
      <add-cost @add="add" :is_supply="false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addingCustom" max-width="600px">
    <cost-create-update
      :cost=newCost 
      @save="add"
      :custom='true'
    /> 
  </v-dialog>
</v-container>

</template>
<script>
import AddCost from '@/components/products/costs/AddCost'
import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'
import draggable from 'vuedraggable'

export default {
  name:'Recipe',
  components: { AddCost,CostCreateUpdate,draggable},
  data:()=>({
    addingProcess:false,
    addingSupply:false,
    addingCustom: false,
    newCost:{
      id:-1,
      unit:'',
      name:'',
      price:0,
      min_price:0,
      group:-1,
      is_supply:false
    },
  }),
  props:['items','mts2','qty','total','readonly','disabled'],
  methods:{
    add(item){
      this.items.push(item)
      this.addingCustom=false,
      this.newCost={
            id:-1,
            unit:'',
            name:'',
            price:0,
            min_price:0,
            is_supply:'',
            group:-1,
          },
      this.addingProcess=false,
      this.addingSupply=false
    },
    deleteItem(item){
      const index = this.items.indexOf(item);
      this.items.splice(index, 1);
    },
  },
  computed:{
    
  }
}
</script>
