import Vue from 'vue'

function showSuccess(text) {
    Vue.notify({
        group: 'principal',
        title: `Enhorabuena!!!`,
        text,
        type: 'success',
        data: {
            randomNumber: Math.random()
        },
        duration: 3000,
        closeOnClick: true,
        classes: "vue-notification"
    })
}

function showError(text) {
    Vue.notify({
        group: 'principal',
        title: `Oppsss!!!`,
        text,
        type: 'error',
        data: {
            randomNumber: Math.random()
        },
        duration: 5000,
        closeOnClick: true,
        classes: "vue-notification"
    })
}

function showInfo(text) {
    Vue.notify({
        group: 'principal',
        title: `Información`,
        text,
        type: 'info',
        data: {
            randomNumber: Math.random()
        },
        duration: 3000,
        closeOnClick: true,
        classes: "vue-notification"
    })
}


function clean(group) {
    Vue.notify({ group, clean: true })
}

export default { showSuccess, showError, showInfo, clean }