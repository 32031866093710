<template>
<v-container>
  <v-row class="mb-2" justify="space-between">
    <v-col>
      <h1 class="text text-h4 titulo">Cotizaciones</h1>
    </v-col>
    <v-col cols="12" md="6" class="text-end">
      <div class="d-flex d-inline-flex">
        <v-btn
          color="secondary"
          dark
          class="mr-2"
          @click="$router.push({name:'CompromiseCalendar'})"
          text
          v-if="$can('read', 'Calendar')"
          >
            <v-icon class="mr-2">fas fa-calendar-minus</v-icon>Compromisos
          </v-btn>
        <export-sales-notes :filter="filter" :options="options" class="mr-2"/>
        <v-btn
          color="success"
          dark
          class="mb-2"
          @click="create"
          >
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
          </v-btn>
      </div>
    </v-col>
  </v-row>
  <v-row justify="center">
    <v-col>
    <v-card class="pa-6">
      <v-card-text>
        <v-row justify="space-between"> 
          <v-text-field dense :disabled="loading" v-model='filter.created_after' class="mr-4" outlined clearable type="date" label="Desde:"></v-text-field>
          <v-text-field dense :disabled="loading" v-model='filter.created_before' clearable outlined type="date" label="Hasta:" ></v-text-field>
        </v-row>
        <v-row justify="space-between">
          <v-col  class="px-0" cols="12" md="4">
            <customer-complete2 v-model="filter.customer"/>
          </v-col>
          <v-col class="px-2" cols="12" md="4">
            <Can I="readAll" a="SalesNote">
            <v-select
              v-model="filter.seller"
              :items="sellers"
              item-text="email"
              item-value="id"
              label="Vendedor"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
            />
            </Can>
          </v-col>
          <v-col  class="px-0" cols="12" md="4">
            <v-select
              v-model="filter.status"
              :items="status"
              item-text="text"
              item-value="value"
              label="Estatus"
              placeholder="Todos"
              prepend-inner-icon="filter_alt"
              outlined
              clearable
              dense
            />
          </v-col>
        </v-row>
        
          <v-data-table
            class="tabla"
            :headers="headers"
            :items="salesNotes"
            :search="search"
            :options.sync="options"
            :server-items-length="totalSalesNotes"
            sort-by="created_at"
            :sort-desc="false"
            :item-class="itemRowStyle"
            :loading="loading"
            @click:row="showSalesNote"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'fas fa-angle-double-left',
              lastIcon: 'fas fa-angle-double-right',
              prevIcon: 'fas fa-angle-left',
              nextIcon: 'fas fa-angle-right',
              itemsPerPageOptions: [10, 20, 30, 40, 50,100],
            }"
          >
            <template v-slot:top>
              <v-row justify="space-around">
                  <number-card title="Cotizado">
                    {{quoted | currency('$', 0)}}
                  </number-card>
                  <number-card title="Confirmado">
                    {{confirmed | currency('$', 0)}} [{{confirmed/quoted| percent}}]
                  </number-card>
              </v-row>
              <v-row justify="end">
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="search"
                      label="Buscar"
                      append-icon="fas fa-search"
                      @keyup="searchTimeOut"
                    ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{item.created_at | moment("DD/MM/YYYY")}}
            </template>
            
            <template v-slot:item.customer__name="{ item }">
              {{item.sales_note_customer.name}}
            </template>
            <template v-slot:item.customer__seller__first_name="{ item }">
              {{`${item.sales_note_customer.seller.first_name } ${item.sales_note_customer.seller.last_name}`}}
            </template>
            <template v-slot:item.total="{ item }">
              <div class="d-flex justify-end">
                <v-icon small class="mr-2 red--text" v-if="item.flag">fas fa-exclamation-triangle</v-icon>
                <v-icon small class="mr-2 yellow--text" v-if="item.yellow_flag">fas fa-exclamation-triangle</v-icon>
                {{item.totals.total | currency('$', 0)}}
              </div>
            </template>
            <template v-slot:item.workOrder="{ item }">
              <show-work-order v-if="item.work_order" :work_order_id="item.work_order" class="work-order-link">{{item.work_order}}</show-work-order>
              <span v-else><v-icon center small>fas fa-times</v-icon></span>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-btn text small class="mr-2" @click="view(item)" ><v-icon>fas fa-eye</v-icon></v-btn>
              <v-btn text :disabled="Boolean(item.work_order)" @click.stop="edit(item)" small fab class="mr-2" ><v-icon>create</v-icon></v-btn>
              <v-btn text @click.stop="generateCostReport(item)" small fab class="mr-2" ><v-icon>fas fa-calculator</v-icon></v-btn>
              <v-btn text @click.stop="duplicate(item)" small fab class="mr-2" ><v-icon>content_copy</v-icon></v-btn>
              <v-btn text @click.stop="generateQuotation(item)" small fab class="mr-2" ><v-icon>print</v-icon></v-btn>
              <v-btn text :disabled="Boolean(item.work_order)" small fab class="error--text"><v-icon>delete</v-icon></v-btn> -->
              <v-menu
                    offset-y
                    bottom
                    left
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon 
                        v-bind="attrs"
                        v-on="on"
                        text
                        class="ml-2">more_vert</v-icon> 
                    </template>
                    <v-list dense>
                      <v-list-item v-if="!item.work_order">
                        <v-btn text @click.stop='approveSalesNote(item)' color="dark_blue--text" small><v-icon class="mr-2">fas fa-handshake</v-icon>Confirmar Negocio</v-btn>
                      </v-list-item>
                      <v-list-item >
                        <v-btn text small :disabled="item.work_order?!$can('update_with_OT','SalesNote'):!$can('update','SalesNote')" @click.stop="edit(item)" class="dark_blue--text"><v-icon class="mr-2">create</v-icon>Modificar</v-btn>
                      </v-list-item>
                      <v-list-item v-if="$can('readCost', 'SalesNote')">
                        <v-btn text small @click.stop="generateCostReport(item)" class="dark_blue--text"><v-icon class="mr-2">fas fa-calculator</v-icon>Informe de Costos</v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn text small @click.stop="generateQuotation(item)" class="dark_blue--text"><v-icon class="mr-2">print</v-icon>Presupuesto Cliente</v-btn>
                      </v-list-item>
                      <v-list-item v-if="$can('duplicate', 'SalesNote')">
                        <v-btn text small @click.stop="duplicate(item)" class="dark_blue--text" ><v-icon class="mr-2">fas fa-clone</v-icon>Duplicar</v-btn>
                      </v-list-item>
                      <v-list-item>
                        <v-btn text small :disabled="Boolean(item.work_order)" v-if="$can('delete','SalesNote')" @click.stop="()=>{
                          salesNotetoDelete=item
                          showDeleteSalesNote=true
                          }" class="error--text"><v-icon class="mr-2">delete</v-icon>Eliminar</v-btn>
                      </v-list-item> 
                    </v-list>
                  </v-menu>
            </template>
          </v-data-table>
       
      </v-card-text>
    </v-card>
    </v-col>
  </v-row>
  <confirm
    v-if="salesNotetoDelete"
    v-model="showDeleteSalesNote" 
    :message="`¿Confirma eliminar la cotización # ${salesNotetoDelete.id}?`"
    @ok="removeSalesNote(salesNotetoDelete)"
    @cancel="showDeleteSalesNote=false"/>
  <v-dialog v-model='displaySalesNote' max-width="1300" >
    <v-sheet class="pa-12"  >
      <SalesDetail :salesNote='salesNoteOnDisplay' @close="displaySalesNote=false"/> 
    </v-sheet>
  </v-dialog>
  <v-dialog v-model='approvingSalesNote' max-width="1200">
    <approve-sales-note :salesNote='salesToApprove' @close="fetch"/>
  </v-dialog>
</v-container>
</template>
<script>
import {fetchSalesNote,deleteSalesNote} from '@/api/salesNotes'
import {fetchSellers} from '@/api/auth'
import CustomerComplete2 from '@/components/customers/CustomerComplete2'
import ApproveSalesNote from '@/components/sales/ApproveSalesNote'
import notifications from '@/utils/notifications'
import {pdfQuotation} from '@/doc_generators/quotation'
import {pdfCostReport} from '@/doc_generators/costReport'
import SalesCard from '../../components/sales/SalesCard.vue'
import SalesDetail from '@/components/sales/SalesDetail'
import NumberLabel from '@/components/utils/NumberLabel'
import ExportSalesNotes from '@/components/sales/ExportSalesNotes'
import NumberCard from '@/components/utils/NumberCard'
import moment from 'moment'

import store from '@/store'

export default {
  name:'SalesNotes',
  components:{CustomerComplete2,ApproveSalesNote,SalesDetail,NumberLabel,ExportSalesNotes,NumberCard,},
  data:()=>({
    salesNotes:[],
    search:'',
    showDialog:false,
    loading:false,
    totalSalesNotes:0,
    options:{},
    filter:{
      created_after:moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('yyyy-MM-DD'),
      created_before:'',
      customer:'',
      status:'',
      seller:'',
    },
    status: [
      {text:'Cotizado',value:false,color:'info'},
      {text:'Confirmado',value:true,color:'success'}
    ],
    sellers:[],
    displaySalesNote:false,
    salesNoteOnDisplay:{},
    approvingSalesNote:false,
    salesToApprove:{},
    quoted:'',
    confirmed:'',
    showDeleteSalesNote:false,
    salesNotetoDelete:{},
  }),
  computed:{
    headers(){
      return [
          {
            text: 'Número',
            align: 'start',
            value: 'id',
            class: 'table-header',
          },
          {
            text: 'Creada',
            align: 'start',
            value: 'created_at',
            class: 'table-header',
          },
          {
            text: 'Proyecto',
            align: 'start',
            value: 'name',
            class: 'table-header',
          },
          {
            text: 'Cliente',
            align: 'start',
            value: 'customer__name',
            class: 'table-header',
          },
          {
            text: 'Vendedor',
            align: 'start',
            value: 'customer__seller__first_name',
            class: 'table-header',
            sortable:false
          },
          {
            text: 'Total',
            align: 'end',
            value: 'total',
            class: 'table-header',
            sortable:false
          },
          {
            text: 'OT',
            align: 'center',
            value: 'workOrder',
            class: 'table-header',
            align:'center',
            sortable:false
          },
          
          { text: '', align:'center',value:'actions',sortable: false,class: 'table-header',}
        ]
    }
  },
  methods:{
    create(){
      this.$router.push({name:'SalesNoteCreateUpdate'})
    },
    edit(item){
      this.$router.push({name:'SalesNoteCreateUpdate',params:{id:item.id}})
    },
    duplicate(item){
      this.$router.push({name:'SalesNoteCreateUpdate',params:{id:item.id, duplicate:true}})
    },
    view(item){
      this.$router.push({name:'SalesNoteDetail',params:{sales_note:item}})
    },
    generateQuotation(item){
      pdfQuotation(item)
    },
    generateCostReport(item){
      pdfCostReport(item)
    },
    async fetch(){
      this.approvingSalesNote=false
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      //Bloqueo solo a NV donde el usuario es el vendedor
      console.log(this.$can('readAll', 'SalesNote'))
      if (!this.$can('readAll', 'SalesNote')){
        const user = this.$store.getters.getUser
        this.filter.seller=user.id
      }
      await fetchSalesNote(
        sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`,
        this.search,this.filter.created_after,
        this.filter.created_before,
        this.filter.customer.id,
        this.filter.seller,
        this.filter.status,
        page,
        itemsPerPage)
      .then((data)=>{
        this.totalSalesNotes=data.count
        this.salesNotes=this.flag(data.results)
        this.quoted=data.quoted
        this.confirmed=data.confirmed
      })
      this.loading=false
    },
    async fetchAllData(){
      this.loading=true
      //Bloqueo solo a NV donde el usuario es el vendedor
      if (!this.$can('readAll', 'SalesNote')){
        const user = this.$store.getters.getUser
        this.filter.seller=user.id
      }
      await fetchSalesNote(
        sortDesc[0]?`${sortBy[0]}`:`-${sortBy[0]}`,
        this.search,this.filter.created_after,
        this.filter.created_before,
        this.filter.customer.id,
        this.filter.seller,
        this.filter.status,
        1,
        -1)
      .then((data)=>{
        this.totalSalesNotes=data.count
        this.salesNotes=data.results
        
      })
      this.loading=false
    },
    flag(results){
      //Función para mostrar alertas cuando existan rojos en los precios
      return results.map(item=>{
        let flag=false
        let yellow_flag=false

        //Revisión de Totales
        if(item.totals.margin<0) flag=true

        //Revisión de costos de productos
        
          const {products} = item.details
          for(let i=0;i<products.length;i++){
            if (products[i].price_bellow_cost) flag=true
            if(products[i].price_bellow_original) yellow_flag=true
          }
        

        //Revisión de costos adicionales
        const {costs}=item.details
        if(!flag){
          for(let i=0;i<costs.length;i++){
            if(costs[i].price>costs[i].sell_price){
              flag=true
              break
            }
          }
        }
        item={...item,flag,yellow_flag}
        return item
      })
    },
    showSalesNote(item){
      this.salesNoteOnDisplay=item
      this.displaySalesNote=true
    },
    approveSalesNote(item){
      this.salesToApprove=item
      this.approvingSalesNote=true
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
    itemRowStyle(item){
      return 'row-style-normal'
    },
    async removeSalesNote(item){
      this.loading=true
      await deleteSalesNote(item.id)
      .then((res)=>{
        notifications.showSuccess('Nota de Venta '+item.id+' Eliminada')
        this.showDeleteSalesNote=false
        this.fetch()
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.showDeleteSalesNote=false
      })
      this.loading=false
    }
  },
  watch:{
    filter:{
      deep: true,
      handler: 'fetch'
    },
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.loading=true
    //Cargando lista Vendedores
    this.sellers=this.$store.getters.getSellers
    await this.fetch()
  }
}
</script>

<style scoped>
  .row-style-normal{
    cursor: pointer;
  }

</style>



  

