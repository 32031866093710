<template>
<div>
  <v-row v-show="!loadingNewOrders&&!loadingPendingOrders" >
    
  </v-row>
  <v-row>
    <Can I="read" a="WorkOrder">
    <v-col cols="12" md="6" >
      <v-card class="pa-4" :loading="loadingNewOrders">
        <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Ultimas Ordenes de Trabajo
            </div>
          </v-card-title>
        <v-simple-table>
          <thead>
            <tr>
              <th class="table-header">Orden</th>
              <th class="table-header">Cliente</th>
              <th class="table-header text-end">Mts<sup>2</sup></th>
              <th class="table-header">Entrega</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in lastWorkOrders" :key="item.id" @click="showWorkOrder(item)" class="row-style-normal">
              <td>{{item.id}}</td>
              <td>{{item.customer_name}}</td>
              <td class="text-end">{{item.total_mts2}}</td>
              <td>{{item.delivery_date | moment('DD/MM/YYYY')}}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
      <v-dialog  v-model='display' max-width="1200">
        <v-card class="pa-4" >
          <work-order-card :work_order='workOrderOnDisplay' @close="display=false" :loading="workOrderOnDisplayLoading"/> 
        </v-card>
      </v-dialog>
    </v-col>
    </Can>
    <Can I="read" a="WorkOrder">
    <v-col cols="12" md="6" >
      <v-card class="pa-4">
        <v-card-title >
            <div class="subheading font-weight-light grey--text text-center">
                Compromisos proximos 7 días [Mts<sup>2</sup>]
            </div>
          </v-card-title>
      <next-orders-graph/>
      </v-card>
    </v-col>
    </Can>
  </v-row>
</div>
</template>

<script>
import {fetchWorkOrders,getWorkOrder} from '@/api/production'
import WorkOrderCard from '@/components/production/WorkOrderCard'
import NextOrdersGraph from '@/components/production/NextOrdersGraph'
import notifications from '@/utils/notifications'
import moment from 'moment'
import SupplyOrdersPendingReportVue from '../../views/Reports/SupplyOrdersPendingReport.vue'
export default {
  name:'ProductionDashboard',
  components:{WorkOrderCard,NextOrdersGraph},
  data() {
    return {
      lastWorkOrders: [],
      display:false,
      workOrderOnDisplay:{},
      workOrderOnDisplayLoading:false,
      delayed:'',
      today:'',
      pending:[],
      loadingNewOrders:false,
      loadingPendingOrders:false,
    }
  },
  methods:{
    async fetchLastWorkOrders(){
      this.loadingNewOrders=true
      await fetchWorkOrders(1, 5, '', '', '', '', '', '', '', '-created_at')
      .then(res=>{
        this.lastWorkOrders=res.results
        this.loadingNewOrders=false
      })
    },
    //page = '', page_size = '', search = '', created_after = '', created_before = '', delivery_after = '', delivery_before = '', status = '', seller = '', sort = ''
    async fetchNextWorkOrders(){
      this.loadingPendingOrders=true
      await fetchWorkOrders(1,-1,'','','','',moment(new Date().toString()).add(7,'day').format('YYYY-MM-DD'),'false','','','-created_at')
      .then(res=>{
       this.groupByDeliveryDate(res.results)
       this.loadingPendingOrders=false
      })
    },
    async showWorkOrder(item){
      this.workOrderOnDisplayLoading=true
      this.display=true
      await getWorkOrder(item.id)
      .then((data)=>{
        console.log(data)
        this.workOrderOnDisplay=data
        this.workOrderOnDisplayLoading=false
      })
      .catch((e)=>{
        notifications.showError('Error '+e)
        this.display=false
        this.workOrderOnDisplayLoading=false
      })
    },
    groupByDeliveryDate(workOrders){
      workOrders.forEach(w=>{
        w.stringDeliveryDate=moment(w.delivery_date).format('YYYY-MM-DD')
      })
      
      //Agrupo por día
      const groupByDay=workOrders.reduce((acc,value)=>{
        if(!acc[value.stringDeliveryDate]){
          acc[value.stringDeliveryDate]=[]
        }
        acc[value.stringDeliveryDate].push(value);
        return acc;
        
      },{})
      const results=[]
      Object.keys(groupByDay).map(day=>{
        results.push(
          {
            day:day,
            workOrders:groupByDay[day],
            total_mts2:groupByDay[day].reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
            })
      })
      //Ordenes Atrasadas
      const delayed = results.filter(w=>moment(w.day).isBefore())
      delayed.total_mts2=delayed.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
      //Ordenes por entregar
      const today=results.filter(w=>w.day==moment().format('YYYY-MM-DD'))
      const pending=results.filter(w=>moment(w.day).isSameOrAfter())
    
      //Convierto los valores a días de la semana
      pending.forEach(w=>{
          w.stringDay=moment(w.day).format('dddd')
        })

      this.today=today.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
      this.pending=pending.reverse()
      this.delayed=delayed.reduce((acc, val)=>{ return acc + parseFloat(val.total_mts2); }, 0)
    }
  },
  mounted(){
    this.fetchLastWorkOrders()
    //this.fetchNextWorkOrders()
  }
}
</script>

<style>

</style>