var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.groups,"disabled":_vm.loading,"item-text":"name","item-value":"id","label":"Grupo","placeholder":"Todos los Grupos","prepend-inner-icon":"fas fa-filter","single-line":"","outlined":"","dense":"","clearable":""},on:{"change":function () {
            this$1.options.page=1;_vm.fetch();
          }},model:{value:(_vm.groupFilter),callback:function ($$v) {_vm.groupFilter=$$v},expression:"groupFilter"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.loading,"label":"Buscar","append-icon":"fas fa-search"},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.costs,"items-per-page":5,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"loading-text":"Cargando Datos","search":_vm.search,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.unit_name",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"outlined":"","label":""}},[_vm._v(_vm._s(item.unit_name))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"success--text",on:{"click":function($event){$event.stopPropagation();return _vm.add(item)}}},[_vm._v("fas fa-plus")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }