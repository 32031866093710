<template>
<div>
  <v-btn :disabled="disabled" text icon color="white"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="secuenceData"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
export default {
  name:"ExportMachinePlanner",
  props:['secuence','options','disabled'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      labels:{ 
        secuence:{title:'Sec.'},
        orden:{title:'OT'},
        customer_name:{title:'Cliente'},
        seller_name:{title:'Vendedor'},
        product:{title:'Producto'},
        name: { title: 'Proceso' },
        total:{title:'Total'},
        completed:{title:'Completado'},
        pending:{title:'Pendiente'},
        units:{title:'UN'},
        machine:{title:'Area'},
        delivery_date:{title:'Fecha Entrega'},
        install_date:{title:'Fecha Instalación'},
        uninstall_date:{title:'Fecha Desinstalación'},
       },
       csvTitle:`export_planificación_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  computed:{
    secuenceData(){
      return this.secuence.map(i=>{
        return {
          secuence:this.secuence.indexOf(i)+1,
          orden:i.product.work_order.id,
          customer_name:i.customer_name,
          product:i.product.name,
          name:i.name,
          total:i.machine.production_unit_m2?numeral(i.product.mts2).format('0[.]00'):numeral(i.product.qty).format('0[.]00'),
          completed:i.machine.production_unit_m2?numeral(i.completed_mts2_qty.mts2).format('0[.]00'):numeral(i.completed_mts2_qty.qty).format('0[.]00'),
          pending:i.machine.production_unit_m2?numeral(i.product.mts2-i.completed_mts2_qty.mts2).format('0[.]00'):numeral(i.product.qty-i.completed_mts2_qty.qty).format('0[.]00'),
          units:i.machine.production_unit_m2?'MTS2':'UNID',
          machine:i.machine.name,
          delivery_date:moment(i.delivery_date).format('DD-MM-YYYY'),
          install_date:i.install_date?moment(i.install_date).format('DD-MM-YYYY'):'',
          uninstall_date:i.uninstall_date?moment(i.uninstall_date).format('DD-MM-YYYY'):'',
          seller_name:i.seller_name
        }
      })
    }
  },
  methods:{
    async fetch(){
      this.dataReady=true
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>