var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',{attrs:{"align-items-center":""}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Facturas")])]),_c('v-col',{staticClass:"text-end",attrs:{"justify-end":""}},[_c('div',{staticClass:"d-flex justify-end"},[_c('export-invoices',{attrs:{"filter":_vm.filter,"options":_vm.options,"disabled":_vm.loading}}),_c('Can',{attrs:{"I":"create","a":"Invoice"}},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.$router.push({name:'CreateDTE',params:{id:-1,tipo:30}})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nueva ")],1)],1)],1)])],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","outlined":"","clearable":"","type":"date","label":"Emitida Desde:"},on:{"change":function (){
          _vm.options.page=1;
          _vm.fetch()
        }},model:{value:(_vm.filter.created_after),callback:function ($$v) {_vm.$set(_vm.filter, "created_after", $$v)},expression:"filter.created_after"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('v-text-field',{attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","clearable":"","outlined":"","type":"date","label":"Emitida Hasta:"},on:{"change":function (){
          _vm.options.page=1;
          _vm.fetch()
        }},model:{value:(_vm.filter.created_before),callback:function ($$v) {_vm.$set(_vm.filter, "created_before", $$v)},expression:"filter.created_before"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('Can',{attrs:{"I":"readAll","a":"SalesNote"}},[_c('v-select',{attrs:{"items":[
          {
            value:30,
            text:'Factura'
          },
          {
            value:60,
            text:'Nota de Crédito'
          }
        ],"item-text":"text","item-value":"value","label":"Tipo","placeholder":"Todos","prepend-inner-icon":"filter_alt","outlined":"","clearable":"","disabled":_vm.loading,"dense":""},on:{"change":function (){
          _vm.options.page=1;
          _vm.fetch()
        }},model:{value:(_vm.filter.tipo),callback:function ($$v) {_vm.$set(_vm.filter, "tipo", $$v)},expression:"filter.tipo"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('Can',{attrs:{"I":"readAll","a":"SalesNote"}},[_c('v-select',{attrs:{"items":_vm.sellers,"disabled":_vm.loading,"item-text":"email","item-value":"id","label":"Vendedor","placeholder":"Todos","prepend-inner-icon":"filter_alt","outlined":"","clearable":"","dense":""},on:{"change":function (){
          _vm.options.page=1;
          _vm.fetch()
        }},model:{value:(_vm.filter.seller),callback:function ($$v) {_vm.$set(_vm.filter, "seller", $$v)},expression:"filter.seller"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('Can',{attrs:{"I":"readAll","a":"SalesNote"}},[_c('v-select',{attrs:{"items":[{'text':'Emitido','value':true}],"item-text":"text","item-value":"value","label":"Estatus","placeholder":"Todos","prepend-inner-icon":"filter_alt","outlined":"","clearable":"","disabled":_vm.loading,"dense":""},on:{"change":function (){
          _vm.options.page=1;
          _vm.fetch()
        }},model:{value:(_vm.filter.not_null),callback:function ($$v) {_vm.$set(_vm.filter, "not_null", $$v)},expression:"filter.not_null"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.computedHeaders,"items":_vm.dtes,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalDtes,"item-class":function (item){return item.tipo==60?'row-style-nc':'row-style-normal'},"search":_vm.search,"sort-by":"date_emited","sort-desc":false,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right',
          itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.displayDetails},scopedSlots:_vm._u([{key:"item.document_id",fn:function(ref){
        var item = ref.item;
return [_c('small',{class:((_vm.estatuses.find(function (e){ return e.value==item.estatus; }).color) + "--text")},[_vm._v(_vm._s(_vm.estatuses.find(function (e){ return e.value==item.estatus; }).text))]),(item.tipo==60)?_c('v-chip',{staticClass:"ml-2",attrs:{"x-small":"","color":"error"}},[_vm._v(" NC ")]):_vm._e(),_c('div',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.document_id)+" ")])]}},{key:"item.date_emited",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date_emited,'DD/MM/YYYY'))+" ")])]}},{key:"item.rznSocRecep",fn:function(ref){
        var item = ref.item;
return [_c('small',[_vm._v(_vm._s(item.rutRecep))]),_c('div',{staticClass:"d-flex d-block"},[_vm._v(" "+_vm._s(item.rznSocRecep)+" ")])]}},{key:"item.get_total",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.get_total,'$', 0))+" ")]}},{key:"item.payed",fn:function(ref){
        var item = ref.item;
return [(item.tipo==60)?_c('div',{staticClass:"text-center"},[_vm._v("-")]):_c('div',{class:(item.get_total - item.get_payed)>0?'red--text':''},[_vm._v(_vm._s(_vm._f("currency")(item.get_payed,'$', 0)))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"text":""}},'v-icon',attrs,false),on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('Can',{attrs:{"I":"update","a":"Invoice"}},[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","small":"","disabled":item.tipo==60},on:{"click":function($event){$event.stopPropagation();return (function (){
                  _vm.itemToPayment=item
                  _vm.toPayment=true
                  })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-money-check-alt")]),_vm._v("Pagos ")],1)],1)],1),_c('Can',{attrs:{"I":"update","a":"Invoice"}},[(Boolean(item.estatus<2))?_c('v-list-item',[_c('v-btn',{attrs:{"text":"","small":"","disabled":item.tipo==60},on:{"click":function($event){$event.stopPropagation();return (function (){
                  _vm.invoice=item
                  _vm.showConfirmReceived=true
                  })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sync")]),_vm._v("Recepcionar ")],1)],1):_vm._e()],1),_c('Can',{attrs:{"I":"delete","a":"Invoice"}},[(Boolean(item.estatus<3))?_c('v-list-item',[_c('v-btn',{staticClass:"error--text",attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();return (function (){
                  _vm.invoice=item
                  _vm.showConfirmCancel=true
                  })($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-trash")]),_vm._v("Anular ")],1)],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1),(_vm.invoice)?_c('confirm',{attrs:{"message":("Confirma la anulación de la Factura #" + (_vm.invoice.document_id) + "?")},on:{"ok":function($event){return _vm.updateEstatus(3)},"cancel":function($event){_vm.showConfirmCancel=false}},model:{value:(_vm.showConfirmCancel),callback:function ($$v) {_vm.showConfirmCancel=$$v},expression:"showConfirmCancel"}}):_vm._e(),(_vm.invoice)?_c('confirm',{attrs:{"message":("Confirma el cambio de Estatus de la Factura #" + (_vm.invoice.document_id) + " emitida a " + (_vm.invoice.rznSocRecep) + " a Recepcionada?")},on:{"ok":function($event){return _vm.updateEstatus(2)},"cancel":function($event){_vm.showConfirmReceived=false}},model:{value:(_vm.showConfirmReceived),callback:function ($$v) {_vm.showConfirmReceived=$$v},expression:"showConfirmReceived"}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',{staticClass:"px-4"},[_c('invoice-detail',{attrs:{"invoice":_vm.detailToDisplay},on:{"close":function($event){_vm.showDetail=false}}})],1)],1),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.toPayment),callback:function ($$v) {_vm.toPayment=$$v},expression:"toPayment"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-card-title',{staticClass:"titulo text-h5"},[_vm._v("Historial de Pagos")]),_c('invoice-pay-detail',{attrs:{"invoice":_vm.itemToPayment},on:{"refresh":function (){
      _vm.toPayment=false
      _vm.fetch()
      }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }