var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"sort-by":"date","sort-desc":false,"items-per-page":5,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right',
            itemsPerPageOptions: [5,10, 20, 50],
            }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('export-product-sales-detail',{attrs:{"product":_vm.items,"filter":_vm.filter,"options":_vm.options,"disabled":_vm.loading}})],1),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading,"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
            var item = ref.item;
return [_c('show-work-order',{attrs:{"work_order_id":item.id}},[_vm._v(" "+_vm._s(item.work_order)+" ")])]}},{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"DD/MM/YYYY"))+" ")]}},{key:"item.mts2",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(item.mts2,'0,0'))+" ")]}},{key:"item.total_sales_price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_sales_price,'$', 0))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }