var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-row',{staticClass:"mb-4",attrs:{"justify":"space-between","align-content":"center"}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Post Costeo")]),_c('export-post-cost',{attrs:{"filter":_vm.filter,"options":_vm.options,"disabled":_vm.loading}})],1),_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","outlined":"","clearable":"","type":"date","label":"Creada Desde:"},on:{"change":function (){_vm.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_after),callback:function ($$v) {_vm.$set(_vm.filter, "created_after", $$v)},expression:"filter.created_after"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{attrs:{"hide-details":"","disabled":_vm.loading,"dense":"","clearable":"","outlined":"","type":"date","label":"Creada Hasta:"},on:{"change":function (){_vm.options.page=1;_vm.fetch()}},model:{value:(_vm.filter.created_before),callback:function ($$v) {_vm.$set(_vm.filter, "created_before", $$v)},expression:"filter.created_before"}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading,"hide-details":""},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"pa-4",attrs:{"headers":_vm.headers,"items":_vm.workOrders,"options":_vm.options,"server-items-length":_vm.totalWorkOrders,"search":_vm.search,"sort-by":"created_at","sort-desc":false,"single-expand":true,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch]},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('show-work-order',{attrs:{"work_order_id":item.id}},[_vm._v(_vm._s(item.id))])]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY")))])]}},{key:"item.sales_note__name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.sales_note__customer__name",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_name)+" ")]}},{key:"item.completed_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.completed_at,"DD/MM/YYYY"))+" ")]}},{key:"item.estimated_cost",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.real_price.sales_cost,'$', 0))+" ["+_vm._s(_vm._f("percent")(item.estimated_margin,1))+"] ")]}},{key:"item.real_price",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.real_price.total,'$', 0))+" ["+_vm._s(_vm._f("percent")(item.real_margin,1))+"] ")]}},{key:"item.total_sale",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.real_price.sales_total,'$', 0))+" ")]}},{key:"item.progress",fn:function(ref){
      var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"primary","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.progress),callback:function ($$v) {_vm.$set(item, "progress", $$v)},expression:"item.progress"}})]}},{key:"item.estimated",fn:function(ref){
      var item = ref.item;
return [(item.estimated!=0)?_c('div',[_vm._v(" "+_vm._s(1-item.estimated/item.total_sale)+" "+_vm._s(_vm._f("moment")(item.estimated,"DD/MM/YYYY"))+" ")]):_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fas fa-check-circle")])],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"dense":"","small":"","text":""},on:{"click":function($event){return _vm.$router.push({name:'DetailedPostCost',params:{id:item.id}})}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-eye")])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"1200"},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c('v-card',{staticClass:"pa-4"},[_c('work-order-card',{attrs:{"work_order":_vm.workOrderOnDisplay},on:{"close":function($event){_vm.display=false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }