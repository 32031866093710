var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-10"},[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Agregar Registro de Procesos")])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',{staticClass:"table-header"},[_vm._v(" OT ")]),_c('th',{staticClass:"table-header"},[_vm._v(" Producto ")]),(_vm.machine.production_unit_m2)?_c('th',{staticClass:"table-header"},[_vm._v(" Total Mts"),_c('sup',[_vm._v("2")])]):_vm._e(),(_vm.machine.production_unit_m2)?_c('th',{staticClass:"table-header"},[_vm._v(" Producido Mts"),_c('sup',[_vm._v("2")])]):_vm._e(),(!_vm.machine.production_unit_m2)?_c('th',{staticClass:"table-header"},[_vm._v(" Total Unidades ")]):_vm._e(),(!_vm.machine.production_unit_m2)?_c('th',{staticClass:"table-header"},[_vm._v(" Producido Unidades ")]):_vm._e(),_c('th',{staticClass:"d-flex align-center justify-center table-header"},[_vm._v(" Completado? ")])])]),_c('tbody',[_vm._l((_vm.processes),function(process){return _c('tr',{key:process.id},[_c('td',[_vm._v(" "+_vm._s(process.work_order)+" ")]),_c('td',[(process.product.library_product)?_c('div',{staticClass:"text text-caption"},[_vm._v(" ["+_vm._s(process.product.library_product.sku)+"] ")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(process.product.name)+" ")])]),(_vm.machine.production_unit_m2)?_c('td',[_c('strong',[_vm._v(_vm._s(process.completed_mts2_qty.mts2))]),_vm._v(" / "+_vm._s(process.product.mts2)+" "),_c('v-progress-linear',{attrs:{"color":"light-blue","height":"5","value":process.completed_mts2_qty.mts2/process.product.mts2*100,"striped":""}})],1):_vm._e(),(_vm.machine.production_unit_m2)?_c('td',[_c('ValidationProvider',{attrs:{"name":"Producción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"quantity",attrs:{"solo":"","dense":"","min":0,"type":"number","error-messages":errors,"append-icon":"square_foot"},on:{"input":function($event){return _vm.calculateQty(process)}},model:{value:(process.real_mts2),callback:function ($$v) {_vm.$set(process, "real_mts2", $$v)},expression:"process.real_mts2"}})]}}],null,true)})],1):_vm._e(),(!_vm.machine.production_unit_m2)?_c('td',[_c('strong',[_vm._v(_vm._s(process.completed_mts2_qty.qty))]),_vm._v(" / "+_vm._s(process.product.qty)+" "),_c('v-progress-linear',{attrs:{"color":"light-blue","height":"5","value":process.completed_mts2_qty.qty/process.product.qty*100,"striped":""}})],1):_vm._e(),(!_vm.machine.production_unit_m2)?_c('td',[_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"quantity",attrs:{"solo":"","dense":"","min":0,"type":"number","error-messages":errors,"append-icon":"view_comfy"},on:{"input":function($event){return _vm.calculateMts2(process)}},model:{value:(process.real_qty),callback:function ($$v) {_vm.$set(process, "real_qty", $$v)},expression:"process.real_qty"}})]}}],null,true)})],1):_vm._e(),_c('td',{staticClass:"d-flex align-center justify-center"},[_c('v-checkbox',{model:{value:(process.ask_completed),callback:function ($$v) {_vm.$set(process, "ask_completed", $$v)},expression:"process.ask_completed"}})],1)])}),_c('tr',[_c('td'),(_vm.machine.production_unit_m2)?_c('td',{staticStyle:{"text-align":"end"}},[_vm._v(" Total Mts"),_c('sup',[_vm._v("2")]),_vm._v(" Reales: ")]):_vm._e(),(_vm.machine.production_unit_m2)?_c('td',[_c('h4',[_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.mts2,'0,0[.]00')))])])]):_vm._e(),(!_vm.machine.production_unit_m2)?_c('td',{staticStyle:{"text-align":"end"}},[_vm._v(" Total Cantidad Real: ")]):_vm._e(),(!_vm.machine.production_unit_m2)?_c('td',[_c('strong',[_vm._v(_vm._s(_vm._f("number")(_vm.qty,'0,0[.]00')))])]):_vm._e()])],2)]),_c('ValidationProvider',{attrs:{"name":"Máquina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Equipo","placeholder":"Seleccione el Equipo","items":_vm.machine.equipments,"item-text":"name","item-value":"id","outlined":"","error-messages":errors},model:{value:(_vm.equipment),callback:function ($$v) {_vm.equipment=$$v},expression:"equipment"}})]}}],null,true)}),_c('v-textarea',{attrs:{"outlined":"","label":"Notas","placeholder":"Notas al registro","rows":1},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('div',{staticClass:"text-center mb-6"},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.loading,"x-large":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-paper-plane")]),_vm._v(" Enviar")],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }