<template>
<div>
  <v-btn :disabled="disabled" text icon color="primary"  title="Exportar" :loading="loading" @click="fetch"><v-icon>fas fa-file-export</v-icon></v-btn>
  <v-dialog v-model="dataReady" max-width="500">
    <v-card>
      <v-card-title>
        <h1 class="text text-h5 titulo"><v-icon class="mr-2">fas fa-download</v-icon>Exportar</h1>
      </v-card-title>
      <v-card-text class="text-center">
        <vue-json-to-csv
          :json-data="items"
          :labels="labels"
          :csv-title="csvTitle"
          :separator="';'"
          @success="val => handleSuccess(val)"
          @error="val => handleError(val)">
          <v-btn color="success">
            <v-icon class="mr-2">fas fa-download</v-icon> <b>Descargar</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import notifications from '@/utils/notifications'
import VueJsonToCsv from 'vue-json-to-csv'
import numeral from 'numeral'
import moment from 'moment'
import {fetchBitacora} from '@/api/production'

export default {
  name:"ExportProductionBitacora",
  props:['options','disabled','filter'],
  components: {VueJsonToCsv,},
  data() {
    return {
      loading: false,
      dataReady:false,
      items:[],
      labels:{ 
        created_at:{title:'Fecha'},
        work_order:{title:'OT'},
        customer_name:{title:'Cliente'},
        product_sku:{title:'SKU'},
        product_name: { title: 'Producto' },
        product_qty:{title:'Cantidad'},
        product_mts2:{title:'Total Mts2'},
        machine_name:{title:'Area'},
        equipment_name:{title:'Equipo'},
        production:{title:'Producido'},
        unit:{title:'Unidad'},
        created_by:{title:'Creado por'},
        notes:{title:'Notas'},
       },
       csvTitle:`export_production_bitacora_${moment(new Date().toISOString()).format('DD-MM-yyyy_HH:mm')}`
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      //Arreglando variables de fecha en caso de ser null
      if(!this.filter.created_after) this.filter.created_after=''
      if(!this.filter.created_before) this.filter.created_before=''
      if(!this.filter.machine) this.filter.machine={id:''}
      if(!this.filter.equipment) this.filter.equipment=''
      
      const {sortBy, sortDesc,page, itemsPerPage }=this.options
      const query=`ordering=${sortDesc[0]?sortBy[0]:'-'+sortBy[0]}&page=${page}&created_after=${this.filter.created_after}&created_before=${this.filter.created_before}&process_order__machine__id=${this.filter.machine.id}&equipment__id=${this.filter.equipment}`
      await fetchBitacora(query)
      .then((data)=>{
        this.items = data.results.map(item=>{
          return{
            created_at:moment(item.created_at).format('DD-MM-YYYY HH:mm'),
            work_order:item.work_order,
            customer_name:item.customer_name,
            product_sku:item.product_sku,
            product_name:item.product_name,
            product_qty:item.product_qty,
            product_mts2:item.product_mts2,
            machine_name:item.machine_name,
            equipment_name:item.equipment_name,
            production:item.machine_production_unit_m2?item.mts2:item.qty,
            unit:item.machine_production_unit_m2?'mts2':'unidades',
            created_by:item.created_by,
            notes:item.notes,
          }
        })
        this.dataReady=true
      })
      .catch((err)=>notifications.showError('Error Cargando Ordenes de Trabajo' + err))
      //Calculo porcentaje de completado del producto
      this.loading=false
    },
    handleSuccess(val){
      console.log(val)
      notifications.showSuccess('Ordenes de Trabajo Exportadas')
      this.dataReady=false
    },
    handleError(val){
      console.log(val)
      notifications.showError('Error Exportando Ordenes de Trabajo')
      this.dataReady=false
    }
  }

}
</script>