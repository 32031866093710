import {getWorkOrderCostResume} from '@/api/production.js'
import notifications from'@/utils/notifications'

const production = {
    state:()=>({
        workOrderCostResume:null,
    }),
    mutations:{
        SET_WORK_ORDER_COST_RESUME:(state,data)=>{
            state.workOrderCostResume=data
        }
    },
    getters:{
        getworkOrderCostResume:(state)=>{
            return state.workOrderCostResume
        }
    },
    actions:{
        fetchWorkOrderResume:async (context,data)=>{
            await getWorkOrderCostResume(data)
            .then(data=>{
                console.log(data)
                context.commit('SET_WORK_ORDER_COST_RESUME',data)
            })
            .catch(e=>notifications.showError(e))
        }
    }
}

export default production