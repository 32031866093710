<template>
<div>
      <v-autocomplete
        v-model="value"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="primary"
        hide-selected
        item-text="full_text"
        item-value="id"
        label="Cliente"
        placeholder="Ingrese el nombre del Cliente"
        :prepend-icon="prependIcon"
        return-object
        outlined
        clearable
        class="purple--text"
        @input="updateValue"
        @change="cambio"
        dense
        :disabled="disabled"
        hide-details
      >
      <!-- <template v-slot:item="data">
        <div class="d-flex">
          <div class="text text-caption mr-2">{{data.item.rut}} {{data.item.name}} - {{data.item.fantasy}} {{data.item.branch_office>0?`[${data.item.branch_office}]`:''}}</div>
        </div>
      </template> -->
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Buscar 
            <strong>Cliente</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      </v-autocomplete>
    </div>  
</template>

<script>
import {fetchCustomers} from '@/api/customers'
import notifications from '@/utils/notifications'

  export default {
    name:'CustomerComplete2',
    data: () => ({
      descriptionLimit: 60,
      customers: [],
      isLoading: false,
      model: null,
      search:null,
      items: [],
      timer:'',
    }),
    props:['value','status','customer_name','prependIcon','dense','disabled'],
    methods:{
      updateValue(){
        this.value ? this.$emit('input',this.value) : this.$emit('input','')
      },
      cleared(){
        this.$emit('input','')
      },
      cambio(){
        console.log('cambio en el value')
      },
      querySelections (v) {
        this.isLoading = true
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(async () => {
          //sort = '', page = '', page_size = '', search = '', seller = '', status = ''
          await fetchCustomers('',1,-1,v,'',this.status)
          .then((res)=>{
            this.items=res.results.map(item=>{
              return {
                ...item,full_text:`${item.rut} ${item.name}`
              }
            })
          })

          this.isLoading=false
          },800)
        
        
      },
      searchTimeOut() {  
        //Función para hacer búsqueda lazy
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            this.fetch()
        }, 800);
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      customer_name(val){
        if(val) this.search=val
      }
    },
    
    
  }
</script>