var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v("Usuarios")])]),_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.CreateUpdate($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":15,"search":_vm.search,"loading":_vm.loading,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right'
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"hide-details":"","prepend-inner-icon":"fas fa-search","single-line":"","placeholder":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.is_seller",fn:function(ref){
        var item = ref.item;
return [(item.is_seller)?_c('v-icon',{staticClass:"primary--text"},[_vm._v("fas fa-check")]):_vm._e()]}},{key:"item.is_staff",fn:function(ref){
        var item = ref.item;
return [(item.is_staff)?_c('v-icon',{staticClass:"primary--text"},[_vm._v("fas fa-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.CreateUpdate(item)}}},[_vm._v("fas fa-edit")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }