var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v(_vm._s(_vm.id>0?("Actualizar #" + _vm.id):"Registrar Documento"))])]),_c('v-col',{staticClass:"justify-end d-flex align-center",attrs:{"cols":"12","md":"9","align-self":"center"}},[_c('ValidationProvider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"text-end mr-2",attrs:{"dense":"","items":_vm.tipos,"item-text":"text","item-value":"value","label":"Tipo de Documento","single-line":"","hide-details":"","outlined":"","error-messages":errors},model:{value:(_vm.dte.tipo),callback:function ($$v) {_vm.$set(_vm.dte, "tipo", $$v)},expression:"dte.tipo"}})]}}])}),_c('v-btn',{staticClass:"text-end",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("fas fa-chevron-left")])],1)],1)],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"formulario",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirm)},"keydown":function($event){return _vm.event.key != 'Enter';}}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Orden de Trabajo","outlined":"","append-icon":_vm.work_order_finded?'fas fa-check':'fas fa-search',"loading":_vm.loadingWorkOrder},on:{"keydown":_vm.searchWorkOrder},model:{value:(_vm.work_order),callback:function ($$v) {_vm.work_order=$$v},expression:"work_order"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nro Documento","outlined":"","error-messages":errors},model:{value:(_vm.dte.document_id),callback:function ($$v) {_vm.$set(_vm.dte, "document_id", $$v)},expression:"dte.document_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fecha Emision","type":"date","outlined":"","error-messages":errors},model:{value:(_vm.dte.date_emited),callback:function ($$v) {_vm.$set(_vm.dte, "date_emited", $$v)},expression:"dte.date_emited"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"RUT","dense":"","loading":_vm.loadingCustomer,"disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors,"rules":[_vm.rules.rut]},on:{"keyup":_vm.getCustomerDataByRut},model:{value:(_vm.dte.rutRecep),callback:function ($$v) {_vm.$set(_vm.dte, "rutRecep", $$v)},expression:"dte.rutRecep"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Razon Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Razón Social","loading":_vm.loadingCustomer,"disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors},model:{value:(_vm.dte.rznSocRecep),callback:function ($$v) {_vm.$set(_vm.dte, "rznSocRecep", $$v)},expression:"dte.rznSocRecep"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Giro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Giro","loading":_vm.loadingCustomer,"disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors},model:{value:(_vm.dte.giroRecep),callback:function ($$v) {_vm.$set(_vm.dte, "giroRecep", $$v)},expression:"dte.giroRecep"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"name":"Referencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Condicion de Pago","type":"number","min":0,"outlined":"","error-messages":errors},model:{value:(_vm.dte.payment),callback:function ($$v) {_vm.$set(_vm.dte, "payment", $$v)},expression:"dte.payment"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Referencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Referencia","min":0,"outlined":"","error-messages":errors},model:{value:(_vm.dte.glosa),callback:function ($$v) {_vm.$set(_vm.dte, "glosa", $$v)},expression:"dte.glosa"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-select',{staticClass:"text-end mr-2",attrs:{"dense":"","items":_vm.contactList,"loading":_vm.loadingCustomer,"disabled":_vm.loadingCustomer,"item-text":"email","return-object":"","label":"Selecciona Contacto","single-line":"","hide-details":"","outlined":""},on:{"change":_vm.selectContact}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Contacto","disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors},model:{value:(_vm.dte.contactRecep),callback:function ($$v) {_vm.$set(_vm.dte, "contactRecep", $$v)},expression:"dte.contactRecep"}})]}}],null,true)})],1),_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticClass:"text-end mr-2",attrs:{"dense":"","items":_vm.addressList,"loading":_vm.loadingCustomer,"disabled":_vm.loadingCustomer,"item-text":"name","return-object":"","label":"Selecciona Direccion","single-line":"","hide-details":"","outlined":""},on:{"change":_vm.selectAddress}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('ValidationProvider',{attrs:{"name":"Direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Dirección","disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors},model:{value:(_vm.dte.dirRecep),callback:function ($$v) {_vm.$set(_vm.dte, "dirRecep", $$v)},expression:"dte.dirRecep"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Comuna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Comuna","disabled":_vm.loadingCustomer,"outlined":"","error-messages":errors},model:{value:(_vm.dte.cmnaRecep),callback:function ($$v) {_vm.$set(_vm.dte, "cmnaRecep", $$v)},expression:"dte.cmnaRecep"}})]}}],null,true)})],1),_c('v-col',[(_vm.alertCustomerNotInDB)?_c('v-alert',{attrs:{"type":"info","dense":"","text":""}},[_vm._v("Cliente No encontrado en Base de Datos")]):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"pa-4 mt-4"},[_c('v-row',{staticClass:"mt-4 align-center"},[_c('v-col',{staticClass:"d-block",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex mb-4"},[_c('h1',{staticClass:"text text-h5 titulo"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-plus")]),_vm._v("Productos")],1),(_vm.sales_note)?_c('v-chip',{staticClass:"ml-4",attrs:{"info":""}},[_vm._v("Cotización "),_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.sales_note))]),_vm._v(" "),(_vm.sales_discount>0)?_c('span',{staticClass:"ml-4"},[_vm._v("-- descuento de "+_vm._s(_vm.sales_discount)+"% ")]):_vm._e()]):_vm._e()],1),(_vm.alertProductsnotInDB)?_c('v-alert',{attrs:{"type":"info","dense":"","text":""}},[_vm._v("Existen productos no encontrados en la librería")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dte.dte_detail,"item-key":"nro_linea","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"ma-4",attrs:{"value":true,"icon":"fas fa-info-circle"}},[_vm._v(" Agregue Elementos al documento ")])]},proxy:true},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","align":"center"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"fab":"","small":"","color":"success"},on:{"click":function (){ return _vm.dte.dte_detail.push({
                    nro_linea:_vm.dte.dte_detail.length + 1,
                    product:-1,
                    sku:'',
                    name:'',
                    unit:'',
                    qty:'',
                    price:''
                  }); }}},[_c('v-icon',[_vm._v("fas fa-plus")])],1)],1)]},proxy:true},{key:"item.sku",fn:function(ref){
                  var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"SKU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"error-messages":errors,"hide-details":errors.length<1,"solo":"","dense":""},on:{"keyup":function($event){return _vm.getProductDatabySKU(item)}},scopedSlots:_vm._u([(item.product<0&&item.sku!='')?{key:"append-outer",fn:function(){return [_c('v-icon',{staticClass:"info--text",attrs:{"x-small":""}},[_vm._v("fas fa-exclamation")])]},proxy:true}:null],null,true),model:{value:(item.sku),callback:function ($$v) {_vm.$set(item, "sku", $$v)},expression:"item.sku"}})]}}],null,true)})]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-textarea',{staticClass:"pa-2",staticStyle:{"width":"300px"},attrs:{"rows":"2","error-messages":errors,"hide-details":errors.length<1,"solo":"","dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}}],null,true)})]}},{key:"item.qty",fn:function(ref){
                  var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"width":"100px"},attrs:{"type":"number","min":1,"error-messages":errors,"hide-details":errors.length<1,"solo":"","dense":""},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}})]}}],null,true)})]}},{key:"item.unit",fn:function(ref){
                  var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"width":"80px"},attrs:{"error-messages":errors,"hide-details":errors.length<1,"solo":"","dense":""},model:{value:(item.unit),callback:function ($$v) {_vm.$set(item, "unit", $$v)},expression:"item.unit"}})]}}],null,true)})]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_c('ValidationProvider',{attrs:{"name":"qty","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"width":"150px"},attrs:{"type":"number","min":0,"solo":"","dense":"","error-messages":errors,"hide-details":errors.length<1},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})]}}],null,true)})]}},{key:"item.total",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.price*item.qty,'$', 0))+" ")]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"error--text",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeArticle(item)}}},[_vm._v("fas fa-trash")])]}}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"justify-center mt-4"},[_c('v-col',[_c('v-card',{staticClass:"py-6"},[_c('v-simple-table',{staticClass:"strip-table",attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center table-header"},[_vm._v("Neto")]),_c('th',{staticClass:"text-center table-header"},[_vm._v("IVA")]),_c('th',{staticClass:"text-center table-header"},[_vm._v("Total")]),_c('th',{staticClass:"text-center table-header"},[_vm._v("Vencimiento")])])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center strip-body"},[_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.neto,'$',0)))])]),_c('td',{staticClass:"text-center strip-body d-flex align-center justify-center",staticStyle:{"min-height":"80px"}},[_vm._v(" ("),_c('ValidationProvider',{attrs:{"name":"IVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticStyle:{"max-width":"70px"},attrs:{"type":"number","dense":"","solo":"","hide-details":"","max":"100","min":"0"},model:{value:(_vm.dte.iva),callback:function ($$v) {_vm.$set(_vm.dte, "iva", $$v)},expression:"dte.iva"}})]}}],null,true)}),_vm._v(")% "),_c('strong',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm._f("currency")((_vm.neto)*_vm.dte.iva*0.01,'$',0)))])],1),_c('td',{staticClass:"text-center strip-body"},[_c('strong',{},[_vm._v(_vm._s(_vm._f("currency")(_vm.neto*(1+_vm.dte.iva/100),'$',0)))])]),_c('td',{staticClass:"text-center strip-body"},[_c('strong',{},[_vm._v(_vm._s(_vm.payment_date))])])])])])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center mt-4"},[_c('v-btn',{attrs:{"color":"success","type":"submit","disabled":false,"loading":_vm.loading}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.showProgress),callback:function ($$v) {_vm.showProgress=$$v},expression:"showProgress"}},[_c('v-card',{staticClass:"pa-10"},[_c('v-card-title',{staticClass:"titulo text-h4"},[_vm._v("Registrando...")]),_c('v-row',[_c('v-col',[_c('v-progress-linear',{model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('h1',{staticClass:"text text-h4 titulo"},[_vm._v(_vm._s(_vm._f("number")(_vm.progress,'0.0'))+"%")])])],1)],1)],1),_c('confirm',{attrs:{"message":_vm.dte.work_order?("Se creara un nuevo registro vinculado a la OT " + (_vm.dte.work_order) + " "):'Se creará un nuevo registro sin vinculo a ninguna OT'},on:{"ok":_vm.create,"cancel":function($event){_vm.showConfirm=false}},model:{value:(_vm.showConfirm),callback:function ($$v) {_vm.showConfirm=$$v},expression:"showConfirm"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }