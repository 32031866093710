import { cmsToMts, mtsToCms, getMts2 } from '@/utils/unitConversion'

export function productCost(product) {
    const productAreaM2 = getMts2(product.width, product.height)
    const productQty = parseFloat(product.qty)
    var unitCost = 0
    var totalCost = 0
    var unitProcessCost = 0
    var unitSuppliesCost = 0
    var totalProcessCost = 0
    var totalSuppliesCost = 0
    product.product_receta.forEach(r => {
        const price = parseFloat(r.price)
        const min_price = parseFloat(r.min_price)
        var subUnitCost = 0
        var subTotalCost = 0
        if (r.unit === 1) {
            //Costos por Metro Cuadrado
            subUnitCost = price * productAreaM2
            subTotalCost = subUnitCost * productQty

            //función antígua
            //unitCost += price * qty * productAreaM2
            //totalCost += price * qty * productAreaM2 * productQty
        } else if (r.unit === 2) {
            // costos por unidad
            subUnitCost = price
            subTotalCost = subUnitCost * productQty

            //Función Antígua
            //unitCost += price * qty
            //totalCost += price * qty * productQty
        } else {
            //costos por valor fijo
            subUnitCost = price
            subTotalCost = price
        }
        //Chequeo si el costo es menor que el mínimo
        if (subTotalCost < min_price) {
            totalProcessCost += min_price
            unitProcessCost += min_price / productQty
        } else {
            totalProcessCost += subTotalCost
            unitProcessCost += subUnitCost
        }
    })

    //Sumo el costo de suministros que inclue la cantidad
    product.product_supplies.forEach(r => {
        const price = parseFloat(r.price)
        const min_price = parseFloat(r.min_price)
        const qty = parseFloat(r.qty)||1
        var subUnitCost = 0
        var subTotalCost = 0
        if (r.unit === 1) {
            //Costos por Metro Cuadrado
            subUnitCost = price * qty * productAreaM2
            subTotalCost = subUnitCost * productQty

        } else if (r.unit === 2) {
            // costos por unidad
            subUnitCost = price * qty
            subTotalCost = subUnitCost * productQty

        } else {
            //costos por valor fijo
            subUnitCost = price * qty
            subTotalCost = subUnitCost
        }
        //Chequeo si el costo es menor que el mínimo
        if (subTotalCost < min_price) {
            totalSuppliesCost += min_price
            unitSuppliesCost += min_price / productQty
        } else {
            totalSuppliesCost += subTotalCost
            unitSuppliesCost += subUnitCost
        }
    })

    unitCost = unitProcessCost + unitSuppliesCost
    totalCost = totalProcessCost + totalSuppliesCost

    return {
        unit: unitCost,
        total: totalCost,
        unitSupplies: unitSuppliesCost,
        unitProcess: unitProcessCost,
        totalSupplies: totalSuppliesCost,
        totalProcess: totalProcessCost,
        mts2: productAreaM2,
        totalMts2: productAreaM2 * productQty
    }
}



export function calcSheets(product,supply) {
    const pw=parseFloat(product.width||0)
    const ph=parseFloat(product.height||0)
    const sw=parseFloat(supply.width||0)
    const sh=parseFloat(supply.height||0)
    const pq=parseFloat(product.qty||1)

    if(pw===0||ph===0||sw===0||sh===0) return 0

    //Orientacion directa
    const res1=Math.floor(sw/pw)*Math.floor(sh/ph)

    //Orientacion inversa
    const res2=Math.floor(sh/pw)*Math.floor(sw/ph)

    //Productos por plancha
    const product_per_sheet=Math.max(res1,res2)
    if (product_per_sheet===0 )return 0

    //Cantidad de Planchas
    const sheets_qty=Math.ceil(pq/product_per_sheet)

    return sheets_qty 
}