import axios from "axios"
import store from '@/store'
import notifications from '@/utils/notifications'
import {API_BASE_URL} from '@/main'
//const token = localStorage.getItem('access_token')
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')

const axios_body={
   baseURL: API_BASE_URL
    //baseURL: "http://grafickpro.runflow.cl/api/"
    //"http://" + location.hostname + "/api/";
}

export async function login(credentials) {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.post('auth/login', {
            email: credentials.email,
            password: credentials.password,
        })
        .then((res) => {
            //console.log(res.data)
            localStorage.setItem('access_token', res.data.tokens)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
            store.state.token = res.data.tokens
                /* const user = {
                    id: res.data.id,
                    email: res.data.email,
                    username: res.data.username,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    is_staff: res.data.is_staff
                } */
            const user = res.data
            store.dispatch('setLogin', user)
            notifications.showSuccess('Bienvenido! ' + user.first_name + ' ' + user.last_name)
            return user
        })
        .catch((e) => {
            notifications.showError('Error de Autorización')
            throw e
        })
}

export async function me() {
    return await axios.get('auth/me')
        .then((res) => {

            return res.data
        })
        .catch(() => {
            return null
        })
}

export async function listUsers() {
    return await axios.get('auth/users')
        .then((res) => {
            return res.data
        })
        .catch(() => {
            return null
        })
}

export async function fetchSellers() {
    return await axios.get(`auth/users?is_seller=true`)
        .then((res) => {
            return res.data
        })
        .catch(() => {
            return null
        })
}

export async function getUser(id) {
    return await axios.get(`auth/users/${id}`)
        .then((res) => {
            console.log(res.data)
            return res.data
        })
        .catch(() => {
            return null
        })
}

export async function updateUser(user) {
    return await axios.patch(`auth/users/${user.id}`, {
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            is_seller: user.is_seller,
            is_staff: user.is_staff,
            comision: user.is_seller ? user.comision : 0,
            role: user.role,
            softnet_id: user.softnet_id,
        })
        .then((res) => {
            console.log(res.data)
            return res.data
        })
        .catch(() => {
            throw e
        })
}

export async function createUser(user) {
    const loginAxios = axios.create(axios_body);
    console.log(user)
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.post(`auth/register/`, user)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            throw e
        })
}

export async function passwordResetSendEmail(email) {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.post('password-reset/', {
            email: email
        })
        .then((res) => {
            res.status
        })
        .catch((e) => {
            throw e
        })
}

export async function passwordResetValidate(token) {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.post('password-reset/validate_token/', {
            token: token
        })
        .then((res) => {
            res.status
        })
        .catch((e) => {
            throw e
        })
}

export async function passwordReset(credentials) {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.post('password-reset/confirm/', {
            token: credentials.token,
            password: credentials.password
        })
        .then((res) => {
            res.status
        })
        .catch((e) => {
            console.log(e.response.data)
            throw e
        })
}

export async function getLogo() {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.get('parameters/logo')
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            console.log(e.response.data)
            throw e
        })
}

export async function getPaymentRequest() {
    const loginAxios = axios.create(axios_body);
    loginAxios.defaults.headers.common['Content-Type'] = 'application/json'
    loginAxios.defaults.headers.common['Authorization'] = ''
    return await loginAxios.get('parameters/payment-request')
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            console.log(e.response.data)
            throw e
        })
}






export async function logout() {
    localStorage.removeItem('access_token')
    store.dispatch('logout')
}

export async function fetchRoles() {
    return await axios.get(`auth/roles`)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            return e
        })
}

export async function getRole(id) {
    return await axios.get(`auth/roles/${id}`)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            return e
        })
}

export async function updateRole(role) {
    return await axios.patch(`auth/roles/${role.id}`, {
            name: role.name,
            access: role.access,
            permits: role.permits
        })
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            throw e
        })
}

export async function createRole(role) {
    return await axios.post(`auth/roles`, {
            name: role.name,
            access: role.access,
            permits: role.permits
        })
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            throw e
        })
}

export async function fetchAccess() {
    return await axios.get(`auth/access`)
        .then((res) => {
            return res.data
        })
        .catch((e) => {
            return e
        })
}