<template>
<v-container>
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(savemachine)">
    <v-card-title>
      <h1 class="text text-h4 titulo">{{machine.id>0 ? 'Actualizar Area':'Crear Area'}}</h1>
    </v-card-title>
    <v-row justify="end">
      <v-btn class="" type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon> Guardar</v-btn>
    </v-row>
    <v-row>
      <v-col>
      <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Nombre'
        prepend-icon="fas fa-file-invoice"
        v-model='machine.name'
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Capacidad Diária" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Capacidad por Hora'
        type="number"
        prepend-icon="fas fa-hard-hat"
        :append-icon="machine.production_unit_m2?'square_foot':'view_comfy'"
        @click:append="machine.production_unit_m2 = !machine.production_unit_m2"
        v-model='machine.capacity'
        :hint="machine.production_unit_m2?'Metros Cuadrádos':'Unidades'"
        persistent-hint
        :min="1"
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>  
      <ValidationProvider name="Horas por dia" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Horas por día'
        class="mt-2"
        type="number"
        prepend-icon="fas fa-stopwatch"
        v-model='machine.hours_per_day'
        :min="1"
        :max="24"
        outlined
        :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <ValidationProvider name="Orden" rules="required" v-slot="{ errors }">
        <v-text-field
        label='Orden'
        type="number"
        prepend-icon="fas fa-sort"
        v-model='machine.sort'
        outlined
        :error-messages="errors"
        :loading="loading"
        ></v-text-field>
      </ValidationProvider> 
      <div class="d-flex">
        <v-checkbox
            v-model='machine.saturday'
            label="Sabado"
            dense
            class="mr-4"
            />
        <v-checkbox
            v-model='machine.sunday'
            label="Domingo"
            dense
            />
      </div>
      
      </v-col>
    </v-row>
    </v-form>
    </ValidationObserver>
    <div v-if="machine.equipments">
      <v-card-actions>
        <v-btn class="mx-auto mb-2" @click="addEquipment"><v-icon class="mr-2">fas fa-plus</v-icon>Equipo</v-btn>
      </v-card-actions>
      <v-simple-table v-if="machine.equipments.length>0">
        <tbody >
          <tr v-for='equipment in machine.equipments' :key="equipment.id">
            <td>
              <div>
                {{equipment.name}}
              </div>
            </td>
            <td>
              <v-switch v-model="equipment.enabled" @change="()=>{newEquipment=equipment;saveEquipment()}"></v-switch>
            </td>
            <td align="center">
              <div>
                <v-icon @click="editEquipment(equipment)" class="mr-2">fas fa-edit</v-icon>
              </div>
            </td>
          </tr>
        </tbody>
        </v-simple-table>
        <v-dialog v-model='isEditingEquipment' max-width="400">
          <v-card class="pa-4">
            <v-card-title>Editar Equipo</v-card-title>
            <ValidationObserver v-slot="{handleSubmit}">
              <v-form @submit.prevent="handleSubmit(saveEquipment)">
                <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
                  <v-text-field
                  prepend-icon="fas fa-file-invoice" 
                  v-model='newEquipment.name'
                  outlined
                  :error-messages="errors">
                    <template v-slot:append-outer>
                      <v-btn type="submit"><v-icon>fas fa-save</v-icon></v-btn>
                    </template>  
                  </v-text-field>
                  </ValidationProvider>
              </v-form>
            </ValidationObserver>
          </v-card>
        </v-dialog>
    </div>
</v-container>
</template>
<script>
import {createEquipment,updateEquipment} from '@/api/production'
import notifications from '@/utils/notifications'
export default {
  components: {},
  name:'MachineCreateUpdate',
  props:['machine',],
  data() {
    return {
      newEquipment: {
        id:-1,
        name:''
      },
      isEditingEquipment:false,
      addingEquipment:false,
      loading:false,
    }
  },
  methods:{
    savemachine(){
      this.$emit('close')
      this.$emit('save',this.machine)
    },
    addEquipment(){
      this.newEquipment={
        id:-1,
        name:''
      }
      this.isEditingEquipment=true
    },
    editEquipment(equipment){
      this.newEquipment=equipment
      this.isEditingEquipment=true
    },
    async saveEquipment(){
      this.loading=true
      if(this.newEquipment.id<0){
        await createEquipment(
          {
            name:this.newEquipment.name,
            machine:this.machine.id
          }
        )
        .then((data)=>{
          this.isEditingEquipment=false
          this.machine.equipments.push(data)
          notifications.showSuccess('Equipo '+ data.name + ' Creada')
        })
        .catch((err)=>{
          notifications.showError('Error creando equipo '+err)
        })
      }
      else{
        await updateEquipment(this.newEquipment)
        .then((data)=>{
          this.isEditingEquipment=false
          notifications.showSuccess('Equipo '+ data.name + ' Actualizado')
        })
        .catch((err)=>{
          notifications.showError('Error creando equipo '+err)
        })
      }
      this.loading=false
    }
  }
}
</script>