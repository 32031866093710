<template>
<div class="mt-4">
    <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    :loading="loading"
    dense
    >
    <template v-slot:top>
        <div class="d-flex justify-space-between mb-4">
            <div class="text text-h6"><v-icon small>fas fa-basket-shopping</v-icon> Consumo</div>
            <add-consumption :productId="productId" :work_order_id="work_order_id" @added="fetch"/>
        </div>
    </template>
    <template v-slot:no-data>
        <v-alert :value="true"  icon="fas fa-info-circle" class="ma-4">
        No existen artículos registrados
        </v-alert>
    </template>
    <template v-slot:item.item="{ item }">
        {{items.indexOf(item) + 1}}
    </template>
    <template v-slot:item.created_at="{ item }">
        {{item.created_at | moment("DD/MM/YYYY")}}
    </template>
    <template v-slot:item.name="{ item }">
        <div class="caption">[{{item.article.sku}}]</div>
            {{item.article.name}}
    </template>
    <template v-slot:item.actions="{ item }">
        <v-icon @click="confirmDelete(item)" x-small class="error--text">fas fa-trash</v-icon>
    </template>
    </v-data-table>
    <confirm 
    v-model="displayConfirmation" 
    :message="confirmationMessage"
    @ok="deleteComsumption"
    @cancel="displayConfirmation=false"/>
    



</div>

</template>
<script>
import { fetchConsumtionBitacora,deleteConsumptionBitacora } from '@/api/production'
import AddArticle from '@/components/stocks/AddArticle'
import AddConsumption from './AddConsumption'
import notifications from '@/utils/notifications'
export default{
    name:'ProductConsumptionDetail',
    props:['productId','work_order_id'],
    components:{AddArticle,AddConsumption},
    data(){
        return{
            loading:false,
            items:[],
            headers:[
                {text:'Item',value:'item',align:'left'},
                {text:'Fecha',value:'created_at',align:'left'},
                {text:'Artículo ',value:'name',align:'left'},
                {text:'Cant.',value:'qty',align:'right'},
                {text:'Unid.Consumo',value:'unitConsume',align:'left'},
                {text:'',value:'actions',align:'left'},
            ],
            displayConfirmation:false,
            itemToDelete:null,
        }
    },
    methods:{
        fetch(){
            this.loading = true
            const query=`page=1&product=${this.productId}&ordering=created_at`
            fetchConsumtionBitacora(query)
            .then((res)=>{
                this.items=res.results
            })
            .catch((e)=>{
                notifications.showError(e)
            })
            .finally(()=>{
                this.loading = false
            })
        },
        confirmDelete(item){
            this.itemToDelete=item
            this.confirmationMessage=`¿Eliminar consumo de [${item.article.sku}] ${item.article.name} cantidad ${item.qty} ${item.unitConsume.toUpperCase()}?`
            this.displayConfirmation=true
        },
        deleteComsumption(){
            this.displayConfirmation=false
            deleteConsumptionBitacora(this.itemToDelete.id)
            .then((res)=>{
                this.itemToDelete=null
                this.fetch()
                notifications.showSuccess('Consumo eliminado correctamente')
            })
            .catch((e)=>{
                notifications.showError(e)
            })
            .finally(async ()=>{
                //Actualizo el resumen
                await this.$store.dispatch('fetchWorkOrderResume',this.work_order_id)
            })
        }
    },
    watch:{
        productId(){
            this.items=[]
            this.fetch()
        }
    },
    created(){
        this.fetch()
    }
}

</script>