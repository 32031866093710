<template>
<div>
    <add-article
    @add="add"
    />
    <v-dialog 
    v-model="display"  
    max-width="300"
    transition="dialog-bottom-transition">
        <v-card>
            <v-card-title>
                <v-card-title
                class="titulo text-h6 mb-4"
                >
                <v-icon class="mr-2 align-items-center">fas fa-bars-staggered</v-icon>Agregar Consumo
                </v-card-title>
            </v-card-title>
            <v-card-text>
                <div v-if="selected" class="mb-6">

                    <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle class="info--text">Código</v-list-item-subtitle>
                        <v-list-item-title>{{selected.sku}}</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle class="info--text">Descripción</v-list-item-subtitle>
                        {{selected.name}}
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-subtitle class="info--text">Unidad de Consumo</v-list-item-subtitle>
                        <v-list-item-title>{{selected.unit_consume_name}}</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <div class="text-caption">
                                Factor de Conversión: <strong>{{selected.unit_consume_factor}}</strong>
                            </div>
                            <div class="text-caption mr-4"">
                                Precio Promedio: <strong>{{selected.avg | currency('$',0)}}</strong> 
                            </div>
                            <div class="text-caption mr-4">
                                Unidad de Compra: <strong>{{selected.unit_name}}</strong> 
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <div>
                        
                    </div>
                </div>
                
                <ValidationObserver v-slot="{handleSubmit}">
                    <v-form @submit.prevent="handleSubmit(confirmation)" class="mb-4">
                        <ValidationProvider name="Cantidad" rules="required|minvalue:0" v-slot="{ errors }">
                            <v-text-field
                            x-large
                            ref="qtyField"
                            type="number"
                            v-model="qty"
                            label="Cantidad"
                            outlined
                            required
                            focusable
                            :error-messages="errors"
                            />    
                        </ValidationProvider>
                        <v-btn block color="success" type="submit">Guardar</v-btn>
                    </v-form>
                </ValidationObserver>
            </v-card-text>
        </v-card>
    </v-dialog>
    <confirm 
    v-model="displayConfirmation" 
    :message="confirmationMessage"
    @ok="save"
    @cancel="displayConfirmation=false"/>
</div>
</template>
<script>
import AddArticle from '@/components/stocks/AddArticle'
import {createComsumptionBitacora} from '@/api/production'
import notifications from '@/utils/notifications'
export default  {
    name:'AddConsumption',
    components:{AddArticle},
    props:['productId','work_order_id'],
    data(){
        return{
            display:false,
            displayConfirmation:false,
            selected:null,
            qty:null,
            confirmationMessage:'',
        }
    },
    methods:{
        add(item){
            console.log(item)
            this.selected=item
            if(!this.selected.unit_consume_factor) this.selected.unit_consume_factor=1
            if(!this.selected.unit_consume_name) this.selected.unit_consume_name=this.selected.unit_name
            this.qty=1
            this.display=true
        },
        confirmation(){
            this.confirmationMessage=`¿Consumo agregar consumo de [${this.selected.sku}] ${this.selected.name} cantidad ${this.qty} ${this.selected.unit_consume_name.toUpperCase()}?`
            this.display=false
            this.displayConfirmation=true
        },
        async save(){
            this.displayConfirmation=false
            const consumption={
                article_id:this.selected.id,
                product_id:this.productId,
                articlePrice:parseFloat(this.selected.avg),
                convertedPrice:parseFloat(this.selected.avg)*parseFloat(this.selected.unit_consume_factor),
                qty:this.qty,
                unit:this.selected.unit_name,
                unitConsume:this.selected.unit_consume_name,
                unitConsume_factor:parseFloat(this.selected.unit_consume_factor),
            }
            await createComsumptionBitacora(consumption)
            .then(response=>{
                notifications.showSuccess('Consumo agregado exitosamente')
                this.$emit('added')
                this.selected=null
            })
            .catch(error=>{
                notifications.showError(error)
                this.display=true
            })
            .finally(async ()=>{
                //Actualizo el resumen
                await this.$store.dispatch('fetchWorkOrderResume',this.work_order_id)
            })
        }
    }
    
}

</script>