import axios from "axios"

export async function fetchCustomers(sort = '', page = '', page_size = '', search = '', seller = '', status = '',rut='') {
    return await axios.get(`customer/?ordering=${sort}&page=${page}&page_size=${page_size}&search=${search}&seller=${seller}&status=${status}&rut=${rut}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getCustomer(id) {
    return await axios.get(`customer/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
            return e
        })
}

export async function getCustomerByRUT(rut) {
    return await axios.get(`customer/rut/${rut}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
            return e
        })
}

export async function createCustomer(customer) {
    console.log(customer)
    return await axios.post(`customer/`, {
            rut: customer.rut,
            name: customer.name,
            giro: customer.giro,
            fantasy: customer.fantasy,
            phone: customer.phone,
            com_agencia: customer.com_agencia,
            seller: customer.seller.id,
            payment: customer.payment,
            status: customer.status,
            branch_office: customer.branch_office,
        })
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function uploadCustomers(customers) {
    return await axios.post(`customer/batch-upload`, customers)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateCustomer(customer) {
    return await axios.put(`customer/${customer.id}`, {
            rut: customer.rut,
            name: customer.name,
            giro: customer.giro,
            fantasy: customer.fantasy,
            phone: customer.phone,
            com_agencia: customer.com_agencia,
            payment: customer.payment,
            status: customer.status,
            seller: customer.seller.id,
            branch_office: customer.branch_office,
        })
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createAddress(address) {
    return await axios.post(`customer/address`, {
            customer: address.customer,
            name: address.name,
            region: address.region,
            comune: address.comune,
            street: address.street,
            number: address.number,
            suite: address.suite,
            default: false,
        })
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteAddress(address) {
    return await axios.delete(`customer/address/${address.id}`)
        .then(res => {
            console.log(res)
            return res
        })
        .catch(e => {
            throw e
        })
}

export async function createContact(contact) {
    return await axios.post(`customer/contact`, {
            customer: contact.customer,
            first_name: contact.first_name,
            last_name: contact.last_name,
            email: contact.email,
            phone: contact.phone
        })
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function deleteContact(contact) {
    return await axios.delete(`customer/contact/${contact.id}`)
        .then(res => {
            console.log(res)
            return res
        })
        .catch(e => {
            throw e
        })
}