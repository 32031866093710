<template>
  <v-container class="center-wrapper">
    <v-row justify="center" >
      <v-col cols="12" md="5">
        <v-form @submit.prevent="login">
          <v-img
            max-height="150"
            max-width="150"
            class="mx-auto mb-4"
            fluid
            :src="logo"
          ></v-img>
          <!-- <div class="text-center mb-4">
            <div class="">
              <span class="title">RunFlow&nbsp;</span>
              <span class="title font-weight-light">Evolution</span>
            </div>
          </div> -->
          <v-row class="d-flex" align="center">
            <v-col cols="2">
                <v-img
                max-height="80"
                max-width="80"
                class="mx-auto mb-4"
                fluid
                src="@/assets/runflow_logo.png"
              ></v-img>
              </div>
            </v-col>
            <v-col cols="10">
              <div class="titulo text text-h5 text-md-h4 font-weight-300 mb-4">Bienvenido de vuelta</div>
            </v-col>
          </v-row>
            <v-text-field
              outlined
              prepend-inner-icon="mdi-account"
              placeholder="Correo Electrónico"
              v-model="email"
              type="email"
            ></v-text-field>
            <v-text-field
              outlined
              prepend-inner-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              placeholder="Contraseña"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
            ></v-text-field>
            <div class="text-center">
              <v-btn color="primary" dark  type="submit" :loading="loading"><v-icon class="mr-2">fas fa-sign-in-alt</v-icon>  Acceder</v-btn>
            </div>
          <v-btn to="/auth/password-reset/request" class="mt-4" text>¿Olvidó su Password?</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {login,logout,getLogo} from '@/api/auth'
import interpolate from 'interpolate'
export default {
  name:'Login',
  data: ()=>({
    email:'',
    password:'',
    showPassword:false,
    loading:false,
    logo:''
  }),
  async created(){
    await logout()
    this.logo = await getLogo()
  },
  methods:{
    async login(){
      this.loading=true
      await login({
        email: this.email,
        password:this.password
      })
      .then(async (user)=> {
        this.updateAbility(user)
        //Lleno el store con datos
        await this.$store.dispatch('getParameters')
        await this.$store.dispatch('getSellers')
        await this.$store.dispatch('getGroups')
        await this.$store.dispatch('getLines')
        await this.$store.dispatch('getFamilies')
        await this.$store.dispatch('getStages')
        await this.$store.dispatch('getStores')
        await this.$store.dispatch('getPaymentRequest')
        
        
        this.$router.push('/')
        this.loading=false
        })
      .catch((error)=>{
        this.loading=false
        
      })  
    },
    updateAbility(user) {
      const permissions = user.user_role.permits
      const rules=[]
      permissions.forEach(p => {
        const conditionString = interpolate(p.condition,{user})
        rules.push({
          action:p.action,
          subject:p.subject,
          conditions:JSON.parse(conditionString)
        })
      });
      console.log(rules)
      this.$ability.update(rules); 
      //[{action:'edit',subject:'Customer',conditions:{'sellerId':1}}]
      //[{action:'manage',subject:'all',conditions:''}]
    } 
  },
}
</script>
<style lang="scss" scoped>
:root {
    --dark: #3b4247;
    --dark:#404650;
    --ligth:#ffffff;
}

.center-wrapper {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-fluid {
    height: 200px;
}



.bg-dark { background-color: #404650 !important; }
.bg-dark-x { background-color: var(--dark);}

.form-control {
    min-height: 3.125rem;
    line-height: initial;
}
</style>>
