<template>
  <v-container>
    <v-row justify="end" class="mb-2">
      <v-btn
        color="success"
        dark
        class="ml-2"
        @click="create"
      >
        <v-icon>add</v-icon> Nuevo
      </v-btn>
    </v-row>
  <v-data-table
    :headers="headers"
    :items="costs"
    :items-per-page="10"
    :loading="loading"
    :options.sync="options"
    :server-items-length="totalItems"
    loading-text="Cargando Datos"
    :search="search"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fas fa-angle-double-left',
      lastIcon: 'fas fa-angle-double-right',
      prevIcon: 'fas fa-angle-left',
      nextIcon: 'fas fa-angle-right'
    }"
  >
      <template v-slot:top>
      <v-toolbar
        flat
        class="pa-4"
      >
      <v-select
        v-model="groupFilter"
        :items="groups"
        item-text="name"
        item-value="id"
        label="Grupo"
        placeholder="Todos los Grupos"
        single-line
        outlined
        dense
        clearable
      />
      <v-text-field
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
      ></v-text-field>
        <v-dialog
          v-model="showDialog"
          max-width="500px"
        >
          <cost-create-update 
            :cost=newCost 
            :groups=groups 
            @save="save"
          /> 
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn x-small fab class="mr-2" @click.stop="edit(item)"><v-icon>create</v-icon></v-btn>
      <v-btn x-small fab class="error--text" @click.stop="deleteItem(item)"><v-icon>delete</v-icon></v-btn>
    </template>
  </v-data-table>
  
</v-container>
</template>
<script>
import {fetchCost,createCost, updateCost,fetchGroups,} from '@/api/products'
import notifications from '@/utils/notifications'

import CostCreateUpdate from '@/components/products/costs/CostCreateUpdate'

export default {
  name:'Cost',
  components:{CostCreateUpdate},
  props:{
    is_supply:{
      type:Boolean,
      default:false
    }
  },
  data:()=>({
    costs:[],
    search:'',
    showDialog:false,
    loading:false,
    groups:[],
    groupFilter:'',
    options:{},
    totalItems:0,
    newCost:{
      id:-1,
      group:'',
      unit:'',
      name:'',
      price:0,
      min_price:0,
      is_supply:''
    },
    headers: [
      { text: 'Item',
        align: 'end',
        value: 'id',
        class: 'table-header'
      },
      {text: 'Descripción',value:'name',aling:'start',class: 'table-header'},
      {text: 'Precio',value:'price',align: 'end',class: 'table-header'},
      {text: 'Grupo',value:'group_name',aling:'start',class: 'table-header'},
      {text: 'Unidades',value:'unit_name',aling:'start',class: 'table-header'},
      {text: 'Acciones',value:'actions',aling:'start',class: 'table-header',sortable: false}
    ],
  }),
  watch:{
    async groupFilter(){
      this.costs=await fetchCost(this.is_supply,this.groupFilter)
    }
  },
  methods:{
    edit(item){
      this.newCost=item
      this.showDialog=true
    },
    create(){
      this.newCost={
        id:-1,
        group:'',
        unit:'',
        name:'',
        price:0,
        is_supply:this.is_supply,
        machine:''
      }
      this.showDialog=true
    },
    save(cost){
      this.newCost=cost
      this.newCost.is_supply=this.is_supply
      if(this.newCost.id>0) this.updateCost()
      else this.createCost()
    },
    async createCost(){
      await createCost(this.newCost)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        this.costs=await fetchCost(this.is_supply)
        notifications.showSuccess(this.newCost.name + ' Creado')
        })
      .catch((err)=>{
        notifications.showError('Error Creando '+err)
        this.loading=false
        })
    },
    async updateCost(){
      await updateCost(this.newCost)
      .then(async ()=>{
        this.showDialog=false
        this.loading=false
        notifications.showSuccess(this.newCost.name + ' Actualizado')
        })
      .catch(async (e)=>{
        this.loading=false
        notifications.showError('Error Actualizando ' + e)
        })
      this.costs=await fetchCost(this.is_supply)
    },
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort = ''
      if(sortBy[0]=='group_name'){
        sort = 'group__name'
      }
      else if(sortBy[0]=='unit_name'){
        sort = 'unit__name'
      }
      else{
        sort = sortBy[0]
      }
      await fetchCost(sortDesc[0]?`${sort}`:`-${sort}`,this.search,page,itemsPerPage,this.is_supply,this.groupFilter)
      .then((data)=>{
        this.totalItems=data.count
        this.costs=data.results
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error en carga de datos '+err)
        this.loading=false
      })
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetch()
      }, 800);
    },
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    this.groups=await fetchGroups(this.is_supply)
    this.loading=false
  }
}
</script>
